import { useState } from "react";

export const useAsyncErrorHandling = (
    callback: (...args) => Promise<any> | any
) => {
    const [state, setState] = useState();

    return async (...args) => {
        return callback(...args)
            .then((res) => res)
            .catch((e) => {
                setState(() => {
                    throw e;
                });
            });
    };
};
