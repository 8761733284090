import Constants from "expo-constants";
import * as Crypto from "expo-crypto";
import { ServiceException } from "../shared/exceptions/ServiceException";
import { R, ResultAsync } from "../shared/utils/Result";

const seed: string = Constants?.expoConfig?.extra?.secretSeed;

async function encryptSHA256(
    value: string
): ResultAsync<ServiceException, string> {
    try {
        const c = seed.split("-");
        const word =
            c[0] +
            "-" +
            c[1] +
            "-" +
            c[2] +
            "-" +
            value +
            "-" +
            c[3] +
            "-" +
            c[4];

        const digest = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA256,
            word
        );
        return R.ok(digest);
    } catch (error) {
        console.error(error);
        return R.failure(new ServiceException(error as Error));
    }
}

export { encryptSHA256 };
