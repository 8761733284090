import { Exception } from "./Exception";

export class BusinessException extends Exception {
    BusinessException = "key";

    constructor(creationProps?: Error | string) {
        super(creationProps, "BusinessException");
    }

    getName(): string {
        return BusinessException.name;
    }

    getInstance(): this {
        return this;
    }
}
