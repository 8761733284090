import { BusinessException } from "../BusinessException";

export class HttpForbiddenException extends BusinessException {
    HttpForbiddenException = "key";

    getName(): string {
        return HttpForbiddenException.name;
    }

    getInstance(): this {
        return this;
    }
}
