import * as Loja from "@models/Loja/Loja";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type ListarLojasUsuarioLogadoExceptions = HttpExceptions | ApiException;
export type ListarLojasUsuarioLogadoResult = ResultAsync<
    ListarLojasUsuarioLogadoExceptions,
    Loja.Loja[]
>;

export const listarLojasUsuarioLogado =
    async (): ListarLojasUsuarioLogadoResult => {
        const lojasApi = await get<Loja.Loja[]>(`${basePath}/me`);
        if (lojasApi.isFailure()) return R.failure(lojasApi.error);
        const lojas: Loja.Loja[] = [];
        for (const lojaApi of lojasApi.value) {
            const loja = Loja.criarLoja({ ...lojaApi });
            lojas.push(loja);
        }
        return R.ok(lojas);
    };
