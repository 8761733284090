import { ResponsavelPlanoAcao } from "@features/PlanoAcao/models";
import { Select } from "@shared/components/Select/Select";
import { BottomPage } from "@shared/components/bottomPage/BottomPage";
import { Botao } from "@shared/components/button/Button";
import { EmailInput } from "@shared/components/input/EmailInput";
import { TextInput } from "@shared/components/input/TextInput";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import React, { Fragment, useEffect, useState } from "react";
import { View } from "react-native";
import { Button } from "react-native-paper";
import { TextInput as TextInputRNP } from "react-native-paper";
export interface SelecaoResponavelPlanoAcaoProps {
    cargos: string[];
    responsavelAtual?: ResponsavelPlanoAcao;
    sugestaoResponsavelList: ResponsavelPlanoAcao[];
    onClose: () => void;
    onResponsavelSelecionado: (responsavel: ResponsavelPlanoAcao) => void;
}

export const SelecaoResponsavelPlanoAcao = ({
    cargos,
    responsavelAtual,
    sugestaoResponsavelList,
    onClose,
    onResponsavelSelecionado,
}: SelecaoResponavelPlanoAcaoProps) => {
    const [responsavel, setResponsavel] = useState<
        ResponsavelPlanoAcao | undefined
    >(() => responsavelAtual);

    const [sugestaoResponsavel, setSugestaoResponsavel] = useState<
        ResponsavelPlanoAcao[]
    >([]);

    const handleOnSave = () => {
        responsavel && responsavel.cargo && responsavel.email
            ? onResponsavelSelecionado(responsavel)
            : onClose();
    };

    useEffect(() => {
        const sugestaoResponsavel = sugestaoResponsavelList.filter(
            (r) => r.cargo == responsavel?.cargo
        );
        setSugestaoResponsavel(sugestaoResponsavel);
    }, [responsavel]);

    useEffect(() => {
        if (
            cargos &&
            cargos.length == 1 &&
            (!responsavel || !responsavel.cargo)
        ) {
            const responsavel = sugestaoResponsavelList?.filter(
                (r) => r.cargo == cargos[0]
            );

            if (responsavel && responsavel.length == 1) {
                setResponsavel(responsavel[0]);
            } else {
                setResponsavel({
                    cargo: cargos[0],
                    email: "",
                    nome: "",
                });
            }
        }
    }, [cargos]);

    return (
        <BottomPage title={"Responsáveis"} onClose={onClose}>
            <Container className="pt-52">
                <Body className="pt-4 overflow-y-auto mb-2">
                    <Select
                        label="Cargo"
                        options={cargos.map((c) => {
                            return { valor: c, rotulo: c };
                        })}
                        value={responsavel?.cargo}
                        onSelect={(cargo?: string) => {
                            setResponsavel({
                                cargo: cargo || "Selecione",
                                email: "",
                                nome: "",
                            });
                        }}
                    />
                    {responsavel && responsavel.cargo && (
                        <Fragment>
                            <View className="flex flex-col space-y-1 bg-white">
                                <TextInput
                                    label={"Nome"}
                                    labelFora={true}
                                    opcoesAutocomplete={sugestaoResponsavel.map(
                                        (s) => s.nome
                                    )}
                                    value={responsavel.nome}
                                    onChangeText={(nome) => {
                                        setResponsavel({
                                            ...responsavel,
                                            nome,
                                        });
                                    }}
                                    onOpcaoAutoCompleteSelecionada={(nome) => {
                                        const email = sugestaoResponsavel.find(
                                            (s) => s.nome == nome
                                        )?.email;

                                        setResponsavel({
                                            ...responsavel,
                                            nome,
                                            email: email || "",
                                        });
                                    }}
                                />

                                <EmailInput
                                    label={"E-mail"}
                                    labelFora={true}
                                    opcoesAutocomplete={sugestaoResponsavel.map(
                                        (s) => s.email
                                    )}
                                    textContentType="emailAddress"
                                    value={responsavel.email}
                                    onChangeText={(email) => {
                                        setResponsavel({
                                            ...responsavel,
                                            email,
                                        });
                                    }}
                                />
                            </View>
                        </Fragment>
                    )}
                </Body>

                <Botao onPress={() => handleOnSave()} className="w-full">
                    Salvar
                </Botao>
            </Container>
        </BottomPage>
    );
};
