import { ApiException } from "@shared/exceptions/Api.exception";
import { ResultAsync } from "@shared/utils/Result";
import { HttpExceptions, patch } from "@shared/http/Http.service";
import { basePath } from "./constants";

export interface ConfigurarPlanoAcaoProps {
    idVisita: string;
    configuracao: {
        id: string;
        prazo: number;
        responsavel: {
            cargo: string;
            email: string;
        };
        observacao?: string;
    }[];
    idPlanoAcao: string;
}

export type ConfigurarPlanoAcaoResult = ResultAsync<
    HttpExceptions | ApiException,
    void
>;

export async function configurarPlanoAcao({
    idVisita,
    configuracao,
    idPlanoAcao,
}: ConfigurarPlanoAcaoProps): ConfigurarPlanoAcaoResult {
    return await patch(`${basePath}/${idVisita}/plano-acao/${idPlanoAcao}`, {
        body: configuracao.map((c) => ({
            ...c,
            sponsor: c.responsavel,
            informacaoAdicional: c.observacao,
        })),
    });
}
