import { MD3Theme } from "react-native-paper";

export type Theme = MD3Theme & {
    title: string;
    colors: {
        primary: string;
        outline: string;
        secundary: string;
        success: string;
        error: string;
        warning: string;
        info: string;
        border: {
            default: string;
        };
        button: {
            border: {
                default: string;
                inverted: string;
                disabled: string;
            };
            background: {
                default: {
                    notPressed: string;
                    pressed: string;
                };
                inverted: {
                    notPressed: string;
                    pressed: string;
                };
                disabled: string;
            };
            text: {
                default: {
                    notPressed: string;
                    pressed: string;
                };
                inverted: {
                    notPressed: string;
                    pressed: string;
                };
                disabled: string;
            };
        };
        textInput: {
            border: {
                default: string;
                disabled: string;
            };
        };
        text: {
            dark: string;
            light: string;
            disable: string;
            primary: string;
            white: string;
        };
        icon: {
            dark: string;
            light: string;
            disable: string;
            alert: string;
            primary: string;
            ok: string;
            white: string;
            error: string;
        };
        line: {
            default: string;
        };
    };
};

export const colors = {
    disable: "#9ca3af",

    random: [
        "#71717a",
        "#ef4444",
        "#f97316",
        "#f59e0b",
        "#84cc16",
        "#10b981",
        "#06b6d4",
        "#3b82f6",
        "#6366f1",
        "#d946ef",
        "#f43f5e",
        "#450a0a",
        "#451a03",
        "#1a2e05",
        "#042f2e",
        "#083344",
        "#172554",
        "#2e1065",
        "#4a044e",
        "#500724",
    ],

    // Greens
    green: {
        light: "#047857",
        medium: "#065f46",
        dark: "#052e16",
    },

    // Whites
    white: {
        light: "#ffffff",
        dark: "#f0f0f0",
    },

    // Purples
    purple: {
        light: "#6b21a8",
        dark: "#5a1da3",
    },

    // Blues
    blue: {
        light: "#128AD8",
        dark: "#1e3a8a",
    },

    // Backs
    black: {
        light: "#18181b",
        dark: "#000000",
    },

    // Grays
    gray: {
        light: "#EEEEEE",
        medium: "#BDBDBD",
        dark: "#1f2937",
    },

    // Reds
    red: {
        light: "#f87171",
        medium: "#ef4444",
        dark: "#dc2626",
    },

    // Yellows
    yellow: {
        light: "#facc15",
        medium: "#f59e0b",
        dark: "#d97706",
    },
};
