import * as UsuarioApi from "@api/Usuario";
import * as ErroContext from "@contexts/Erro";
import * as LoginStore from "@contexts/Login";
import { loadingQueue } from "@shared/components/Loading/models/LoadingQueue";
import React, { useEffect } from "react";
import { InvalidEmailOrPaswordException } from "../../../exceptions/InvalidEmailOrPasword.exception";
import * as AuthenticationApi from "../../../infra/api/Autenticacao/Authentication.api";
import { Screens } from "../../../routes/Screens";
import { useErro } from "../../../shared/components/Error/hooks/useErro";
import { useLoading } from "../../../shared/components/Loading/hooks/useLoading";
import { Exception } from "../../../shared/exceptions/Exception";
import { HttpAccessDeniedException } from "../../../shared/exceptions/http/HttpAccessDenied.exception";
import { HttpForbiddenException } from "../../../shared/exceptions/http/HttpForbidden.exception";

export interface UseLogin {
    loadScreen: () => void;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    showForgotPasswordModal: boolean;
    setShowForgotPasswordModal: (show: boolean) => void;
    showForgotPasswordSuccessMessage: boolean;
    setShowForgotPasswordSuccessMessage: (show: boolean) => void;
    signIn: () => void;
    signOut: () => void;
    loadingQueue: loadingQueue;
    error?: Exception;
    setError: (error: Exception) => void;
}

export const useLogin = ({ navigation }): UseLogin => {
    //const navigation = useNavigation<NativeStackNavigationProp<any>>();

    const { addLoading, removeLoading, loadingQueue } = useLoading();
    const { setErro: setError, erro: error } = useErro();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loginAttempts, setLoginAttempts] = React.useState(0);
    const [showForgotPasswordModal, setShowForgotPasswordModal] =
        React.useState(false);
    const [
        showForgotPasswordSuccessMessage,
        setShowForgotPasswordSuccessMessage,
    ] = React.useState(false);

    useEffect(() => {
        if (loginAttempts > 0) {
            if (loginAttempts % 3 === 0 && loginAttempts > 0 && email) {
                setShowForgotPasswordModal(true);
            } else {
                setError(new InvalidEmailOrPaswordException());
            }
        }
    }, [loginAttempts]);

    const loadScreen = () => {
        navigation.navigate(Screens.SIGN_IN);
    };

    const signIn = async () => {
        const tag = "useAuthenticationContext.signIn";
        addLoading(tag);

        const signInResult = await AuthenticationApi.signIn(email, password);
        if (signInResult.isFailure()) {
            if (isPasswordResetRequired(signInResult.error)) {
                navigation.navigate(Screens.CHANGE_PASSWORD, {
                    email: email,
                    password: password,
                });
            } else if (isPasswordOrEmailInvalid(signInResult.error)) {
                setLoginAttempts(loginAttempts + 1);
            } else {
                setError(signInResult.error);
            }

            removeLoading(tag);
            return;
        }

        setLoginAttempts(0);

        const getUsuarioAtual = await UsuarioApi.getUsuarioAtual();

        if (getUsuarioAtual.isFailure()) {
            ErroContext.addErro(getUsuarioAtual.error);
            removeLoading(tag);
            return;
        }

        LoginStore.logIn(getUsuarioAtual.value.menus);
        removeLoading(tag);
    };

    const signOut = () => {
        //globalContext.setCurrentUser(undefined);
        navigation.navigate(Screens.SIGN_IN);
    };

    const isPasswordResetRequired = (error: Exception) => {
        return error instanceof HttpForbiddenException;
    };

    const isPasswordOrEmailInvalid = (error: Exception) => {
        return error instanceof HttpAccessDeniedException;
    };

    return {
        loadScreen,
        email,
        setEmail,
        password,
        setPassword,
        showForgotPasswordModal,
        setShowForgotPasswordModal,
        showForgotPasswordSuccessMessage,
        setShowForgotPasswordSuccessMessage,
        signIn,
        signOut,
        loadingQueue,
        error,
        setError,
    };
};
