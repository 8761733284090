import { BooleanChecklistButton } from "@components/Checklist/BooleanChecklistButton";
import { NumberChecklistButton } from "@components/Checklist/NumberChecklistButton";
import { Ionicons } from "@expo/vector-icons";
import { TiposItensChecklist } from "@models/Checklist/ItemChecklist";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "src/styles/GlobalStyles";
import { SelectChecklistButton } from "../../../components/Checklist/SelectChecklistButton";

/**
 * titulo = indica que o click aconteceu sobre o titulo do item
 * detalhe-resposta = indica que o click aconteceu sobre o ícone de detalhe da resposta
 */
export type LocalOnClickItemChecklist = "titulo" | "detalhe-resposta";

export interface ItemChecklistProps {
    item: TiposItensChecklist;
    onClick: (local: LocalOnClickItemChecklist) => void;
    onResponderItemChecklist: (
        itemChecklistId: string,
        resposta: boolean | number | string
    ) => void;
}

export const ItemChecklist = ({
    item,
    onClick,
    onResponderItemChecklist,
}: ItemChecklistProps) => {
    const theme = useTheme<Theme>();

    const styles = StyleSheet.create({
        icon: {
            color: theme.colors.icon.light,
        },
    });

    return (
        <View className="flex flex-row">
            <View className="basis-2/4">
                <Pressable
                    onPress={() => {
                        onClick("titulo");
                    }}
                >
                    <View className="flex flex-row">
                        <Text>
                            {item.title}{" "}
                            <Ionicons
                                name="information-circle-outline"
                                size={12}
                                style={styles.icon}
                            />
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View className="grow"></View>
            <View className="flex-none">
                {item.type == "boolean" && (
                    <BooleanChecklistButton
                        title={item.title}
                        comment={item.answer.comment}
                        answer={item.answer.answer as boolean | "not_answered"}
                        onAnswered={(answer: boolean | "not_answered") => {
                            answer !== "not_answered" &&
                                onResponderItemChecklist(item.id, answer);
                        }}
                        onDetail={() => onClick("detalhe-resposta")}
                    />
                )}

                {item.type === "numeric" && (
                    <NumberChecklistButton
                        title={item.title}
                        comment={item.answer.comment}
                        sufixo={item.sufixo}
                        answer={item.answer.answer as number}
                        onDetail={() => onClick("detalhe-resposta")}
                        onAnswer={(answer?: number, comment?: string) => {
                            answer && onResponderItemChecklist(item.id, answer);
                        }}
                        answerRange={{
                            min: item.answerRange.minValue,
                            max: item.answerRange.maxValue,
                        }}
                    />
                )}

                {item.type === "select" && (
                    <SelectChecklistButton
                        title={item.title}
                        comment={item.answer.comment}
                        answer={item.answer.answer as string}
                        onDetail={() => onClick("detalhe-resposta")}
                        onAnswer={(answer?: string, comment?: string) => {
                            answer && onResponderItemChecklist(item.id, answer);
                        }}
                        opcoes={item.opcoes}
                    />
                )}
            </View>
        </View>
    );
};
