import ContentLoader, { Facebook } from "react-content-loader";

export interface ImagemLabelLoaderProps {
    style?: React.CSSProperties;
}

export const ImagemLabelLoader = ({ style }: ImagemLabelLoaderProps) => {
    return (
        <ContentLoader
            speed={2}
            width={448}
            height={28}
            viewBox="0 0 448 28"
            backgroundColor="#f2f2f2"
            foregroundColor="#ffffff"
            style={{ ...style }}
        >
            <rect x="0" y="0" rx="4" ry="4" width="28" height="28" />
            <rect x="36" y="0" rx="4" ry="4" width="396" height="28" />
        </ContentLoader>
    );
};
