import { Image } from "expo-image";
import React from "react";
import { Keyboard, Platform, Pressable, View } from "react-native";
import { PageContainer } from "../../page/PageContainer";

export interface LoginTemplateProps {
    children: React.ReactNode;
}

export interface LoginTemplateProps {
    children: React.ReactNode;
}

const LoginTemplateContent = ({ children }: any) => {
    return (
        <View
            className={`flex flex-col flex-1 gap-y-4 bg-white cursor-default`}
        >
            <View className="flex items-center justify-start w-screen h-1/3">
                <Image
                    className="flex flex-1 w-52"
                    source={require("../../../../../assets/images/checkei/logo-vertical.png")}
                    contentFit="scale-down"
                />
            </View>

            <View className="flex flex-row w-screen justify-center grow ">
                <View className="flex flex-col w-screen max-w-md px-2">
                    {children}
                </View>
            </View>

            <View className="flex items-center w-screen h-6 mb-4">
                <Image
                    className="flex flex-1 w-full ios:w-1/4 android:w-1/4"
                    source={require("../../../../../assets/images/compartti/logo-horizontal.png")}
                    contentFit="contain"
                />
            </View>
        </View>
    );
};

export const LoginTemplate = ({ children }: LoginTemplateProps) => {
    return (
        <PageContainer>
            {Platform.OS === "web" && (
                <LoginTemplateContent>{children}</LoginTemplateContent>
            )}

            {Platform.OS !== "web" && (
                <Pressable
                    className={`flex flex-col flex-1 gap-y-4 bg-white cursor-default`}
                    onPress={() => {
                        Keyboard.dismiss();
                    }}
                >
                    <LoginTemplateContent>{children}</LoginTemplateContent>
                </Pressable>
            )}
        </PageContainer>
    );
};

/**
 *
 *
 */
