import { Text, View } from "react-native";
import { IconButton, List } from "react-native-paper";

export interface ChecklistSanfonaProps {
    deleteDesabilitado: boolean;
    titulo: string;
    onPress: () => void;
    onDeletePress?: () => void;
    aberto: boolean;
    children: React.ReactNode;
    mostrarBotaoExcluir?: boolean;
}

export const ChecklistSanfona = ({
    titulo,
    deleteDesabilitado,
    onPress,
    onDeletePress,
    aberto,
    children,
    mostrarBotaoExcluir = true,
}: ChecklistSanfonaProps) => {
    return (
        <View className="bg-transparent border border-zinc-200 rounded-md mb-2">
            <List.Accordion
                title={
                    <View className="flex flex-row w-full justify-between items-center ">
                        <Text>{titulo}</Text>
                        {mostrarBotaoExcluir && (
                            <IconButton
                                icon={"trash-can-outline"}
                                iconColor={"#270040"}
                                size={20}
                                onPress={onDeletePress}
                                style={{ margin: 0 }}
                                disabled={deleteDesabilitado}
                            />
                        )}
                    </View>
                }
                titleStyle={{
                    color: "#270040",
                    fontWeight: "bold",
                }}
                onPress={onPress}
                expanded={aberto}
                style={{
                    backgroundColor: "rgb(230, 243, 234)",
                }}
            >
                <List.Section>{children}</List.Section>
            </List.Accordion>
        </View>
    );
};
