import { delay } from "@shared/utils/delay";
import React, { useEffect } from "react";
import * as Animatable from "react-native-animatable";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { CloseIcon } from "../Icons/CloseIcon";
import { HeaderBar, Titulo } from "../page/PageHeader";

export interface BottomPageProps {
    children: React.ReactNode;
    onClose: () => void;
    title?: string;
    closeIcon?: boolean;
}

export const BottomPage = ({
    children,
    onClose,
    title,
    closeIcon = true,
}: BottomPageProps) => {
    const [animation, setAnimation] = React.useState<
        "fadeInUpBig" | "fadeOutDownBig"
    >("fadeInUpBig");

    const handleClose = () => {
        setAnimation("fadeOutDownBig");
    };

    useEffect(() => {
        if (animation === "fadeOutDownBig") {
            async function close() {
                await delay(200);
                onClose();
            }
            close();
        }
    }, [animation]);

    return (
        <Animatable.View
            animation={animation}
            duration={200}
            style={{
                flex: 1,
                shadowColor: "gray",
                shadowOffset: {
                    width: 0,
                    height: 0,
                },
                shadowOpacity: 0.44,
                shadowRadius: 10.32,
                elevation: 16,
            }}
            className="absolute h-2/3 w-screen bg-white z-50 max-w-md border-t border-l border-r border-zinc-300 rounded-t-3xl bottom-0 p-2"
        >
            <Container>
                <HeaderBar>
                    <Titulo label={title} color="#00AF14" />
                    {closeIcon && (
                        <CloseIcon onPress={handleClose} color="#00AF14" />
                    )}
                </HeaderBar>
                <Body>{children}</Body>
            </Container>
        </Animatable.View>
    );
};
