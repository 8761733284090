import { Loja } from "@components/Loja/Loja";
import * as MapaContext from "@contexts/Mapa";
import { BackIcon } from "@shared/components/Icons/BackIcon";
import { HeaderBar, PageHeader, Titulo } from "@shared/components/page";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import React from "react";

export const PaginaLoja = () => {
    const loja = MapaContext.useMapaContext((state) => state.lojaSelecionada);
    const navegarParaMapa = MapaContext.useMapaContext(
        (state) => state.navegarParaMapa
    );

    const voltarHandler = () => {
        navegarParaMapa();
    };

    return (
        <Container>
            <PageHeader>
                <HeaderBar>
                    <Titulo label={loja?.title} color="#00AF14" />
                    <BackIcon color={"#00AF14"} onPress={voltarHandler} />
                </HeaderBar>
            </PageHeader>
            <Body>
                <Loja />
            </Body>
        </Container>
    );
};
