import { TechnicalException } from "@shared/exceptions/TechnicalException";

export class PlanoAcaoNaoCarregadoException extends TechnicalException {
    PlanoAcaoNaoCarregadoException = "key";

    constructor() {
        super("Plano de ação não carregado");
    }

    getInstance(): this {
        return this;
    }

    getName(): string {
        return PlanoAcaoNaoCarregadoException.name;
    }
}
