import { CHAVES_GLOSSARIO } from "@hooks/useGlossario";

export const getTexto = async (chave: CHAVES_GLOSSARIO) => {
    switch (chave) {
        case CHAVES_GLOSSARIO.TITULO_ABA_VISITAS_PAGINA_LOJA:
            return "Supervisão";
        case CHAVES_GLOSSARIO.TITULO_ULTIMA_VISITA_PAGINA_LOJA:
            return "Última supervisão";
        case CHAVES_GLOSSARIO.TITULO_BOTAO_NOVA_VISITA_PAGINA_LOJA:
            return "Iniciar supervisão";
        case CHAVES_GLOSSARIO.TITULO_BOTAO_CONTINUAR_VISITA_PAGINA_LOJA:
            return "Continuar supervisão";
        case CHAVES_GLOSSARIO.DESCRICAO_PLANO_ACAO_LOJA_CARD_RESUMO_PLANO_ACAO:
            return "Plano de ação da supervisão";
        case CHAVES_GLOSSARIO.DESCRICAO_PONTO_VENDA_SEM_VISITA_PAGINA_LOJA:
            return "Este ponto de venda ainda não possui nenhuma supervisão.";
        case CHAVES_GLOSSARIO.TITULO_PAGINA_VISITA:
            return "Supervisão";
        default:
            return "";
    }
};
