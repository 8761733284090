import * as React from "react";
import { Modal, View } from "react-native";
import { Button, Text, TextInput } from "react-native-paper";

export interface MessageModalHeaderProps {
    icon?: React.JSX.Element;
    renderIcon?: () => React.ReactElement<any>;
    title?: string;
    titleColor?: string;
}

export const MessageModalHeader = ({
    icon,
    renderIcon,
    title,
    titleColor,
}: MessageModalHeaderProps) => {
    return (
        <View className="flex items-center pt-4">
            {icon ? icon : renderIcon ? renderIcon() : <></>}
            {title && (
                <Text variant="displayMedium" style={{ color: titleColor }}>
                    {title}
                </Text>
            )}
        </View>
    );
};

export interface MessageModalBodyProps {
    message?: string | React.ReactElement<any>;
    messageColor?: string;
    children?: React.ReactNode;
}

export const MessageModalBody = ({
    message,
    messageColor,
    children,
}: MessageModalBodyProps) => {
    return (
        <View className="flex flex-col items-center space-y-2">
            {children || (
                <Text
                    variant="bodyLarge"
                    style={{
                        color: messageColor,
                        verticalAlign: "middle",
                    }}
                >
                    {message}
                </Text>
            )}
        </View>
    );
};

export interface MessageModalFooterProps {
    primaryButtonText?: string;
    primaryButtonColor?: string;
    secundaryButtonText?: string;
    onPrimaryButtonPressed?: () => void;
    onSecundaryButtonPressed?: () => void;
}

export const MessageModalFooter = ({
    primaryButtonText,
    primaryButtonColor,
    secundaryButtonText,
    onPrimaryButtonPressed,
    onSecundaryButtonPressed,
}: MessageModalFooterProps) => {
    return (
        <View className="flex flex-col space-y-2">
            <Button
                mode="elevated"
                buttonColor={primaryButtonColor}
                textColor="white"
                onPress={onPrimaryButtonPressed}
            >
                {primaryButtonText}
            </Button>
            {secundaryButtonText && (
                <Button
                    mode="text"
                    textColor="black"
                    onPress={onSecundaryButtonPressed}
                >
                    {secundaryButtonText}
                </Button>
            )}
        </View>
    );
};

export interface ModalMessageProps {
    visible: boolean;
    icon?: React.JSX.Element;
    renderIcon?: () => React.ReactElement<any>;
    title?: string;
    titleColor?: string;
    message?: string | React.ReactElement<any>;
    messageColor?: string;
    primaryButtonText?: string;
    primaryButtonColor?: string;
    secundaryButtonText?: string;
    onRequestClose: () => void;
    onPrimaryButtonPressed?: () => void;
    onSecundaryButtonPressed?: () => void;
    body?: React.ReactNode;
}

export const MessageModal = ({
    visible,
    icon,
    renderIcon,
    title,
    titleColor,
    primaryButtonText,
    primaryButtonColor = "#f87171",
    secundaryButtonText,
    message,
    messageColor,
    onRequestClose,
    onPrimaryButtonPressed,
    onSecundaryButtonPressed,
    body,
}: ModalMessageProps) => {
    return (
        <Modal
            animationType="slide"
            visible={visible}
            transparent
            onRequestClose={onRequestClose}
        >
            <View className="flex-1 justify-center pt-5 items-center ">
                <View className="flex flex-col space-y-4 bg-white mx-6 p-4 rounded-2xl shadow shadow-zinc-500 max-w-md">
                    <MessageModalHeader
                        icon={icon}
                        renderIcon={renderIcon}
                        title={title}
                        titleColor={titleColor}
                    />

                    {body && <MessageModalBody>{body}</MessageModalBody>}
                    <MessageModalBody
                        message={message}
                        messageColor={messageColor}
                    />

                    <MessageModalFooter
                        onPrimaryButtonPressed={onPrimaryButtonPressed}
                        primaryButtonText={primaryButtonText}
                        primaryButtonColor={primaryButtonColor}
                        secundaryButtonText={secundaryButtonText}
                        onSecundaryButtonPressed={onSecundaryButtonPressed}
                    />
                </View>
            </View>
        </Modal>
    );
};
