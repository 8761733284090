import "@expo/metro-runtime";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createURL } from "expo-linking";
import React from "react";
import theme from "../styles/themes/DefaultTheme";
import { StackPrivadaComponent } from "./private";
import { StackPublicaComponent } from "./public";

const MyTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: theme.colors.primary,
    },
};

const url = createURL("/");

const StackPrincipal = createStackNavigator();

export const Routes = () => {
    const config = {
        screens: {
            public: {
                screens: {
                    "resposta-plano-acao": {
                        path: "public/responder-plano-acao/:idVisita/:idPlanoAcao/:token",
                        parse: {
                            idVisita: (idVisita) => idVisita,
                            idPlanoAcao: (idPlanoAcao) => idPlanoAcao,
                            token: (token) => token,
                        },
                    },
                },
            },
            private: {
                screens: {
                    loginStack: {
                        screens: {
                            sigin: "signin",
                        },
                    },
                    mapaStack: {
                        screens: {
                            mapa: "mapa",
                        },
                    },
                },
            },
        },
    };

    const linking = {
        prefixes: [url],
        config,
    };

    return (
        <NavigationContainer theme={MyTheme} linking={linking}>
            <StackPrincipal.Navigator
                screenOptions={{ headerShown: false }}
                initialRouteName="private"
            >
                <StackPrincipal.Screen
                    name={"public"}
                    component={StackPublicaComponent}
                />

                <StackPrincipal.Screen
                    name="private"
                    component={StackPrivadaComponent}
                />
            </StackPrincipal.Navigator>
        </NavigationContainer>
    );
};
