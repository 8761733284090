import { DefaultText } from "@shared/components/text/DefaultText";
import { Keyboard, TextInput, View } from "react-native";

export interface ComentarioItemChecklistProps {
    value?: string;
    onChange: (comment: string) => void;
}

export const ComentarioItemChecklist = ({
    value,
    onChange,
}: ComentarioItemChecklistProps) => {
    const handleBlur = () => {
        Keyboard.dismiss();
    };

    return (
        <View className="flex flex-col">
            <DefaultText label="Comentário:" />
            <TextInput
                style={{ verticalAlign: "top" }}
                placeholder="Adicione aqui um comentário da sua avaliação..."
                className="text-zinc-600 text-xl border border-zinc-300 rounded-lg p-2 h-72"
                multiline={true}
                value={value}
                onChangeText={onChange}
                onBlur={() => {
                    handleBlur();
                }}
            />
        </View>
    );
};
