import { Ionicons } from "@expo/vector-icons";
import { PrazoPlanoAcaoSelect } from "@features/PlanoAcao/components/PlazoPlanoAcao/PrazoPlanoAcaoSelect";
import { PlanoAcao } from "@models/PlanoAcao";
import { StatusAtividadePlanoAcao } from "@models/PlanoAcao/StatusAtividadePlanoAcao";
import { CalendarIcon } from "@shared/components/Icons/CalendarIcon";
import { InformationIcon } from "@shared/components/Icons/InformationIcon";
import { PersonIcon } from "@shared/components/Icons/PersonIcon";
import { TagIcon } from "@shared/components/Icons/TagIcon";
import { IconLabelValueList } from "@shared/components/itemList/IconLabelValueList";
import { Subtitle } from "@shared/components/text/Subtitle";
import { View } from "react-native";

export interface ItemAtividadePlanoAcao {
    id: string;
    descricao: string;
    prazo?: number;
    opcoesPrazo: number[];
    dataInicio?: Date;
    responsavel?: { email: string };
    status: StatusAtividadePlanoAcao;
    observacao?: string;
    itemChecklistGatilho: string[];
    planoAcao?: PlanoAcao;
}

export enum PROP_NAMES {
    DESCRICAO = "DESCRICAO",
    PRAZO = "PRAZO",
    RESPONSAVEL = "RESPONSAVEL",
    OBSERVACAO = "OBSERVACAO",
    STATUS = "STATUS",
}

export interface AtividadePlanoAcaoProps {
    item: ItemAtividadePlanoAcao;
    titulo?: string;
    onClickProps?: (propName: PROP_NAMES) => void;
    onChangeValue?: (propName: PROP_NAMES, value?: string | number) => void;
}

export const AtividadePlanoAcao = ({
    item,
    titulo,
    onClickProps,
    onChangeValue,
    ...props
}: AtividadePlanoAcaoProps) => {
    const onClickHandler = (id: string) => {
        onClickProps && onClickProps(id as PROP_NAMES);
    };

    return (
        <View className="flex flex-col space-y-2" {...props}>
            {titulo && (
                <View className="flex flex-row px-2 justify-between">
                    <Subtitle label={titulo} />
                </View>
            )}

            <View className="bg-zinc-50 rounded py-2">
                <IconLabelValueList
                    data={[
                        {
                            id: PROP_NAMES.DESCRICAO,
                            label: "Descrição",
                            value: item.descricao,
                            icon: <InformationIcon size="sm" />,
                            styleValue: "line-clamp-2",
                            styleLabel: "w-24",
                        },
                        {
                            id: PROP_NAMES.OBSERVACAO,
                            label: "Observação",
                            value: item.observacao,
                            icon: (
                                <Ionicons
                                    name="chatbox-ellipses-outline"
                                    size={20}
                                    color="#00AF14"
                                />
                            ),
                            styleValue: "line-clamp-4",
                            styleLabel: "w-24",
                        },
                        {
                            id: PROP_NAMES.PRAZO,
                            label: "Prazo",
                            value: (
                                <PrazoPlanoAcaoSelect
                                    value={item.prazo}
                                    options={item.opcoesPrazo}
                                    onSelect={(prazo) => {
                                        onChangeValue &&
                                            onChangeValue(
                                                PROP_NAMES.PRAZO,
                                                prazo,
                                            );
                                    }}
                                    dataInicio={item.dataInicio}
                                    enabled={onChangeValue ? true : false}
                                />
                            ),
                            icon: <CalendarIcon size="sm" />,
                            formatValue: (value) => {
                                return `${value} dias | ${new Date(
                                    new Date().getTime() +
                                        Number(value) * 24 * 60 * 60 * 1000,
                                ).toLocaleDateString()}`;
                            },
                            styleLabel: "w-24",
                        },
                        {
                            id: PROP_NAMES.RESPONSAVEL,
                            label: "Responsável",
                            value: item.responsavel?.email,
                            icon: <PersonIcon size="sm" />,
                            styleLabel: "w-24",
                        },
                        {
                            id: PROP_NAMES.STATUS,
                            label: "Status",
                            value: item.status,
                            icon: <TagIcon size="sm" />,
                            styleValue: "line-clamp-2",
                            styleLabel: "w-24",
                        },
                    ]}
                    onClick={onClickProps ? onClickHandler : undefined}
                    separator={true}
                />
            </View>
        </View>
    );
};
