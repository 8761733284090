import { loadingQueue } from "@shared/components/Loading/models/LoadingQueue";
import { create } from "zustand";

export type UseLoadingStore = {
    loadingQueue: loadingQueue;
    addLoading: (id: string) => void;
    removeLoading: (id: string) => void;
};

export const useLoadingStore = create<UseLoadingStore>()((set, get) => ({
    loadingQueue: [],
    addLoading(id: string) {
        set((state) => ({
            loadingQueue: [...state.loadingQueue, id],
        }));
    },
    removeLoading(id: string) {
        set((state) => ({
            loadingQueue: state.loadingQueue.filter((item) => item !== id),
        }));
    },
}));
