import { useEffect } from "react";
import { Image, View } from "react-native";
import { Text, TextInput, useTheme } from "react-native-paper";
import { MessageModal } from "../../../../shared/components/modal/MessageModal";
import { Theme } from "../../../../styles/GlobalStyles";
import { useForgotPassword } from "./useForgotPassword";

export interface ForgotPasswordInterfaceProps {
    email: string;
    visible: boolean;
    close: (forgotPasswordSuccess: boolean) => void;
}

export const ForgotPasswordInterface = ({
    email: _email,
    visible,
    close,
}: ForgotPasswordInterfaceProps) => {
    const theme = useTheme<Theme>();

    const { email, setEmail, forgotPassword } = useForgotPassword();

    useEffect(() => {
        setEmail(_email);
    }, [_email]);

    const forgotPasswordHandler = async () => {
        const forgotPasswordSuccess = await forgotPassword();
        close(forgotPasswordSuccess);
    };

    const body = (
        <View className="flex flex-col space-y-2">
            <Text
                variant="bodyLarge"
                style={{
                    verticalAlign: "middle",
                }}
            >
                {`Estamos prontos para te enviar um e-mail com o passo a passo para recuperação da sua senha.`}
            </Text>
            <TextInput
                mode="outlined"
                label={"E-mail"}
                value={email}
                onChangeText={(text) => setEmail(text)}
            ></TextInput>
        </View>
    );

    return (
        <View>
            <MessageModal
                visible={visible}
                primaryButtonText="Ok, pode enviar o e-mail"
                secundaryButtonText="Cancelar"
                primaryButtonColor={theme.colors.text.primary}
                renderIcon={() => {
                    return (
                        <Image
                            source={{
                                uri: "https://compartti-assets.s3.us-east-2.amazonaws.com/forgot-password2.png",
                            }}
                            className="w-56 h-56"
                        />
                    );
                }}
                onPrimaryButtonPressed={forgotPasswordHandler}
                onSecundaryButtonPressed={() => close(false)}
                onRequestClose={() => close(false)}
                body={body}
            />
        </View>
    );
};
