import { ImagemLabelLoader } from "@components/Loaders/ImagemLabelLoader";
import * as ErroContext from "@contexts/Erro";
import { useMapaContext } from "@contexts/Mapa";
import { LojaNaoCarregadaException } from "@exceptions/Loja/LojaNaoCarregada.exception";
import { DetalheLoja } from "@models/Loja/DetalheLoja";
import { ItemsList } from "@pages/Loja/components/ItemsList";
import * as LojaService from "@services/Loja";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FlatList, View } from "react-native";

export default function AbaDetalhesLoja() {
    const [loading, setLoading] = useState(false);
    const [detalhes, setDetalhes] = useState<DetalheLoja[]>([]);

    const loja = useMapaContext((state) => state.lojaSelecionada);

    useEffect(() => {
        if (loja) {
            setLoading(true);
            carregarDetalhesLoja();
        }
    }, [loja]);

    const carregarDetalhesLoja = async () => {
        if (!loja) {
            ErroContext.addErro(new LojaNaoCarregadaException());
            return;
        }

        const detalhes = await LojaService.listarDetalhesLoja(loja.id);
        if (detalhes.isFailure()) {
            ErroContext.addErro(detalhes.error);
            return;
        }

        setDetalhes(detalhes.value);
        setLoading(false);
    };

    return (
        <Container>
            <Body>
                {loading ? (
                    <View className="flex flex-col">
                        <ImagemLabelLoader
                            style={{ marginLeft: 8, marginTop: 8 }}
                        />
                        <ImagemLabelLoader
                            style={{ marginLeft: 8, marginTop: 8 }}
                        />
                        <ImagemLabelLoader
                            style={{ marginLeft: 8, marginTop: 8 }}
                        />
                        <ImagemLabelLoader
                            style={{ marginLeft: 8, marginTop: 8 }}
                        />
                        <ImagemLabelLoader
                            style={{ marginLeft: 8, marginTop: 8 }}
                        />
                    </View>
                ) : (
                    <FlatList
                        data={
                            detalhes &&
                            detalhes.sort((a, b) => a.order - b.order)
                        }
                        keyExtractor={(item) => item.label}
                        renderItem={({ item }) => (
                            <ItemsList
                                label={item.label}
                                value={
                                    item.value instanceof Date
                                        ? format(
                                              new Date(item.value),
                                              "dd/MM/yyyy"
                                          )
                                        : item.value
                                }
                                icon={item.icon}
                            />
                        )}
                    />
                )}
            </Body>
        </Container>
    );
}
