import { useVisita } from "@hooks/useVisita";
import * as ChecklistModel from "@models/Checklist";
import * as ItemChecklistModel from "@models/Checklist/ItemChecklist";
import {
    ItemChecklist,
    LocalOnClickItemChecklist,
} from "@pages/Visita/components/ItemChecklist";
import { Subtitle } from "@shared/components/text/Subtitle";
import { useState } from "react";
import { FlatList, View } from "react-native";

/**
 * "ajuda" = indica que o usuário precisa de ajuda em como validar e responder o item do checklist
 * "complemento" = indica que o usuário deseja adicionar um complemento à sua resposta do checklist (ex: uma observação)
 */
export type TipoModalComplemento = "ajuda" | "complemento";

type showModalComplemento = {
    show: boolean;
    detalhe: {
        tipoModal: TipoModalComplemento;
        itemChecklist: ItemChecklistModel.TiposItensChecklist;
    };
};

export interface ChecklistProps {
    checklist?: ChecklistModel.Checklist;
    onClickItemChecklist: (
        local: LocalOnClickItemChecklist,
        itemChecklist: ItemChecklistModel.TiposItensChecklist,
    ) => void;
}

export const Checklist = ({
    checklist,
    onClickItemChecklist,
}: ChecklistProps) => {
    const data =
        checklist &&
        ItemChecklistModel.categorizarItensChecklist(checklist.itens);

    return (
        <View>
            {checklist && (
                <FlatList
                    data={data}
                    keyExtractor={(item) => item.nomeCategoria}
                    renderItem={({ item }) => {
                        return (
                            <ChecklistCategorizado
                                key={item.nomeCategoria}
                                categoria={item}
                                checklist={checklist}
                                onClickItemChecklist={onClickItemChecklist}
                            />
                        );
                    }}
                />
            )}
        </View>
    );
};

type ChecklistCategorizadoProps = {
    categoria: ItemChecklistModel.ItemChecklistCategorizado;
    checklist: ChecklistModel.Checklist;
    onClickItemChecklist: (
        local: LocalOnClickItemChecklist,
        itemChecklist: ItemChecklistModel.TiposItensChecklist,
    ) => void;
};

const ChecklistCategorizado = ({
    categoria,
    checklist,
    onClickItemChecklist,
}: ChecklistCategorizadoProps) => {
    const { responderItemChecklist } = useVisita();

    return (
        <View
            key={categoria.nomeCategoria}
            className="flex flex-col p-2 space-y-2"
        >
            <Subtitle label={categoria.nomeCategoria} />

            <View className="flex flex-col space-y-4">
                {categoria.itens.map((item, index) => (
                    <ItemChecklist
                        key={item.id}
                        item={item}
                        onClick={(local) => onClickItemChecklist(local, item)}
                        onResponderItemChecklist={(
                            itemChecklistId,
                            resposta,
                        ) => {
                            responderItemChecklist(
                                checklist,
                                itemChecklistId,
                                resposta,
                            );
                        }}
                    />
                ))}
            </View>
        </View>
    );
};
