import * as ErroContext from "@contexts/Erro";
import "@expo/metro-runtime";
import { ErrorBoundary } from "@hooks/ErrorBoundary";
import { Routes } from "@routes/index";
import { MessageModal } from "@shared/components/modal/MessageModal";
import React, { useEffect } from "react";
import { PaperProvider } from "react-native-paper";
import "./global.css";
import theme from "./src/styles/themes/DefaultTheme";
import "./styles.css";
const erroPermmissaoNegada = "HttpAccessDeniedException";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-GMP8PBT5BS";

export default function App() {
    const erro = ErroContext.useErroContext((state) => state.erro);

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
    }, []);

    return (
        <PaperProvider theme={theme}>
            <ErrorBoundary>
                {erro && erro.name !== erroPermmissaoNegada && (
                    <MessageModal
                        visible={!!erro}
                        onRequestClose={() => ErroContext.removerErro()}
                        message={erro.message}
                        title="Ops!"
                        primaryButtonText="Ok"
                        onPrimaryButtonPressed={() => ErroContext.removerErro()}
                    />
                )}
                <Routes />
            </ErrorBoundary>
        </PaperProvider>
    );
}

/*
process(styles)
    .then(() => {
        // Render your components after tailwindcss plugins have been processed
        ReactDOM.render(<App />, document.getElementById("root"));
    })
    .catch((error) => {
        console.error(error);
    });
*/
