import { CHAVES_GLOSSARIO, useGlossario } from "@hooks/useGlossario";
import React, { useEffect } from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "../../../styles/GlobalStyles";
import { DefaultText, DefaultTextProps } from "./DefaultText";

export interface LabelProps extends Omit<DefaultTextProps, "label"> {
    label?: string | React.ReactElement;
}

export const Label: React.FC<LabelProps> = ({
    label,
    ...props
}: LabelProps) => {
    const theme = useTheme<Theme>();

    const styles = StyleSheet.create({
        text: {
            color: theme.colors.secondary,
        },
    });

    return (
        <DefaultText
            className="font-bold text-[#270040]"
            style={styles.text}
            label={label || ""}
            {...props}
        />
    );
};

export interface LabelComGlossarioProps extends Omit<LabelProps, "label"> {
    chaveGlossario?: CHAVES_GLOSSARIO;
}

export const LabelComGlossario: React.FC<LabelComGlossarioProps> = ({
    chaveGlossario,
    ...props
}: LabelComGlossarioProps) => {
    const theme = useTheme<Theme>();

    const { carregarTexto, componenteLoader, texto } = useGlossario();

    useEffect(() => {
        carregarLabel();
    }, []);

    const carregarLabel = async () => {
        carregarTexto(chaveGlossario as CHAVES_GLOSSARIO);
    };

    const styles = StyleSheet.create({
        text: {
            color: theme.colors.secondary,
        },
    });

    return texto ? (
        <Label
            className="font-bold"
            style={styles.text}
            label={texto || ""}
            {...props}
        />
    ) : (
        componenteLoader
    );
};
