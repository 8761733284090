import * as AutenticacaoApi from "@api/Autenticacao";
import * as LoginContext from "@contexts/Login";
import { R, ResultAsync } from "@shared/utils/Result";

export const logout = async () => {
    const signout = await AutenticacaoApi.signout();
    if (signout.isFailure()) return R.failure(signout.error);
    await LoginContext.logOut();
    return R.ok();
};
