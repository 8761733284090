import * as ChecklistApi from "@api/Checklist";
import * as ChecklistConfigApi from "@api/ChecklistConfig";
import * as PlanoAcaoApi from "@api/PlanoAcao";
import * as VisitaApi from "@api/Visita";
import { ABAS_TELA_LOJA, Telas, useLojaStore } from "@contexts/Loja";
import * as MapaContext from "@contexts/Mapa";
import { ChecklistNaoCarregadoException } from "@exceptions/Checklist/ChecklistNaoCarregado.exception";
import { LojaNaoCarregadaException } from "@exceptions/Loja/LojaNaoCarregada.exception";
import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import { PlanoAcaoNaoCarregadoException } from "@exceptions/PlanoAcao/PlanoAcaoNaoCarregado.exception";
import { VisitaNaoCarregadaException } from "@exceptions/Visita/VisitaNaoCarregada.exception";
import { VisitaNaoEncontradaException } from "@exceptions/Visita/VisitaNaoEncontrada.exception";
import * as Checklist from "@models/Checklist/Checklist";
import { Loja } from "@models/Loja/Loja";
import * as PlanoAcao from "@models/PlanoAcao";
import * as Visita from "@models/Visita";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpAccessDeniedException } from "@shared/exceptions/http/HttpAccessDenied.exception";
import { HttpForbiddenException } from "@shared/exceptions/http/HttpForbidden.exception";
import { HttpServiceException } from "@shared/exceptions/http/HttpService.exception";
import { HttpTimeoutException } from "@shared/exceptions/http/HttpTimeout.exception";
import { HttpExceptions } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";

export interface CarregarVisitasProps {
    idLoja: string;
    actionName: string;
    navegarPara?: Telas;
}

export interface ConcluirVisitaProps {
    visita: Visita.Visita;
    checklists: Checklist.Checklist[];
    planoAcao: PlanoAcao.PlanoAcao[];
    localizacao?: Visita.Localizacao;
}

export type ConcluirVisitaExceptions =
    | LojaNaoCarregadaException
    | PlanoAcaoNaoCarregadoException
    | ChecklistApi.ResponderChecklistExceptions
    | ChecklistNaoCarregadoException
    | VisitaNaoCarregadaException
    | HttpServiceException
    | HttpTimeoutException
    | HttpAccessDeniedException
    | HttpForbiddenException
    | ApiException
    | AtividadePlanoAcaoInvalida;

export type ConcluirVisitaResult = ResultAsync<
    ConcluirVisitaExceptions,
    Buffer
>;

export type ConfigurarPlanoAcaoresult = ResultAsync<
    | PlanoAcaoNaoCarregadoException
    | HttpExceptions
    | ApiException
    | AtividadePlanoAcaoInvalida,
    void
>;

export interface AdicionarPrazoAtividadePlanoAcaoVisitaProps {
    planoAcao: PlanoAcao.PlanoAcao;
    prazo: number;
    idAtividade: string;
}

export const useVisita = () => {
    const {
        iniciarVisita: iniciarVisitaLojaStore,
        carregarVisitas: carregarVisitasLojaStore,
        continuarVisita: continuarVisitaLojaStore,
        atualizarVisita: atualizarVisitaLojaStore,
        atualizarChecklist: atualizarChecklistLojaStore,
        atualizarPlanoAcao: atualizarPlanoAcaoLojaStore,
        checklists: checklistsStore,
    } = useLojaStore();

    const adicionarChecklist = async (
        idChecklistConfig: string,
        visita: Visita.Visita,
    ) => {
        const responderChecklistApiResult = await _responderChecklistApi();
        if (responderChecklistApiResult.isFailure())
            return R.failure(responderChecklistApiResult.error);

        const adicionarChecklistVisita =
            await VisitaApi.adicionarChecklistVisita(
                idChecklistConfig,
                visita.id,
            );
        if (adicionarChecklistVisita.isFailure())
            return R.failure(adicionarChecklistVisita.error);

        const checklists = await ChecklistApi.getChecklist(visita.id);
        if (checklists.isFailure()) return R.failure(checklists.error);

        const planosAcao = await _getPlanosAcaoApi(checklists.value);
        if (planosAcao.isFailure()) return R.failure(planosAcao.error);

        atualizarVisitaLojaStore(visita, checklists.value, planosAcao.value);
    };

    const _getPlanosAcaoApi = async (
        checklists: Checklist.Checklist[],
    ): ResultAsync<
        HttpExceptions | ApiException | AtividadePlanoAcaoInvalida,
        PlanoAcao.PlanoAcao[]
    > => {
        const planosAcao: PlanoAcao.PlanoAcao[] = [];
        for (const checklist of checklists) {
            const planoAcao = await PlanoAcaoApi.getPlanoAcao(
                checklist.idVisita,
                checklist.id,
            );
            if (planoAcao.isFailure()) return R.failure(planoAcao.error);

            if (planoAcao.value) planosAcao.push(planoAcao.value);
        }

        return R.ok(planosAcao);
    };

    const _responderChecklistApi = async (
        checklists?: Checklist.Checklist[],
    ) => {
        for (const checklist of checklists || checklistsStore) {
            const itensRespondidos =
                Checklist.getItensChecklistRespondidos(checklist);
            if (itensRespondidos.length === 0) continue;

            const responderChecklistResult =
                await ChecklistApi.responderChecklist({
                    idVisita: checklist.idVisita,
                    respostas: itensRespondidos.map((a) => {
                        return {
                            idItemChecklist: a.id,
                            resposta: a.answer.answer as string,
                            comentario: a.answer.comment,
                        };
                    }),
                });
            if (responderChecklistResult.isFailure())
                return R.failure(responderChecklistResult.error);
        }

        return R.ok();
    };

    const removerChecklist = async (
        idChecklist: string,
        visita: Visita.Visita,
    ) => {
        const removerchecklist = await VisitaApi.removerCheklistVisita(
            idChecklist,
            visita.id,
        );
        if (removerchecklist.isFailure())
            return R.failure(removerchecklist.error);

        const checklists = await ChecklistApi.getChecklist(visita.id);
        if (checklists.isFailure()) return R.failure(checklists.error);

        const planosAcao = await _getPlanosAcaoApi(checklists.value);
        if (planosAcao.isFailure()) return R.failure(planosAcao.error);

        atualizarVisitaLojaStore(visita, checklists.value, planosAcao.value);
    };

    const iniciarVisita = async (loja: Loja, idChecklistConfig: string) => {
        const iniciarVisita = await VisitaApi.iniciarVisita(
            loja.id,
            idChecklistConfig,
        );
        if (iniciarVisita.isFailure()) return R.failure(iniciarVisita.error);

        const novaVisita = await VisitaApi.getVisita({
            idLoja: loja.id,
            ultimaVisita: true,
        });
        if (novaVisita.isFailure()) return R.failure(novaVisita.error);

        const ultimaVisita = novaVisita.value[0];

        const checklists = await ChecklistApi.getChecklist(ultimaVisita.id);
        if (checklists.isFailure()) return R.failure(checklists.error);

        const planosAcao = await _getPlanosAcaoApi(checklists.value);
        if (planosAcao.isFailure()) return R.failure(planosAcao.error);

        MapaContext.navegarParaVisita();

        iniciarVisitaLojaStore(
            ultimaVisita,
            checklists.value,
            planosAcao.value,
        );

        return R.ok();
    };

    const continuarVisita = async (visita: Visita.Visita) => {
        const checklists = await ChecklistApi.getChecklist(visita.id);
        if (checklists.isFailure()) return R.failure(checklists.error);

        const planosAcao = await _getPlanosAcaoApi(checklists.value);
        if (planosAcao.isFailure()) return R.failure(planosAcao.error);

        continuarVisitaLojaStore(visita, checklists.value, planosAcao.value);

        return R.ok();
    };

    const carregarVisitas = async (props: CarregarVisitasProps) => {
        const getVisita = await VisitaApi.getVisita({
            idLoja: props.idLoja,
            ultimaVisita: true, // remover quando for listar todas as visitas da loja
        });

        let visitas: Visita.Visita[] = [];
        if (
            getVisita.isFailure() &&
            !(getVisita.error instanceof VisitaNaoEncontradaException)
        )
            return R.failure(getVisita.error);
        if (getVisita.isOk()) visitas = getVisita.value;

        const checklistConfig = await ChecklistConfigApi.getChecklistConfig();
        if (checklistConfig.isFailure()) return;

        carregarVisitasLojaStore(
            visitas,
            checklistConfig.value,
            props.navegarPara,
        );

        return R.ok();
    };

    const salvarChecklistsVisita = async (
        visita: Visita.Visita,
        checklists: Checklist.Checklist[],
    ) => {
        const responderChecklistApiResult =
            await _responderChecklistApi(checklists);
        if (responderChecklistApiResult.isFailure())
            return R.failure(responderChecklistApiResult.error);

        const planosAcao = await _getPlanosAcaoApi(checklists);
        if (planosAcao.isFailure()) return R.failure(planosAcao.error);

        atualizarVisitaLojaStore(visita, checklists, planosAcao.value);
        return R.ok();
    };

    const responderItemChecklist = async (
        checklist: Checklist.Checklist,
        itemChecklistId: string,
        resposta: boolean | number | string,
    ) => {
        const _checklist = Checklist.responderItemChecklist(
            checklist,
            itemChecklistId,
            resposta,
        );

        atualizarChecklistLojaStore(_checklist);

        return R.ok();
    };

    const adicionarComentarioItemChecklist = async (
        checklist: Checklist.Checklist,
        itemChecklistId: string,
        comentario: string,
    ) => {
        const _checklist = Checklist.addComentarioItemChecklist(
            checklist,
            itemChecklistId,
            comentario,
        );

        atualizarChecklistLojaStore(_checklist);

        return R.ok();
    };

    const _configurarPlanoAcaoApi = async (
        planoAcaoList: PlanoAcao.PlanoAcao[],
    ) => {
        for (const planoAcao of planoAcaoList) {
            const configurarPlanoAcao = await PlanoAcaoApi.configurarPlanoAcao({
                idVisita: planoAcao.idVisita,
                configuracao: PlanoAcao.listarAtividadesConfiguradas(
                    planoAcao,
                ).map((a) => ({
                    id: a.id,
                    prazo: a.prazo!,
                    responsavel: a.responsavel!,
                    observacao: a.observacao,
                })),
                idPlanoAcao: planoAcao.id,
            });

            if (configurarPlanoAcao.isFailure())
                return R.failure(configurarPlanoAcao.error);
        }

        return R.ok();
    };

    const concluirVisita = async ({
        visita,
        checklists,
        planoAcao,
        localizacao,
    }: ConcluirVisitaProps): ConcluirVisitaResult => {
        const visitaConcluida = Visita.concluirVisita(visita, localizacao);

        const configurarPlanoAcao = await _configurarPlanoAcaoApi(planoAcao);
        if (configurarPlanoAcao.isFailure())
            return R.failure(configurarPlanoAcao.error);

        const responderChecklistApiResult =
            await _responderChecklistApi(checklists);
        if (responderChecklistApiResult.isFailure())
            return R.failure(responderChecklistApiResult.error);

        const concluirVisita = await VisitaApi.concluirVisita({
            visita: visitaConcluida,
        });
        if (concluirVisita.isFailure()) return R.failure(concluirVisita.error);

        const pdf = await VisitaApi.getPDFVisita(visita?.id);
        if (pdf.isFailure()) return R.failure(pdf.error);

        const checklistApi = await ChecklistApi.getChecklist(visita.id);
        if (checklistApi.isFailure()) return R.failure(checklistApi.error);

        const planosAcaoApiResult = await _getPlanosAcaoApi(checklists);
        if (planosAcaoApiResult.isFailure())
            return R.failure(planosAcaoApiResult.error);

        atualizarVisitaLojaStore(
            visitaConcluida,
            checklistApi.value,
            planosAcaoApiResult.value,
            {
                nome: "loja",
                aba: ABAS_TELA_LOJA.VISITAS,
            },
        );

        MapaContext.navegarParaLoja(ABAS_TELA_LOJA.VISITAS);

        return R.ok(pdf.value);
    };

    const adicionarPrazoAtividadePlanoAcaoVisita = ({
        planoAcao,
        idAtividade,
        prazo,
    }: AdicionarPrazoAtividadePlanoAcaoVisitaProps) => {
        const _planoAcao = PlanoAcao.adicionarPrazoAtividade(
            planoAcao,
            idAtividade,
            prazo,
        );

        atualizarPlanoAcaoLojaStore(_planoAcao);
    };

    const adicionarObservacaoAtividadePlanoAcaoVisita = ({
        observacao,
        idAtividade,
        planoAcao,
    }: {
        observacao?: string;
        idAtividade: string;
        planoAcao: PlanoAcao.PlanoAcao;
    }) => {
        const _planoAcao = PlanoAcao.adicionarObservacaoAtividade(
            planoAcao,
            idAtividade,
            observacao,
        );

        atualizarPlanoAcaoLojaStore(_planoAcao);
    };

    const adicionarResponsavelAtividadePlanoAcaoVisita = ({
        responsavel,
        idAtividade,
        planoAcao,
    }: {
        responsavel: PlanoAcao.ResponsavelPlanoAcao;
        idAtividade: string;
        planoAcao: PlanoAcao.PlanoAcao;
    }) => {
        const _planoAcao = PlanoAcao.adicionarResponsavelAtividade(
            planoAcao,
            idAtividade,
            responsavel,
        );

        atualizarPlanoAcaoLojaStore(_planoAcao);
    };

    const configurarPlanosAcaoVisita = async (
        planosAcao: PlanoAcao.PlanoAcao[],
    ): ConfigurarPlanoAcaoresult => {
        for (const planoAcao of planosAcao) {
            const configurarPlanoAcao = await PlanoAcaoApi.configurarPlanoAcao({
                idVisita: planoAcao.idVisita,
                configuracao: PlanoAcao.listarAtividadesConfiguradas(
                    planoAcao,
                ).map((a) => ({
                    id: a.id,
                    prazo: a.prazo!,
                    responsavel: a.responsavel!,
                    observacao: a.observacao,
                })),
                idPlanoAcao: planoAcao.id,
            });

            if (configurarPlanoAcao.isFailure())
                return R.failure(configurarPlanoAcao.error);
        }

        return R.ok();
    };

    return {
        // Visita
        carregarVisitas,
        iniciarVisita,
        continuarVisita,
        concluirVisita,
        // Checklist
        adicionarChecklist,
        removerChecklist,
        salvarChecklistsVisita,
        responderItemChecklist,
        adicionarComentarioItemChecklist,
        // Plano de Ação
        adicionarPrazoAtividadePlanoAcaoVisita,
        adicionarObservacaoAtividadePlanoAcaoVisita,
        adicionarResponsavelAtividadePlanoAcaoVisita,
        configurarPlanosAcaoVisita,
    };
};
