import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, post } from "@shared/http/Http.service";
import { ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type IniciarVisitaExceptions = HttpExceptions | ApiException;
export type IniciarVisitaResult = ResultAsync<IniciarVisitaExceptions, void>;

export async function iniciarVisita(
    idLoja: string,
    idChecklistConfig: string
): IniciarVisitaResult {
    return await post(`${basePath}`, {
        body: { storeId: idLoja, idChecklistConfig: [idChecklistConfig] },
    });
}
