import { Checklist } from "@models/Checklist";
import React, { useState } from "react";
import { View } from "react-native";
import { ChecklistSanfona } from "./ChecklistSanfona";

export interface ListaChecklistsProps {
    checklists: Checklist[];
    getChildren: (checklist: Checklist) => React.ReactNode;
    removerChecklist?: (id: string) => void;
    mostrarBotaoExcluir?: boolean;
}

export const ListaChecklists = ({
    checklists,
    getChildren,
    removerChecklist,
    mostrarBotaoExcluir = true,
}: ListaChecklistsProps) => {
    const [aberto, setAberto] = useState<{ [key: string]: boolean }>(() =>
        checklists.reduce((acc, checklist) => {
            if (Object.keys(acc).length === 0) acc[checklist.id] = true;
            else acc[checklist.id] = false;
            return acc;
        }, {}),
    );

    return checklists.map((checklist) => {
        return (
            <ChecklistSanfona
                key={checklist.id}
                titulo={checklist.titulo}
                deleteDesabilitado={checklists.length == 1}
                aberto={aberto[checklist.id]}
                onPress={() =>
                    setAberto({
                        ...aberto,
                        [checklist.id]: !aberto[checklist.id],
                    })
                }
                onDeletePress={() =>
                    removerChecklist && removerChecklist(checklist.id)
                }
                mostrarBotaoExcluir={mostrarBotaoExcluir}
            >
                {getChildren(checklist)}
            </ChecklistSanfona>
        );
    });
};
