import { TechnicalException } from "@shared/exceptions/TechnicalException";

export class LojaNaoCarregadaException extends TechnicalException {
    LojaNaoCarregadaException = "key";

    constructor() {
        super("Loja não carregada");
    }

    getInstance(): this {
        return this;
    }

    getName(): string {
        return LojaNaoCarregadaException.name;
    }
}
