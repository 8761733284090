import { View } from "react-native";

export interface ContainerProps {
    children: React.ReactNode;
    className?: string;
}

export const Container = ({ children, className }: ContainerProps) => {
    return (
        <View className={`flex flex-1 ${className}`}>
            {children}
        </View>
    );
};
