import { Text, View } from "react-native";
import { TextInput, TextInputProps } from "./TextInput";

export interface EmailInputProps extends TextInputProps {}

export const EmailInput = ({
    value,
    label,
    onChangeText,
    ...props
}: EmailInputProps) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const emailValido = value == "" ? true : emailRegex.test(value || "");

    return (
        <View className="flex flex-col">
            <TextInput
                label={label}
                value={value}
                onChangeText={onChangeText}
                error={!emailValido}
                {...props}
            />
            {!emailValido && (
                <Text className="text-xs text-red-500">E-mail inválido</Text>
            )}
        </View>
    );
};
