import { Ionicons } from "@expo/vector-icons";
import { ChecklistButtonContainer } from "@pages/Visita/components/ChecklistButtonContainer";
import React from "react";
import { Pressable, StyleSheet, View, ViewProps } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "../../styles/GlobalStyles";

export interface BooleanChecklistButtonProps extends ViewProps {
    title: string;
    answer: boolean | "not_answered";
    comment?: string;
    onAnswered: (answer: boolean | "not_answered", comment?: string) => void;
    onDetail: () => void;
}

export const BooleanChecklistButton = ({
    title,
    answer,
    comment,
    onAnswered,
    onDetail,
    ...props
}: BooleanChecklistButtonProps) => {
    const primeiroRender = React.useRef(true);

    const theme = useTheme<Theme>();

    const [answerState, setAnswerState] = React.useState<
        boolean | "not_answered"
    >(answer);

    const [commentState, setCommentState] = React.useState<string | undefined>(
        comment
    );

    React.useEffect(() => {
        if (!primeiroRender.current) {
            onAnswered(answerState, commentState);
        }
        primeiroRender.current = false;
    }, [answerState, commentState]);

    const styles = StyleSheet.create({
        containerYes: {
            backgroundColor: answerState == true ? "#C6EFCE" : "transparent",
        },
        iconYes: {
            color: answerState == true ? "#006100" : theme.colors.icon.light,
        },
        containerNo: {
            backgroundColor: answerState == false ? "#FFC7CE" : "transparent",
        },
        iconNo: {
            color: answerState == false ? "#9C0006" : theme.colors.icon.light,
        },
        line: {
            borderColor: theme.colors.line.default,
        },
    });

    return (
        <ChecklistButtonContainer {...props}>
            <View className="flex flex-row items-start rounded-lg border border-zinc-300 divide-x divide-zinc-300 ">
                <Pressable
                    onPress={() => {
                        setAnswerState(true);
                    }}
                >
                    <View
                        className="flex flex-col items-center rounded-l-md pl-4 pr-4 pt-2 pb-2"
                        style={styles.containerYes}
                    >
                        <Ionicons
                            name="checkmark-circle-outline"
                            size={25}
                            style={styles.iconYes}
                        />
                    </View>
                </Pressable>
                <Pressable
                    onPress={() => {
                        setAnswerState(false);
                    }}
                >
                    <View
                        className="items-center pl-4 pr-4 pt-2 pb-2"
                        style={styles.containerNo}
                    >
                        <Ionicons
                            name="close-circle-outline"
                            size={25}
                            style={styles.iconNo}
                        />
                    </View>
                </Pressable>
                <Pressable
                    onPress={() => {
                        //setModal(true);
                        onDetail();
                    }}
                >
                    <View className=" items-center rounded-r-md pl-4 pr-4 pt-2 pb-2">
                        {!commentState || commentState == "" ? (
                            <Ionicons
                                name="ellipsis-horizontal-circle-outline"
                                size={24}
                                color={theme.colors.icon.light}
                            />
                        ) : (
                            <Ionicons
                                name="alert-circle-outline"
                                size={24}
                                color={theme.colors.icon.alert}
                            />
                        )}
                    </View>
                </Pressable>
            </View>
        </ChecklistButtonContainer>
    );
};
