import { useState } from "react";
import { Pressable, Text, View } from "react-native";
import {
    Menu,
    TextInputProps as TextInputPropsRNP,
    TextInput as TextInputRNP,
} from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import { Label } from "../text/Label";

const Input: React.FC<TextInputProps> = ({
    left,
    right,
    labelFora,
    label,
    ...props
}) => {
    return (
        <View className="flex flex-col">
            {labelFora && <Label label={label} selectable={false} />}
            <TextInputRNP
                className="flex flex-1 w-full text-zinc-600 focus:outline-none"
                mode="outlined"
                left={left && <TextInputRNP.Icon icon={left} />}
                right={right && <TextInputRNP.Icon icon={right} />}
                label={labelFora ? undefined : label}
                {...props}
            />
        </View>
    );
};

export interface TextInputProps
    extends Omit<TextInputPropsRNP, "left" | "right"> {
    left?: IconSource;
    right?: IconSource;
    opcoesAutocomplete?: string[];
    onOpcaoAutoCompleteSelecionada?: (opcao: string) => void;
    labelFora?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
    left,
    right,
    opcoesAutocomplete,
    onOpcaoAutoCompleteSelecionada,
    labelFora,
    ...props
}) => {
    const [visible, setVisible] = useState(false);

    const openMenu = () => {
        setVisible(true);
    };
    const closeMenu = () => {
        setVisible(false);
    };

    const opcaoSelecionadaHandler = (opcao: string) => {
        onOpcaoAutoCompleteSelecionada && onOpcaoAutoCompleteSelecionada(opcao);
        closeMenu();
    };

    const _options =
        props.value == ""
            ? opcoesAutocomplete
            : opcoesAutocomplete?.filter((option) =>
                  option
                      .toLowerCase()
                      .includes((props.value || "").toLowerCase())
              ) || [];

    return _options && _options.length > 0 ? (
        <Menu
            visible={visible}
            onDismiss={closeMenu}
            anchor={
                <Pressable
                    onPress={openMenu}
                    className={`flex flex-col focus:outline-none`}
                >
                    <Input
                        mode="outlined"
                        left={left}
                        right={right}
                        labelFora={labelFora}
                        {...props}
                    />
                </Pressable>
            }
            anchorPosition="bottom"
            contentStyle={{
                backgroundColor: "white",
                width: 430,
                maxWidth: 430,
            }}
            style={{
                maxWidth: 430,
                width: 430,
                minWidth: 430,
            }}
        >
            {_options.map((option, index) => {
                return (
                    <Text
                        key={option}
                        className="px-4 py-1 hover:cursor-pointer hover:bg-zinc-100 text-zinc-600 text-lg"
                        onPress={() => opcaoSelecionadaHandler(option)}
                    >
                        {option}
                    </Text>
                );
            })}
        </Menu>
    ) : (
        <Input
            mode="outlined"
            left={left}
            right={right}
            labelFora={labelFora}
            {...props}
        />
    );
};
