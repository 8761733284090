import { CHAVES_GLOSSARIO, useGlossario } from "@hooks/useGlossario";
import { useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, Pressable, Text, View } from "react-native";
import { Button as ButtonRNP, Menu } from "react-native-paper";
import { Props } from "react-native-paper/lib/typescript/components/Button/Button";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";

export interface BotaoModeloProps extends Props {
    size?: "sm" | "md";
}

const BotaoModelo = ({ children, size = "md", ...props }: BotaoModeloProps) => {
    const labelStyle = {
        marginVertical: size == "sm" ? 4 : 10,
        marginHorizontal: size == "sm" ? 16 : 24,
    };

    return (
        <ButtonRNP
            {...props}
            style={{ borderRadius: 5 }}
            labelStyle={{ ...labelStyle }}
        >
            {children}
        </ButtonRNP>
    );
};

export interface BotaoProps {
    children: React.ReactNode;
    onPress?: () => void;
    className?: string;
    loading?: boolean;
    disabled?: boolean;
    onLayout?: ((event: LayoutChangeEvent) => void) | undefined;
    icon?: IconSource;
    posicaoIcone?: "left" | "right";
    size?: "sm" | "md";
}

export const BotaoElevado = ({ children, ...props }: BotaoProps) => {
    return (
        <BotaoModelo {...props} mode="elevated">
            {children}
        </BotaoModelo>
    );
};

export const BotaoDelineado = ({ children, ...props }: BotaoProps) => {
    return (
        <BotaoModelo {...props} mode="outlined">
            {children}
        </BotaoModelo>
    );
};

export const Botao = ({ children, ...props }: BotaoProps) => {
    return (
        <BotaoModelo {...props} mode="contained">
            {children}
        </BotaoModelo>
    );
};

export const BotaoComGlossario = ({
    loading,
    opcoes,
    onPressOption,
    ...props
}: Omit<BotaoProps, "children"> & {
    chaveGlossario: CHAVES_GLOSSARIO;
    opcoes?: string[];
    onPressOption?: (opcao: string) => void;
}) => {
    const { carregarTexto, texto } = useGlossario();

    useEffect(() => {
        carregar();
    }, []);

    const carregar = async () => {
        carregarTexto(props.chaveGlossario);
    };

    const viewRef = useRef<View>(null);

    const [menuPosition, setMenuPosition] = useState({
        y: 0,
        x: 0,
        width: 0,
    });

    const onPositionMenu = () => {
        opcoes &&
            viewRef.current?.measureInWindow((x, y, width, height) => {
                const variavel = 40 * (opcoes.length - 1);
                setMenuPosition({
                    y: y - (47 + variavel),
                    x: x,
                    width: width,
                });
            });
    };

    const [visibleChecklistConfigOpcoes, setVisibleChecklistConfigOpcoes] =
        useState(false);

    return !opcoes || opcoes.length === 0 ? (
        <Botao {...props} loading={!texto || loading}>
            {texto || `Carregando...`}
        </Botao>
    ) : (
        <View onLayout={onPositionMenu} ref={viewRef}>
            <Botao
                {...props}
                loading={!texto || loading}
                onPress={() => setVisibleChecklistConfigOpcoes(true)}
            >
                {texto || `Carregando...`}
            </Botao>
            <View>
                <Menu
                    visible={visibleChecklistConfigOpcoes}
                    onDismiss={() => setVisibleChecklistConfigOpcoes(false)}
                    anchorPosition="top"
                    anchor={{
                        x: menuPosition.x,
                        y: menuPosition.y,
                    }}
                    contentStyle={{
                        width: menuPosition.width,
                        paddingTop: 2,
                        paddingBottom: 2,
                    }}
                    style={{
                        width: menuPosition.width,
                    }}
                >
                    {opcoes.map((option) => {
                        return (
                            <Pressable
                                key={option}
                                onPress={() =>
                                    onPressOption && onPressOption(option)
                                }
                            >
                                <View className="h-10 items-center justify-center rounded hover:bg-green-200 hover:bg-opacity-40 ">
                                    <Text className="text-md font-bold text-zinc-600">
                                        {option}
                                    </Text>
                                </View>
                            </Pressable>
                        );
                    })}
                </Menu>
            </View>
        </View>
    );
};
