import * as AuthenticationApi from "@api/Autenticacao/Authentication.api";
import { useErro } from "@shared/components/Error/hooks/useErro";
import {
    loadingQueue,
    useLoading,
} from "@shared/components/Loading/hooks/useLoading";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Screens } from "@routes/Screens";
import React, { useEffect } from "react";
import { Exception } from "src/shared/exceptions/Exception";

export type UseChangePassword = {
    email: string;
    setEmail: (email: string) => void;
    currentPassword: string;
    setCurrentPassword: (currentPassword: string) => void;
    newPassword: string;
    setNewPassword: (newPassword: string) => void;
    confirmPassword: string;
    setConfirmPassword: (confirmPassword: string) => void;
    changePassword: () => void;
    goBack: () => void;
    showPasswordChangedMessage: boolean;
    closePasswordChangedMessage: () => void;
    loadingQueue: loadingQueue;
    error?: Exception;
    setError: React.Dispatch<React.SetStateAction<Exception | undefined>>;
};

export interface UseChangePasswordProps {
    email: string;
    currentPassword: string;
}

export const useChangePassword = ({
    currentPassword: _currentPassword,
    email: _email,
}: UseChangePasswordProps): UseChangePassword => {
    const { addLoading, removeLoading, loadingQueue } = useLoading();
    const { setErro: setError, erro: error } = useErro();
    const navigation = useNavigation<NativeStackNavigationProp<any>>();

    const [email, setEmail] = React.useState("");
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [showPasswordChangedMessage, setShowPasswordChangedMessage] =
        React.useState(false);

    useEffect(() => {
        setEmail(_email);
        setCurrentPassword(_currentPassword);
    }, [_email, _currentPassword]);

    const changePassword = async () => {
        const tag = "useChangePasswordContext.changePassword";
        addLoading(tag);
        const changePasswordResult = await AuthenticationApi.changePassword({
            email,
            currentPassword,
            newPassword,
        });

        if (changePasswordResult.isFailure()) {
            setError(changePasswordResult.error);
        } else {
            setShowPasswordChangedMessage(true);
        }

        removeLoading(tag);
    };

    const resetProps = () => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };

    const goBack = () => {
        resetProps();
        navigation.goBack();
    };

    const closePasswordChangedMessage = () => {
        setShowPasswordChangedMessage(false);
        navigation.navigate(Screens.SIGN_IN);
    };

    return {
        email,
        setEmail,
        currentPassword,
        setCurrentPassword,
        newPassword,
        setNewPassword,
        confirmPassword,
        setConfirmPassword,
        changePassword,
        goBack,
        showPasswordChangedMessage,
        closePasswordChangedMessage,
        loadingQueue,
        error,
        setError,
    };
};
