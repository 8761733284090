import { Exception } from "./Exception";

export class TechnicalException extends Exception {
    TechnicalException = "key";

    constructor(creationProps?: Error | string) {
        super(creationProps, "TechnicalException");
    }

    getName(): string {
        return TechnicalException.name;
    }

    getInstance(): this {
        return this;
    }
}
