import { ActivityIndicator } from "react-native-paper";
import { Props } from "react-native-paper/lib/typescript/components/ActivityIndicator";

export interface LoaderProps {
    size?: "xs" | "sm" | "lg" | number;
    color?: string;
}

export const Loader = ({ size = "sm", color }: LoaderProps) => {
    const _size =
        size === "xs" ? 20 : size === "sm" ? 30 : size === "lg" ? 50 : size;

    return <ActivityIndicator size={_size} color={color} />;
};
