import { Ionicons } from "@expo/vector-icons";
import { Text, View } from "react-native";

export interface ItemsListProps {
    label: string;
    value: string | number;
    icon?: any;
}

export const ItemsList = ({ label, value, icon }: ItemsListProps) => {
    return (
        <View className="flex flex-row py-1 border-t border-t-zinc-200">
            <View className="flex flex-row flex-1 space-x-1 px-2 items-center py-1">
                <View className="mr-2 w-6">
                    {icon && <Ionicons name={icon} size={22} color="#00AF14" />}
                </View>
                <Text>{label}:</Text>
                <Text className="text-[#270040] font-semibold">{value}</Text>
            </View>
        </View>
    );
};
