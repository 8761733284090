import { Menu } from "@components/Menu/Menu";
import { Ionicons } from "@expo/vector-icons";
import { Loja } from "@models/Loja/Loja";
import { StoreIcon } from "@shared/components/Icons/StoreIcon";
import React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "../../styles/GlobalStyles";

export interface BarraPesquisaProps {
    lojas?: Loja[];
    onLojaSelecionada?: (loja: Loja) => void;
    navigation?: any;
}

export default function BarraPesquisa({
    lojas,
    onLojaSelecionada,
}: BarraPesquisaProps) {
    const theme = useTheme<Theme>();

    const [termoPesquisa, setTermoPesquisa] = React.useState("");
    const [showLojasPesquisadas, setShowLojasPesquisadas] =
        React.useState(false);

    const styles = StyleSheet.create({
        icon: {
            color: theme.colors.icon.primary,
        },
    });

    const resultadoPesquisa = lojas?.filter(
        (loja) =>
            loja.title.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
            loja.address.toLowerCase().includes(termoPesquisa.toLowerCase())
    );

    const lojaSelecionadaHandler = (loja: Loja) => {
        setTermoPesquisa("");
        setShowLojasPesquisadas(false);
        onLojaSelecionada?.(loja);
    };

    const pesquisaFocusHandler = () => {
        setShowLojasPesquisadas(true);
    };

    const pesquisaBlurHandler = () => {
        setTimeout(() => {
            setShowLojasPesquisadas(false);
        }, 100);
    };

    return (
        <View className="absolute w-full mt-8 px-4 items-center">
            <View
                className={`flex flex-col rounded-3xl w-full max-w-md  border-zinc-200 shadow-sm shadow-zinc-500 bg-white`}
            >
                <View className="flex flex-row items-center rounded-3xl space-x-2 bg-white p-2 px-4 ">
                    <Ionicons name="search" size={24} style={styles.icon} />

                    <TextInput
                        className="flex-1 text-zinc-600 text-md focus:outline-none"
                        placeholder="Pesquise aqui..."
                        value={termoPesquisa}
                        onChangeText={setTermoPesquisa}
                        onFocus={pesquisaFocusHandler}
                        onBlur={pesquisaBlurHandler}
                    />

                    <Menu tipo="mobile" />
                </View>

                {showLojasPesquisadas && (
                    <View className="flex flex-col px-4 pt-2 pb-4 bg-zinc-100 rounded-b-3xl max-h-60 overflow-auto">
                        {resultadoPesquisa && resultadoPesquisa?.length > 0 ? (
                            resultadoPesquisa?.map((loja) => (
                                <LojaPesquisada
                                    key={loja.id}
                                    loja={loja}
                                    onLojaSelecionada={() =>
                                        lojaSelecionadaHandler(loja)
                                    }
                                />
                            ))
                        ) : (
                            <Text>Nenhuma loja encontrada</Text>
                        )}
                    </View>
                )}
            </View>
        </View>
    );
}

export interface LojaPesquisadaProps {
    loja: Loja;
    onLojaSelecionada: () => void;
}

export const LojaPesquisada = ({
    loja,
    onLojaSelecionada,
}: LojaPesquisadaProps) => {
    return (
        <Pressable onPress={onLojaSelecionada}>
            <View className="grid grid-rows-2 grid-cols-12 auto-cols-max gap-x-4 px-4 py-2 rounded-lg hover:bg-green-200 hover:bg-opacity-40 ">
                <View className="row-span-2 items-center justify-center">
                    <StoreIcon size="md" />
                </View>
                <View className="col-span-10 ">
                    <Text className="text-md font-bold text-zinc-600">
                        {loja.title}
                    </Text>
                </View>
                <View className="col-span-10 ">
                    <Text className="text-xs">{loja.address}</Text>
                </View>
            </View>
        </Pressable>
    );
};
