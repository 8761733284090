import { ResumoPlanoAcao } from "@components/ResumoPlanoAcao/ResumoPlanoAcao";
import * as ErroContext from "@contexts/Erro";
import * as LojaStore from "@contexts/Loja";
import * as MapaContext from "@contexts/Mapa";
import { useMapaContext } from "@contexts/Mapa";
import { usePlanoAcaoStore } from "@contexts/PlanoAcao";
import { ChecklistNaoCarregadoException } from "@exceptions/Checklist/ChecklistNaoCarregado.exception";
import { LojaNaoCarregadaException } from "@exceptions/Loja/LojaNaoCarregada.exception";
import { PlanoAcaoNaoCarregadoException } from "@exceptions/PlanoAcao/PlanoAcaoNaoCarregado.exception";
import { CadastroObservacao } from "@features/PlanoAcao/components/CadastroObservacao";
import { usePlanoAcao } from "@hooks/usePlanoAcao";
import { PROP_NAMES } from "@pages/Visita/components/AtividadePlanoAcao";
import {
    AtividadePlanoAcaoType,
    ListaAtividadesPlanoAcao,
} from "@pages/Visita/components/ListaAtividadesPlanoAcao";
import { BackIcon } from "@shared/components/Icons/BackIcon";
import { HeaderBar, PageHeader, Titulo } from "@shared/components/page";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { useState } from "react";
import { ScrollView } from "react-native";

type ShowInclusaoObservacao = {
    idAtividade: string;
    tipo: "observacao";
    observacaoAtual?: string;
};

export const PaginaRespostaPlanoAcao = () => {
    const navegarParaLoja = MapaContext.navegarParaLoja;

    const voltarHandler = () => {
        navegarParaLoja(LojaStore.ABAS_TELA_LOJA.PLANOS_DE_ACAO);
    };

    const [showSelecao, setShowSelecao] = useState<ShowInclusaoObservacao>();

    const resumoPlanoAcao = usePlanoAcaoStore((state) => state.resumoPlanoAcao);
    const planoAcao = usePlanoAcaoStore((state) => state.planoAcao);
    const checklist = usePlanoAcaoStore((state) => state.checklist);

    const { concluirAtividadePlanoAcaoComoPromotor } = usePlanoAcao();

    if (!checklist) {
        ErroContext.addErro(new ChecklistNaoCarregadoException());
        return;
    }

    const loja = useMapaContext((state) => state.lojaSelecionada);

    const concluirAtividadePlanoAcaoHandler = async (
        atividade: AtividadePlanoAcaoType,
    ) => {
        if (!resumoPlanoAcao) {
            ErroContext.addErro(new PlanoAcaoNaoCarregadoException());
            return;
        }
        if (!loja) {
            ErroContext.addErro(new LojaNaoCarregadaException());
            return;
        }
        if (!planoAcao) {
            ErroContext.addErro(new PlanoAcaoNaoCarregadoException());
            return;
        }

        const concluirAtividadePlanoAcao =
            await concluirAtividadePlanoAcaoComoPromotor({
                idVisita: resumoPlanoAcao.visita.id,
                idAtividade: atividade.id,
                observacao: "",
                idLoja: loja.id,
                idChecklist: checklist.id,
                idPlanoAcao: planoAcao.id,
            });

        if (concluirAtividadePlanoAcao.isFailure()) {
            ErroContext.addErro(concluirAtividadePlanoAcao.error);
            return;
        }
    };

    const onClickProps = (propName: PROP_NAMES, id: string) => {
        switch (propName) {
            case PROP_NAMES.OBSERVACAO:
                setShowSelecao({
                    tipo: "observacao",
                    idAtividade: id,
                    observacaoAtual: planoAcao?.atividades.find(
                        (a) => a.id === id,
                    )?.observacao,
                });
        }
    };

    return (
        <Container>
            <ScrollView>
                <PageHeader>
                    <HeaderBar>
                        <Titulo label={"Plano de Ação"} color="#00AF14" />
                        <BackIcon color={"#00AF14"} onPress={voltarHandler} />
                    </HeaderBar>
                </PageHeader>
                <Body>
                    <ResumoPlanoAcao
                        data={{
                            nomeLoja: loja?.title,
                            nomePromotor: resumoPlanoAcao?.planoAcao.promotor,
                            numeroVisita: resumoPlanoAcao?.visita.numero,
                            dataInicio: resumoPlanoAcao?.visita.dataCriacao,
                            dataFim: resumoPlanoAcao?.planoAcao.dataLimite,
                            status: resumoPlanoAcao?.planoAcao.status,
                            percentualProgresso:
                                resumoPlanoAcao?.planoAcao.percentualPrazo,
                        }}
                    />

                    <ListaAtividadesPlanoAcao
                        atividades={
                            planoAcao?.atividades.map((atividade) => ({
                                id: atividade.id,
                                descricao: atividade.descricao,
                                status: atividade.status,
                                prazo: atividade.prazo,
                                responsavel: atividade.responsavel,
                                opcoesPrazo: atividade.prazo
                                    ? [atividade.prazo]
                                    : [],
                                dataInicio: resumoPlanoAcao?.visita.dataCriacao,
                                itemChecklistGatilho:
                                    atividade.itemChecklistGatilho,
                                observacao: atividade.observacao,
                                onClick(prop) {
                                    onClickProps(prop, atividade.id);
                                },
                                planoAcao: planoAcao,
                            })) || []
                        }
                        onConcluirAtividadeClick={
                            concluirAtividadePlanoAcaoHandler
                        }
                        checklist={checklist}
                        modo={"execucao"}
                    />
                </Body>

                {showSelecao?.tipo === "observacao" && (
                    <CadastroObservacao
                        observacaoAtual={showSelecao.observacaoAtual}
                        onClose={() => {
                            setShowSelecao(undefined);
                        }}
                        onSave={(observacao) => {}}
                        modo="visualizacao"
                    />
                )}
            </ScrollView>
        </Container>
    );
};
