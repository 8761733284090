import * as PlanoAcaoLoja from "@models/PlanoAcao";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { ResumoPlanoAcaoApi } from "src/infra/types/ResumoPlanoAcao";
import { basePath } from "./constants";

export type ListarPlanosAcaoLojaExceptions = HttpExceptions | ApiException;
export type ListarPlanosAcaoLojaResult = ResultAsync<
    ListarPlanosAcaoLojaExceptions,
    PlanoAcaoLoja.ResumoPlanoAcao[]
>;

type ListarResumosPlanoAcaoResponse = {
    resumo: ResumoPlanoAcaoApi;
    tenantId: string;
};

export const listarResumosPlanoAcao = async (
    idLoja: string,
): ListarPlanosAcaoLojaResult => {
    const detalhesApi = await get<ListarResumosPlanoAcaoResponse[]>(
        `${basePath}/${idLoja}/visit/action-plan`,
    );

    if (detalhesApi.isFailure()) return R.failure(detalhesApi.error);

    const result: PlanoAcaoLoja.ResumoPlanoAcao[] = [];
    for (const detalhes of detalhesApi.value) {
        const planoAcaoLoja = await PlanoAcaoLoja.criarResumoPlanoAcao({
            visita: {
                id: detalhes.resumo.visita.id,
                numero: detalhes.resumo.visita.numero,
                dataCriacao: detalhes.resumo.visita.dataCriacao,
            },
            planoAcao: {
                id: detalhes.resumo.id,
                status: detalhes.resumo.status,
                percentualPrazo: detalhes.resumo.progresso,
                promotor: detalhes.resumo.promotor.nome,
                dataLimite: detalhes.resumo.dataLimite,
                quantidadeParticipantes:
                    detalhes.resumo.quantidadeParticipantes,
                quantidadeTotalAtividades: detalhes.resumo.quantidadeAtividades,
                quantidadeAtividadesConcluidas:
                    detalhes.resumo.quantidadeAtividadesConcluidas,
                loja: { ...detalhes.resumo.loja },
            },
            checklist: {
                id: detalhes.resumo.checklist.id,
                titulo: detalhes.resumo.checklist.nome,
            },
        });

        result.push(planoAcaoLoja);
    }

    return R.ok(result);
};
