import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import * as PlanoAcaoModel from "@models/PlanoAcao";
import { getStatusAtividadePlanoAcaoFromString } from "@models/PlanoAcao/StatusAtividadePlanoAcao";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { PlanoAcao as PlanoAcaoApiType } from "src/infra/types/PlanoAcao";
import { getParametrosPlanosAcao } from "./GetParametrosPlanoAcao";
import { basePath } from "./constants";

export type GetPlanoAcaoResult = ResultAsync<
    HttpExceptions | ApiException | AtividadePlanoAcaoInvalida,
    PlanoAcaoModel.PlanoAcao
>;

export async function getPlanoAcao(
    idVisita: string,
    idChecklist: string
): GetPlanoAcaoResult {
    const atividadesNecessariasPromise = await getParametrosPlanosAcao(
        idVisita,
        idChecklist
    );

    const planoAcaoApiPromise = get<PlanoAcaoApiType>(
        `${basePath}/${idVisita}/checklist/${idChecklist}/action-plan?filter=current`
    );

    const [planoAcaoApi, atividadesNecessariasApi] = await Promise.all([
        planoAcaoApiPromise,
        atividadesNecessariasPromise,
    ]);

    if (planoAcaoApi.isFailure()) return R.failure(planoAcaoApi.error);
    if (atividadesNecessariasApi.isFailure())
        return R.failure(atividadesNecessariasApi.error);

    const atividadesNecessarias = atividadesNecessariasApi.value.map((a) =>
        PlanoAcaoModel.criarParametroPlanoAcao({
            id: a.id,
            descricao: a.description,
            responsaveis: a.sponsors,
            prazos: a.deadlines,
            itemChecklistGatilho: a.itemChecklistGatilho,
        })
    );

    if (!planoAcaoApi.value) return R.ok();

    const planoAcao = PlanoAcaoModel.criarPlanoAcao({
        id: planoAcaoApi.value.id,
        parametros: planoAcaoApi.value.parametros,
        status: planoAcaoApi.value.status,
        atividades: planoAcaoApi.value.atividades.map((p) => ({
            id: p.id,
            descricao: p.descricao,
            status: getStatusAtividadePlanoAcaoFromString(p.status),
            prazo: p.prazo,
            responsavel: p.responsavel,
            observacao: p.informacaoAdicional,
            itemChecklistGatilho: p.itemChecklistGatilho,
        })),
        atividadesNecessarias,
        idVisita,
        idChecklist,
    });

    return planoAcao;
}
