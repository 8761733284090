import { Ionicons } from "@expo/vector-icons";
import React from "react";

export interface FingerPrintIconProps {
    size?: number;
    color?: string;
}

export const FingerPrintIcon: React.FC<FingerPrintIconProps> = ({
    size = 120,
    color = "#f87171",
}) => {
    return <Ionicons name="finger-print" size={size} color={color} />;
};
