import { CardResumoPlanoAcaoLoader } from "@components/CardResumoPlanoAcao/CardResumoPlanoAcaoLoader";
import * as ErroContext from "@contexts/Erro";
import { useMapaContext } from "@contexts/Mapa/MapaContext";
import { usePlanoAcao } from "@hooks/usePlanoAcao";
import { ResumoPlanoAcao } from "@models/PlanoAcao";
import { Label } from "@shared/components/text/Label";
import { Fragment, useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import { CardResumoPlanoAcao } from "../CardResumoPlanoAcao/CardResumoPlanoAcao";

export const ListaPlanosAcao = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const [resumosPlanosAcao, setResumosPlanoAcao] = useState<
        ResumoPlanoAcao[]
    >([]);

    const { listarResumosPlanoAcao, navegarParaPlanoAcao } = usePlanoAcao();

    const loja = useMapaContext((state) => state.lojaSelecionada);

    useEffect(() => {
        setLoading(true);
        listarResumosPlanosAcao().finally(() => setLoading(false));
    }, []);

    const listarResumosPlanosAcao = async () => {
        if (!loja) {
            ErroContext.addErro(new Error("Loja não carregada"));
            return;
        }
        const resumosPlanoAcao = await listarResumosPlanoAcao(loja.id);
        if (resumosPlanoAcao.isFailure()) {
            ErroContext.addErro(resumosPlanoAcao.error);
            return;
        }
        setResumosPlanoAcao(resumosPlanoAcao.value);
    };

    const cardPlanoAcaoClicadoHandler = async (
        resumoPlanoAcao: ResumoPlanoAcao
    ) => {
        const navegarParaPlanocao = await navegarParaPlanoAcao(resumoPlanoAcao);
        if (navegarParaPlanocao.isFailure()) {
            ErroContext.addErro(navegarParaPlanocao.error);
        }
    };

    return (
        <Fragment>
            {loading && (
                <Fragment>
                    <CardResumoPlanoAcaoLoader />
                    <CardResumoPlanoAcaoLoader />
                    <CardResumoPlanoAcaoLoader />
                </Fragment>
            )}

            {!loading && resumosPlanosAcao.length > 0 && (
                <FlatList
                    data={resumosPlanosAcao}
                    keyExtractor={(planoAcaoLoja) => planoAcaoLoja.visita.id}
                    ItemSeparatorComponent={() => (
                        <View className="w-full border-t h-1 border-zinc-200" />
                    )}
                    renderItem={({ item: planoAcaoLoja }) => (
                        <CardResumoPlanoAcao
                            resumoPlanoAcao={planoAcaoLoja}
                            onClick={() => {
                                cardPlanoAcaoClicadoHandler(planoAcaoLoja);
                            }}
                        />
                    )}
                />
            )}

            {!loading && resumosPlanosAcao.length == 0 && (
                <View className="flex items-center justify-center p-2 ">
                    <Label label="Este ponto de venda não possui nenhum plano de ação." />
                </View>
            )}
        </Fragment>
    );
};
