import React, { Fragment } from "react";
import { Text, View } from "react-native";
import { Tooltip, useTheme } from "react-native-paper";

type Colors = "green" | "zinc" | "transparent" | string;
export interface TagProps {
    icon?: React.ReactNode;
    label: string | number;
    labelColor?: string;
    tooltipMessage?: string;
    color?: Colors;
}

export const Tag = ({
    icon,
    label,
    color = "zinc",
    tooltipMessage,
    labelColor,
    ...props
}: TagProps) => {
    return tooltipMessage ? (
        <Tooltip
            title={tooltipMessage}
            enterTouchDelay={100}
            leaveTouchDelay={50}
        >
            <Container color={color}>
                <Content icon={icon} label={label} labelColor={labelColor} />
            </Container>
        </Tooltip>
    ) : (
        <Container color={color}>
            <Content icon={icon} label={label} />
        </Container>
    );
};

const Container = ({
    color,
    children,
}: {
    color: Colors;
    children: React.ReactNode;
}) => {
    const colorCss = {
        green: "bg-green-100 text-green-500",
        zinc: "bg-zinc-100 text-zinc-500",
        transparent: "bg-transparent text-zinc-500 border border-zinc-200",
    };

    return (
        <View
            className={`flex flex-row space-x-1 items-center px-3 py-1 rounded-2xl ${colorCss[color]}`}
        >
            {children}
        </View>
    );
};

const Content = ({
    icon,
    label,
    labelColor = "black",
}: {
    icon?: React.ReactNode;
    label: string | number;
    labelColor?: string;
}) => (
    <Fragment>
        {icon && icon}
        <Text className="text-xs" style={{ color: `${labelColor}` }}>
            {label}
        </Text>
    </Fragment>
);
