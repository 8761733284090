import { View } from "react-native";
import { Snackbar, Text, useTheme } from "react-native-paper";
import { Theme } from "../../../styles/GlobalStyles";

export interface AlertProps {
    visible: boolean;
    onDismiss: () => void;
    actionLabel?: string;
    onActionClick?: () => void;
    duration?: number;
    icon?: React.ReactNode;
    message: string;
}

export const Alert = ({
    visible,
    onDismiss,
    actionLabel,
    onActionClick,
    duration = 5000,
    icon,
    message,
}: AlertProps) => {
    const theme = useTheme<Theme>();

    return (
        <Snackbar
            visible={visible}
            onDismiss={onDismiss}
            action={
                actionLabel
                    ? {
                          label: actionLabel,
                          onPress: () => onActionClick,
                      }
                    : undefined
            }
            duration={duration}
            className="w-full z-50"
            style={{ backgroundColor: theme.colors.secundary }}
        >
            <View className="flex flex-row w-full space-x-2 items-center">
                {icon && icon}
                <Text className="text-white">{message}</Text>
            </View>
        </Snackbar>
    );
};
