import { ModalDetalheRespostaItemChecklist } from "@components/Checklist/ModalDetalheRespostaItemChecklist";
import * as ErroContext from "@contexts/Erro";
import * as ErroStore from "@contexts/Erro";
import * as LojaStore from "@contexts/Loja";
import { ChecklistNaoCarregadoException } from "@exceptions/Checklist/ChecklistNaoCarregado.exception";
import { VisitaNaoCarregadaException } from "@exceptions/Visita/VisitaNaoCarregada.exception";
import { useVisita } from "@hooks/useVisita";
import {
    ChecklistConfig,
    Checklist as ChecklistModel,
} from "@models/Checklist";
import * as ItemChecklistModel from "@models/Checklist/ItemChecklist";
import { LocalOnClickItemChecklist } from "@pages/Visita/components/ItemChecklist";
import { Botao, BotaoDelineado } from "@shared/components/button/Button";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { Footer } from "@shared/components/page/Footer";
import { Select } from "@shared/components/Select/Select";
import { useState } from "react";
import { ScrollView, View } from "react-native";
import { ActivityIndicator, IconButton } from "react-native-paper";
import { Checklist } from "./Checklist";
import { ListaChecklists } from "./ListaChecklists";
import { ModalAjudaItemChecklist } from "./ModalAjudaItemChecklist";

export interface AbaChecklistProps {
    checklists: ChecklistModel[];
    checklistsConfig: ChecklistConfig[];
    onConcluirVisita: () => void;
    loadingBotaoConcluir?: boolean;
}

type noShowModalComplemento = {
    show: false;
};

/**
 * "ajuda" = indica que o usuário precisa de ajuda em como validar e responder o item do checklist
 * "complemento" = indica que o usuário deseja adicionar um complemento à sua resposta do checklist (ex: uma observação)
 */
export type TipoModalComplemento = "ajuda" | "complemento";

type showModalComplemento = {
    show: boolean;
    detalhe: {
        tipoModal: TipoModalComplemento;
        itemChecklist: ItemChecklistModel.TiposItensChecklist;
        checklist: ChecklistModel;
    };
};

export const AbaChecklist = ({
    checklists = [],
    onConcluirVisita,
    loadingBotaoConcluir,
    checklistsConfig,
}: AbaChecklistProps) => {
    const [adicionandoChecklistLoader, setAdicionandoChecklistLoader] =
        useState(false);

    const {
        adicionarChecklist: adicionarChecklistHook,
        removerChecklist: removerVisitaHook,
        salvarChecklistsVisita,
        adicionarComentarioItemChecklist,
    } = useVisita();

    const visita = LojaStore.useLojaStore(
        (store: LojaStore.LojaStore) => store.visitaSelecionada,
    );

    const [open, setOpen] = useState<{ [key: string]: boolean }>(() =>
        checklists.reduce((acc, checklist) => {
            if (Object.keys(acc).length === 0) acc[checklist.id] = true;
            else acc[checklist.id] = false;
            return acc;
        }, {}),
    );
    const [loading, setLoading] = useState<"salvar" | undefined>(undefined);

    /**
     * Envia todas as responstas realizadas no checklist para o backend
     */
    const salvarChecklistHandler = async () => {
        setLoading("salvar");

        if (checklists.length === 0) {
            ErroStore.addErro(new ChecklistNaoCarregadoException());
            setLoading(undefined);
            return;
        }
        if (!visita) {
            ErroStore.addErro(new VisitaNaoCarregadaException());
            setLoading(undefined);
            return;
        }

        const salvarChecklist = await salvarChecklistsVisita(
            visita,
            checklists,
        );
        if (salvarChecklist.isFailure()) {
            ErroStore.addErro(salvarChecklist.error);
            setLoading(undefined);
            return;
        }

        setLoading(undefined);
    };

    const opcoesChecklistsConfig = checklistsConfig
        .filter(
            (checklistConfig) =>
                !checklists.find(
                    (checklist) => checklist.titulo === checklistConfig.nome,
                ),
        )
        .map((checklistConfig) => ({
            rotulo: checklistConfig.nome,
            valor: checklistConfig.id,
        }));

    const removerChecklist = (idChecklist: string) => {
        if (!visita) {
            ErroStore.addErro(new VisitaNaoCarregadaException());
            return;
        }
        removerVisitaHook(idChecklist, visita);
    };

    const adicionarChecklist = (idChecklistConfig: string) => {
        if (!visita) {
            ErroStore.addErro(new VisitaNaoCarregadaException());
            return;
        }
        setAdicionandoChecklistLoader(true);
        adicionarChecklistHook(idChecklistConfig, visita).finally(() => {
            setAdicionandoChecklistLoader(false);
        });
        setChecklistConfigSelecionado(undefined);
    };

    const [checklistConfigSelecionado, setChecklistConfigSelecionado] =
        useState<string | undefined>(undefined);

    const [showModalComplemento, setShowModalComplemento] = useState<
        noShowModalComplemento | showModalComplemento
    >({ show: false });

    const onClickItemChecklist = (
        local: LocalOnClickItemChecklist,
        itemChecklist: ItemChecklistModel.TiposItensChecklist,
        checklist: ChecklistModel,
    ) => {
        switch (local) {
            case "titulo":
                setShowModalComplemento({
                    show: true,
                    detalhe: {
                        tipoModal: "ajuda",
                        itemChecklist,
                        checklist,
                    },
                });
                break;
            case "detalhe-resposta":
                setShowModalComplemento({
                    show: true,
                    detalhe: {
                        itemChecklist,
                        tipoModal: "complemento",
                        checklist,
                    },
                });
                break;
        }
    };

    const salvarModalDetalheRespostaHandler = async (
        comentario: string,
        itemChecklist: ItemChecklistModel.TiposItensChecklist,
        checklist: ChecklistModel,
    ) => {
        const addComentarioItemChecklist =
            await adicionarComentarioItemChecklist(
                checklist,
                itemChecklist.id,
                comentario,
            );

        if (addComentarioItemChecklist.isFailure()) {
            ErroContext.addErro(addComentarioItemChecklist.error);
            setShowModalComplemento({ show: false });
            return;
        }

        setShowModalComplemento({ show: false });
    };

    return (
        <Container>
            <ScrollView>
                <Body className="p-2">
                    {checklistsConfig.length > 1 && (
                        <View className="flex flex-row space-x-2 items-center">
                            <View className="flex flex-row grow">
                                <Select
                                    options={opcoesChecklistsConfig}
                                    onSelect={(itemValue) => {
                                        setChecklistConfigSelecionado(
                                            itemValue,
                                        );
                                    }}
                                    label="Adicionar checklist"
                                    size="sm"
                                    enabled={
                                        !adicionandoChecklistLoader &&
                                        opcoesChecklistsConfig.length > 0
                                    }
                                />
                            </View>
                            {adicionandoChecklistLoader ? (
                                <ActivityIndicator
                                    animating={true}
                                    className="mt-6 p-2"
                                    size={24}
                                />
                            ) : (
                                <IconButton
                                    icon="plus"
                                    iconColor={"#00AF14"}
                                    size={24}
                                    onPress={() =>
                                        checklistConfigSelecionado &&
                                        adicionarChecklist(
                                            checklistConfigSelecionado,
                                        )
                                    }
                                    style={{ marginTop: 30 }}
                                    disabled={
                                        !checklistConfigSelecionado ||
                                        opcoesChecklistsConfig.length <= 0
                                    }
                                />
                            )}
                        </View>
                    )}

                    {checklists.length > 0 &&
                        (checklistsConfig.length > 1 ? (
                            <ListaChecklists
                                checklists={checklists}
                                removerChecklist={removerChecklist}
                                getChildren={(checklist: ChecklistModel) => {
                                    return (
                                        <Checklist
                                            key={checklist.id}
                                            checklist={checklist}
                                            onClickItemChecklist={(
                                                local,
                                                itemChecklist,
                                            ) => {
                                                onClickItemChecklist(
                                                    local,
                                                    itemChecklist,
                                                    checklist,
                                                );
                                            }}
                                        />
                                    );
                                }}
                            />
                        ) : (
                            <Checklist
                                key={checklists[0].id}
                                checklist={checklists[0]}
                                onClickItemChecklist={(
                                    local,
                                    itemChecklist,
                                ) => {
                                    onClickItemChecklist(
                                        local,
                                        itemChecklist,
                                        checklists[0],
                                    );
                                }}
                            />
                        ))}
                </Body>
            </ScrollView>

            <Footer>
                <View className="flex flex-row justify-around space-x-2">
                    <View className="grow">
                        <BotaoDelineado
                            onPress={salvarChecklistHandler}
                            disabled={!!loading || loadingBotaoConcluir}
                            loading={loading == "salvar"}
                        >
                            Salvar
                        </BotaoDelineado>
                    </View>
                    <View className="grow">
                        <Botao
                            onPress={onConcluirVisita}
                            disabled={!!loading || loadingBotaoConcluir}
                            loading={loadingBotaoConcluir}
                        >
                            Concluir
                        </Botao>
                    </View>
                </View>
            </Footer>

            {showModalComplemento.show &&
                showModalComplemento.detalhe?.tipoModal == "ajuda" && (
                    <ModalAjudaItemChecklist
                        item={showModalComplemento.detalhe?.itemChecklist}
                        onClose={() => setShowModalComplemento({ show: false })}
                    />
                )}

            {showModalComplemento.show &&
                showModalComplemento.detalhe?.tipoModal == "complemento" && (
                    <ModalDetalheRespostaItemChecklist
                        item={showModalComplemento.detalhe.itemChecklist}
                        onClose={() => setShowModalComplemento({ show: false })}
                        onSave={(comentario) =>
                            salvarModalDetalheRespostaHandler(
                                comentario,
                                showModalComplemento.detalhe.itemChecklist,
                                showModalComplemento.detalhe.checklist,
                            )
                        }
                    />
                )}
        </Container>
    );
};
