import { Ionicons } from "@expo/vector-icons";
import { IconProps, SizeIcons } from ".";
import { View } from "react-native";

export const BackIcon = ({
    size = "md",
    onPress,
    color = "black",
    className,
}: IconProps) => {
    return (
        <View>
            <Ionicons
                name="chevron-back-outline"
                size={SizeIcons[size]}
                color={color}
                onPress={onPress}
                className={className}
            />
        </View>
    );
};
