import { LabelLoader } from "@components/Loaders/LabelLoader";
import { TagLoader } from "@components/Loaders/TagLoader";
import { View } from "react-native";

export const CardResumoPlanoAcaoLoader = () => {
    return (
        <View className="flex flex-col p-2 space-y-2">
            <View className="flex flex-row">
                <LabelLoader />
                <TagLoader />
            </View>
            <LabelLoader />
            <View className="flex flex-row space-x-2">
                <TagLoader />
                <TagLoader />
                <TagLoader />
                <TagLoader />
            </View>
        </View>
    );
};
