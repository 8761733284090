import * as AuthenticationApi from "@api/Autenticacao/Authentication.api";
import { useErro } from "@shared/components/Error/hooks/useErro";
import { useLoading } from "@shared/components/Loading/hooks/useLoading";
import React from "react";

export interface UseForgotPassword {
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    forgotPassword: () => Promise<boolean>;
}

export function useForgotPassword(): UseForgotPassword {
    const { addLoading, removeLoading } = useLoading();

    const [email, setEmail] = React.useState("");
    const [showForgotPasswordModal, setShowForgotPasswordModal] =
        React.useState(false);

    const { setErro: setError } = useErro();

    const forgotPassword = async (): Promise<boolean> => {
        let success = false;
        const tag = "useAuthenticationContext.forgotPassword";
        addLoading(tag);

        const forgotPasswordResult = await AuthenticationApi.forgotPassword(
            email
        );
        if (forgotPasswordResult.isFailure()) {
            setError(forgotPasswordResult.error);
        } else {
            success = true;
        }

        removeLoading(tag);
        return success;
    };

    return {
        email,
        setEmail,
        forgotPassword,
    };
}
