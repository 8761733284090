import { BarraPogressoPlanoAcao } from "@components/PlanoAcao/BarraProgressoPlanoAcao";
import { CalendarIcon } from "@shared/components/Icons/CalendarIcon";
import { PersonIcon } from "@shared/components/Icons/PersonIcon";
import { StoreIcon } from "@shared/components/Icons/StoreIcon";
import { CheckIcon } from "@shared/components/Icons/check/CheckIcon";
import { IconLabelValue } from "@shared/components/itemList/IconLabelValue";
import { Body } from "@shared/components/page/Body";
import { Label } from "@shared/components/text/Label";
import { View } from "react-native";

export type ResumoPlanoAcao = {
    nomeLoja?: string;
    nomePromotor?: string;
    numeroVisita?: number;
    dataInicio?: Date;
    dataFim?: Date;
    status?: string;
    percentualProgresso?: number;
};

export interface ResumoPlanoAcaoProps {
    data: ResumoPlanoAcao;
}

export const ResumoPlanoAcao = ({ data }: ResumoPlanoAcaoProps) => {
    return (
        <View className="pt-2">
            <IconLabelValue
                icon={<StoreIcon size="sm" color="#00AF14" />}
                label="Ponto de Venda"
                value={data.nomeLoja}
            />

            <IconLabelValue
                icon={<PersonIcon size="sm" color="#00AF14" />}
                label="Supervisor"
                value={data.nomePromotor}
            />

            <IconLabelValue
                icon={<CheckIcon size="sm" color="#00AF14" />}
                label="Visita"
                value={`#${data.numeroVisita}`}
            />

            <View className="flex flex-row justify-between ">
                <IconLabelValue
                    icon={<CalendarIcon size="sm" color="#00AF14" />}
                    label="Início"
                    value={
                        data.dataInicio && data.dataInicio.toLocaleDateString()
                    }
                />
                <IconLabelValue
                    icon={<CalendarIcon size="sm" color="#00AF14" />}
                    label="Fim"
                    value={data.dataFim && data.dataFim.toLocaleDateString()}
                />
            </View>

            <View className="flex flex-row items-center space-x-4 px-2 mt-1">
                <BarraPogressoPlanoAcao progresso={data.percentualProgresso} />
                <Label label={data.status} />
            </View>
        </View>
    );
};
