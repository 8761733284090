import { CustomDrawerContainer } from "@components/CustomDrawerContainer/CustomDrawerContainer";
import * as MapaContext from "@contexts/Mapa";
import { PaginaLoja } from "@pages/Loja/PaginaLoja";
import { PaginaRespostaPlanoAcao } from "@pages/PlanoAcao/PaginaRespostaPlanoAcao";
import { PaginaVisita } from "@pages/Visita/PaginaVisita";
import React from "react";
import { Drawer } from "react-native-drawer-layout";
import ErrorBoundary from "react-native-error-boundary";
import { MapaStack } from "./MapaStack";
import * as ErroContext from "@contexts/Erro";
import { MessageModal } from "@shared/components/modal/MessageModal";

export type MenusCustomizadosMapaContextType = {
    isMenuLojaAberto: boolean;
    abrirMenuLoja: () => void;
    fecharMenuLoja: () => void;
};

export const MenusCustomizadosMapaContext =
    React.createContext<MenusCustomizadosMapaContextType>({
        isMenuLojaAberto: false,
        abrirMenuLoja: () => {},
        fecharMenuLoja: () => {},
    });

export function MenuCustomizadoMapaStack() {
    const drawer = MapaContext.useMapaContext((state) => state.drawer);
    const loja = MapaContext.useMapaContext((state) => state.lojaSelecionada);
    const deselecionarLoja = MapaContext.useMapaContext(
        (state) => state.deselecionarLoja
    );

    return (
        <Drawer
            open={!!loja}
            onOpen={() => {
                //setIsMenuLojaAberto(true);
            }}
            onClose={() => {
                loja && deselecionarLoja();
            }}
            drawerPosition="left"
            drawerStyle={{ width: 448 }}
            renderDrawerContent={() => (
                <CustomDrawerContainer>
                    <ErrorBoundary>
                        {drawer?.nome === "loja" && <PaginaLoja />}
                        {drawer?.nome === "visita" && <PaginaVisita />}
                        {drawer?.nome === "planoAcao" && (
                            <PaginaRespostaPlanoAcao />
                        )}
                    </ErrorBoundary>
                </CustomDrawerContainer>
            )}
        >
            <MapaStack />
        </Drawer>
    );
}
