export type DetalheLoja = {
    order: number;
    label: string;
    value: string | number | Date;
    icon?: string;
};

export const criarDetalheLoja = (props: DetalheLoja): DetalheLoja => {
    return {
        ...props,
    };
};
