import { Loja } from "@models/Loja/Loja";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import Constants from "expo-constants";
import { isDevtoolsHabilitado } from "..";

export enum ABAS_TELA_LOJA {
    GERAL = "geral",
    VISITAS = "visitas",
    PLANOS_DE_ACAO = "planosAcao",
}

export enum ABAS_TELA_VISITA {
    CHECKLIST = "checklist",
    PLANO_ACAO = "planoAcao",
}

export type Drawers =
    | { nome: "loja"; aba: ABAS_TELA_LOJA }
    | { nome: "visita"; aba: ABAS_TELA_VISITA }
    | { nome: "planoAcao" };

export type MapaState = {
    lojas: Loja[];
    lojaSelecionada?: Loja;
    drawer?: Drawers;
    localizacaoObrigatoria?: boolean;
};

export type MapaActions = {
    continuarVisita: () => void;
    selectionarLoja: (loja: Loja) => void;
    alterarTabDrawerVisita: (novaTab: ABAS_TELA_VISITA) => void;
    deselecionarLoja: () => void;
    navegarParaMapa: () => void;
};

export type GlobalStore = MapaState & MapaActions;

export const useMapaContext = create<GlobalStore>()(
    devtools(
        (set, get) => ({
            lojas: [],
            lojaSelecionada: undefined,
            drawer: undefined,
            localizacaoObrigatoria: false,
            continuarVisita: () => {
                set(
                    (state) => ({
                        ...state,
                        drawer: {
                            nome: "visita",
                            aba: ABAS_TELA_VISITA.CHECKLIST,
                        },
                    }),
                    false,
                    "continuarVisita"
                );
            },
            selectionarLoja: (loja: Loja) => {
                set(
                    (state) => ({
                        ...state,
                        lojaSelecionada: loja,
                        drawer: { nome: "loja", aba: ABAS_TELA_LOJA.GERAL },
                    }),
                    false,
                    "selecionarLoja"
                );
            },
            alterarTabDrawerVisita: (novaTab: ABAS_TELA_VISITA) => {
                set(
                    (state) => ({
                        ...state,
                        drawer: { nome: "visita", aba: novaTab },
                    }),
                    false,
                    "selecionarLoja"
                );
            },
            deselecionarLoja: () => {
                set(
                    (state) => ({
                        ...state,
                        lojaSelecionada: undefined,
                    }),
                    false,
                    "deselecionarLoja"
                );
            },
            navegarParaMapa: () => {
                set(
                    (state) => ({
                        ...state,
                        drawer: undefined,
                        lojaSelecionada: undefined,
                    }),
                    false,
                    "navegarParaMapa"
                );
            },
        }),
        {
            name: "MapaStore",
            enabled: isDevtoolsHabilitado(),
        }
    )
);

export const selecionarLoja = (loja: Loja) =>
    useMapaContext.setState(
        {
            lojaSelecionada: loja,
            drawer: { nome: "loja", aba: ABAS_TELA_LOJA.GERAL },
        },
        false,
        "selecionarLoja"
    );

export const deselecionarLoja = () =>
    useMapaContext.setState(
        (state) => ({
            ...state,
            lojaSelecionada: undefined,
        }),
        false,
        "deselecionarLoja"
    );
