import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { isDevtoolsHabilitado } from "..";

export type LoginState = {
    logado: boolean;
    menus: string[];
};

export type LoginStore = LoginState;

export const useLoginStore = create(
    devtools<LoginStore>(
        (set, get) => ({
            logado: false,
            menus: [],
        }),
        { name: "LoginStore", enabled: isDevtoolsHabilitado() }
    )
);

export const logIn = (menus: string[]) =>
    useLoginStore.setState({ logado: true, menus }, false, "logIn");

export const logOut = () =>
    useLoginStore.setState({ logado: false }, false, "logOut");
