export type ItemChecklist = {
    id: string;
    title: string;
    type: "boolean" | "numeric" | "select";
    category: string;
    question: string;
    description: string;
    answerRange: {
        minValue: number;
        maxValue: number;
    };
    ordem?: number;
};

export type ItemChecklistBoolean = ItemChecklist & {
    type: "boolean";
    answer: {
        answer?: boolean | "not_answered";
        comment: string;
    };
};

export type ItemChecklistNumeric = ItemChecklist & {
    type: "numeric";
    sufixo?: string;
    answer: {
        answer?: number | "not_answered";
        comment: string;
    };
};

export type OpcaoItemChecklistSelect = string;

export type ItemChecklistSelect = ItemChecklist & {
    type: "select";
    answer: {
        answer?: string | "not_answered";
        comment: string;
    };
    opcoes: OpcaoItemChecklistSelect[];
};

export type TiposItensChecklist =
    | ItemChecklistBoolean
    | ItemChecklistNumeric
    | ItemChecklistSelect;

export function criarItemChecklist(
    props: TiposItensChecklist,
): TiposItensChecklist {
    switch (props.type) {
        case "boolean":
            return criarItemChecklistBooleano(props);
        case "numeric":
            return criarItemChecklistNumerico(props);
        case "select":
            return criarItemChecklistSelect(props);
        default:
            throw new Error("Tipo de item de checklist inválido");
    }
}

export function criarItemChecklistSelect(
    props: ItemChecklistSelect,
): ItemChecklistSelect {
    return {
        id: props.id,
        type: "select",
        title: props.title,
        category: props.category,
        question: props.question,
        description: props.description,
        answerRange: props.answerRange,
        answer: props.answer,
        opcoes: props.opcoes,
        ordem: props.ordem,
    };
}

export function criarItemChecklistNumerico(
    props: ItemChecklistNumeric,
): ItemChecklistNumeric {
    return {
        id: props.id,
        type: "numeric",
        title: props.title,
        category: props.category,
        question: props.question,
        description: props.description,
        answerRange: props.answerRange,
        answer: props.answer,
        sufixo: props.sufixo,
        ordem: props.ordem,
    };
}

export function criarItemChecklistBooleano(
    props: ItemChecklistBoolean,
): ItemChecklistBoolean {
    return {
        id: props.id,
        type: "boolean",
        title: props.title,
        category: props.category,
        question: props.question,
        description: props.description,
        answerRange: {
            minValue: 0,
            maxValue: 1,
        },
        answer: props.answer,
        ordem: props.ordem,
    };
}

export type ItemChecklistCategorizado = {
    nomeCategoria: string;
    itens: TiposItensChecklist[];
};

export function categorizarItensChecklist(
    itens: TiposItensChecklist[],
): ItemChecklistCategorizado[] {
    const itensCategorizados: ItemChecklistCategorizado[] = [];

    for (const item of itens) {
        const grupoExistente = itensCategorizados.find(
            (grupo) => grupo.nomeCategoria === item.category,
        );

        grupoExistente
            ? grupoExistente.itens.push(item)
            : itensCategorizados.push({
                  nomeCategoria: item.category,
                  itens: [item],
              });
    }

    return itensCategorizados;
}
