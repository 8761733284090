import { CHAVES_GLOSSARIO, useGlossario } from "@hooks/useGlossario";
import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

export interface TituloPadraoProps {
    color?: string;
}

export interface TituloProps extends TituloPadraoProps {
    label?: string;
}

export const Titulo = ({ label, color }: TituloProps) => {
    return (
        <View className="absolute flex flex-row flex-1 items-center justify-center w-full h-6">
            <Text
                className="text-xl font-bold truncate"
                style={{ color: color || "black" }}
            >
                {label}
            </Text>
        </View>
    );
};

export interface TituloComGlossarioProps extends TituloPadraoProps {
    chaveGlossario?: CHAVES_GLOSSARIO;
}

export const TituloComGlossario = ({
    chaveGlossario,
    color,
}: TituloComGlossarioProps) => {
    const { texto, carregarTexto, componenteLoader } = useGlossario();

    React.useEffect(() => {
        if (chaveGlossario) {
            carregarTexto(chaveGlossario);
        }
    }, [chaveGlossario]);

    return (
        <View className="absolute flex flex-row flex-1 items-center justify-center w-full h-6">
            {texto ? (
                <Text
                    className="text-xl font-bold truncate"
                    style={{ color: color || "black" }}
                >
                    {texto}
                </Text>
            ) : (
                componenteLoader
            )}
        </View>
    );
};

export interface PageHeaderProps {
    children?: React.ReactNode;
}

export const PageHeader = ({ children }: PageHeaderProps) => {
    return (
        <View className="flex flex-col py-2 border-b border-zinc-200">
            {children}
        </View>
    );
};

export interface HeaderBarProps {
    children?: React.ReactNode;
}

export const HeaderBar = ({ children }: HeaderBarProps) => {
    return <View className="flex flex-row items-center h-6">{children}</View>;
};
