import { ABAS_TELA_LOJA } from "@contexts/Loja";
import { useMapaContext } from "../MapaContext";

export const navegarParaLoja = (aba: ABAS_TELA_LOJA) => {
    useMapaContext.setState(
        (state) => ({
            ...state,
            drawer: { nome: "loja", aba },
        }),
        false,
        "navegarParaLoja"
    );
};
