import { TechnicalException } from "./TechnicalException";

export class ApiException extends TechnicalException {
    ApiException = "key";

    getName(): string {
        return ApiException.name;
    }

    getInstance(): this {
        return this;
    }
}
