import { ForgotPasswordInterface } from "@/pages/login/components/forgotPassword/ForgotPassword.interface";
import { useLogin } from "@/pages/login/hooks/useLogin";
import { SenhaInput } from "@/shared/components/input/SenhaInput";
import { TextInput } from "@/shared/components/input/TextInput";
import { Ionicons } from "@expo/vector-icons";
import { BottomPage } from "@shared/components/bottomPage/BottomPage";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { Image } from "react-native";
import { Keyboard, View } from "react-native";
import { Button } from "react-native-paper";

export interface ModalUsuarioSenhaProps {
    onClose: () => void;
    navigation: any;
}

export const ModalUsuarioSenha = ({
    onClose,
    navigation,
}: ModalUsuarioSenhaProps) => {
    const useLoginHook = useLogin({ navigation });

    const closeForgotPasswordModalHandler = (
        forgotPasswordSuccess: boolean,
    ) => {
        useLoginHook.setShowForgotPasswordModal(false);
        useLoginHook.setShowForgotPasswordSuccessMessage(forgotPasswordSuccess);
    };

    return (
        <BottomPage onClose={onClose}>
            <Container className="pt-2">
                <Body className="space-y-1">
                    <View className="items-center h-1/3 justify-center">
                        <Image
                            className="flex w-44 h-8 md:h-8"
                            source={require("../../../assets/images/checkei/logo-horizontal2.png")}
                            resizeMode="contain"
                        />
                    </View>

                    <TextInput
                        mode="outlined"
                        label={"E-mail"}
                        value={useLoginHook.email}
                        onChangeText={(text) => useLoginHook.setEmail(text)}
                        dense={true}
                        left={() => (
                            <Ionicons
                                name="mail-outline"
                                size={20}
                                color="black"
                            />
                        )}
                    />
                    <SenhaInput
                        value={useLoginHook.password}
                        onChangeText={(text) => useLoginHook.setPassword(text)}
                        inputMode="numeric"
                        left={() => (
                            <Ionicons
                                name="lock-closed-outline"
                                size={20}
                                color="black"
                            />
                        )}
                        dense={true}
                    />

                    <Button
                        mode="elevated"
                        onPress={() => {
                            Keyboard.dismiss();
                            useLoginHook.signIn();
                        }}
                        className="w-full"
                        buttonColor="#00AF14"
                        textColor="#fff"
                    >
                        Entrar
                    </Button>

                    <Button
                        mode="text"
                        onPress={() => {
                            Keyboard.dismiss();
                            useLoginHook.setShowForgotPasswordModal(true);
                        }}
                        className="w-full"
                    >
                        Esqueceu sua senha ?
                    </Button>
                </Body>

                <ForgotPasswordInterface
                    email={useLoginHook.email}
                    visible={useLoginHook.showForgotPasswordModal}
                    close={closeForgotPasswordModalHandler}
                />
            </Container>
        </BottomPage>
    );
};
