import * as Checklist from "@models/Checklist";
import { R } from "@shared/utils/Result";
import { useLojaStore } from "../../LojaStore";

/**
 * Atualiza o estado do checklist com comentário do item
 */
export const addComentarioItemChecklist = async (
    checklist: Checklist.Checklist
) => {
    useLojaStore.setState(
        (state) => ({ ...state, checklists: checklist }),
        false,
        "addComentarioItemChecklist"
    );

    return R.ok();
};
