import {
    AntDesign,
    Entypo,
    Ionicons,
    MaterialIcons,
    Octicons,
} from "@expo/vector-icons";

import ReactGA from "react-ga4";
import { ModalUsuarioSenha } from "@/login/components/ModalUsuarioSenha";
import { SenhaInput } from "@shared/components/input/SenhaInput";
import { TextInput } from "@shared/components/input/TextInput";
import { PageContainer } from "@shared/components/page";
import classNames from "classnames";
import React, { useState } from "react";
import {
    Animated,
    Image,
    Keyboard,
    Linking,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Pressable,
    ScrollView,
    Text,
    useWindowDimensions,
    View,
} from "react-native";
import { ForgotPasswordInterface } from "./components/forgotPassword/ForgotPassword.interface";
import { useLogin } from "./hooks/useLogin";

export const LoginInterface = ({ navigation }) => {
    const [isScrolling, setIsScrolling] = useState(false);
    const [showModalUsuarioSenha, setShowModalUsuarioSenha] = useState(false);
    const scrollY = new Animated.Value(0);

    const { width } = useWindowDimensions();

    const handleScroll = Animated.event(
        [{ nativeEvent: { contentOffset: { y: scrollY } } }],
        {
            useNativeDriver: false,
            listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => {
                const offsetY = event.nativeEvent.contentOffset.y;
                if (offsetY > 0) {
                    if (!isScrolling) {
                        setIsScrolling(true);
                    }
                } else {
                    if (isScrolling) {
                        setIsScrolling(false);
                    }
                }
            },
        },
    );

    return (
        <PageContainer>
            <View className="h-full bg-white">
                <Header
                    isScrolling={isScrolling}
                    navigation={navigation}
                    onEntrar={() => {
                        setShowModalUsuarioSenha(true);
                    }}
                />

                <ScrollView onScroll={handleScroll}>
                    <Sessao1 />
                    <Sessao2 />

                    <SessaoRecursos />
                    <Sessao4 />
                    {/*
                <Footer />
                 */}
                </ScrollView>
            </View>

            {width < 850 && showModalUsuarioSenha && (
                <ModalUsuarioSenha
                    onClose={() => {
                        setShowModalUsuarioSenha(false);
                    }}
                    navigation={navigation}
                />
            )}
        </PageContainer>
    );
};

export const Footer = () => {
    return (
        <View className="w-full items-center bg-white h-96">
            <View className="w-full max-w-7xl mx-auto">
                <View className="space-y-2">
                    <View className="flex flex-row space-x-2 items-center">
                        <Text className="text-zinc-700 underline">
                            Segurança
                        </Text>
                        <Text className="text-zinc-700">|</Text>
                        <Text className="text-zinc-700 underline">
                            Termos e privacidade
                        </Text>
                        <Text className="text-zinc-700">|</Text>
                        <Text className="text-zinc-700 underline">
                            Nossa política de privacidade
                        </Text>
                    </View>
                    <Text className="text-zinc-700">
                        Todos os direitos reservados Compartti
                    </Text>
                </View>
            </View>
        </View>
    );
};

export const SessaoRecursos = () => {
    return (
        <View className="flex bg-white py-10 space-y-20 items-center justify-center md:py-20">
            <View className="w-full  mx-auto items-center md:max-w-7xl">
                <Text className="font-semibold text-5xl  text-[#00AF14]/80 md:text-7xl">
                    {`Recursos`}
                </Text>
            </View>

            <View className="flex space-y-6 md:space-x-20 md:flex-row md:space-y-0">
                <CardRecurso
                    titulo="Parametrização total"
                    descricao={`Você pode criar checklists totalmente personalizados de acordo com a sua necessidade`}
                    icone={
                        <Entypo name="sound-mix" size={60} color="#3f3f46" />
                    }
                />
                <CardRecurso
                    titulo="Planos de ação automáticos"
                    descricao={`Todas as ações necessárias, em formato de plano de ação, são disparados automaticamente para os envolvidos com prazo e responsável`}
                    icone={
                        <Octicons name="file-badge" size={65} color="#3f3f46" />
                    }
                />
                <CardRecurso
                    titulo="Monitoramento"
                    descricao={`Acompanhe em tempo real a execução das tarefas, o status dos planos de ação e muito mais`}
                    icone={<Entypo name="location" size={65} color="#3f3f46" />}
                />
            </View>
            <View className="flex space-y-6 md:space-x-20 md:flex-row md:space-y-0">
                <CardRecurso
                    titulo="Integração com seus sistemas"
                    descricao={`Através das nossas APIs você pode integrar o Check-Ei com os sistemas que você já utiliza`}
                    icone={<AntDesign name="API" size={65} color="#3f3f46" />}
                />
                <CardRecurso
                    titulo="Alertas e notificações"
                    descricao={`Receba alertas e notificações em tempo real sobre as atividades do seu time`}
                    icone={
                        <AntDesign
                            name="notification"
                            size={65}
                            color="#3f3f46"
                        />
                    }
                />
                <CardRecurso
                    titulo="Customizável"
                    descricao={`Nossa equipe técnica está pronta para customizar o Check-Ei de acordo com a sua necessidade`}
                    icone={
                        <MaterialIcons
                            name="dashboard-customize"
                            size={65}
                            color="#3f3f46"
                        />
                    }
                />
            </View>
        </View>
    );
};

export interface CardRecursoProps {
    titulo: string;
    descricao: string;
    icone: React.ReactNode;
}

export const CardRecurso = ({
    titulo,
    descricao,
    icone,
    ...props
}: CardRecursoProps) => {
    const [hovered, setHovered] = React.useState(false);

    const classNameActionButton = classNames(
        "rounded-lg items-center px-6 py-6 h-[350px] space-y-8 w-sm max-w-[300px] border border-zinc-100 md:shadow-lg",
    );

    const classNameSaibaMais = classNames(
        "font-semibold text-white justify-center items-center text-center text-lg underline",
        {
            "text-zinc-700": hovered,
        },
    );

    return (
        <Pressable
            className={classNameActionButton}
            onHoverIn={() => {
                setHovered(true);
            }}
            onHoverOut={() => {
                setHovered(false);
            }}
            {...props}
        >
            <View className="items-center space-y-4">
                {icone}
                <Text className="font-semibold text-lg text-zinc-700">
                    {titulo}
                </Text>
            </View>
            <Text className="text-base text-zinc-700 justify-center items-center text-center">
                {descricao}
            </Text>

            {/**
            <Text className={classNameSaibaMais}>{`saiba mais`}</Text>
             */}
        </Pressable>
    );
};

export const Sessao4 = () => {
    const { width } = useWindowDimensions();

    return (
        <View className="flex flex-row bg-[#00AF14]/80 py-20 justify-center md:py-10">
            <View className="flex flex-row w-screen max-w-sm  md:items-center md:max-w-7xl md:mx-auto md:justify-around md:space-y-14">
                <View className="max-w-sm space-y-6 items-center md:max-w-7xl md:items-start">
                    {width < 850 ? (
                        <Text className="font-bold text-center text-5xl text-white">
                            {`Leve a gestão do seu time para outro nível com o Check-Ei`}
                        </Text>
                    ) : (
                        <Text className="font-bold text-white text-7xl text-start">
                            {`Leve a gestão do seu \ntime para outro nível \ncom o Check-Ei`}
                        </Text>
                    )}

                    {width < 850 ? (
                        <Text className="text-xl text-white text-center">
                            {`Faça agora mesmo um teste grátis e veja como podemos ajudar a sua empresa a crescer`}
                        </Text>
                    ) : (
                        <Text className="text-xl text-white items-center">
                            {`Faça agora mesmo um teste grátis e veja como \npodemos ajudar a sua empresa a crescer`}
                        </Text>
                    )}
                    <CallToAction
                        label="Começar"
                        cor="branco"
                        icone={
                            <AntDesign
                                name="arrowright"
                                size={18}
                                color="#00AF14"
                                style={{ marginTop: 3 }}
                            />
                        }
                        onPress={() => {
                            ReactGA.event({
                                category: "checkei_interesse_servico",
                                action: "checkei_click",
                                label: "botao_1",
                            });
                        }}
                    />
                </View>
                {width > 850 && (
                    <MaterialIcons name="auto-graph" size={500} color="white" />
                )}
            </View>
        </View>
    );
};

export const Sessao2 = () => {
    return (
        <View className="flex flex-row bg-[#00AF14]/80 py-32 justify-center px-4">
            <View className="w-full mx-auto items-center space-y-14 md:max-w-[900px]">
                <Text className="font-bold text-5xl text-white text-center md:text-start md:text-7xl">
                    {`Sua solução em auditoria`}
                </Text>
                <Text className="text-xl text-white justify-center items-center text-center font">
                    {`O Check-Ei é um aplicativo focada em auditoria de campo. \nConfigure seus checklists com questões que façam sentido para o desenvolvimento e evolução do seu negócio. Seu time executa as visitas, supervisões ou auditorias, preenche os questionários com as respostas e o Check-Ei faz toda a gestão dessas informações para você.`}
                </Text>
            </View>
        </View>
    );
};

export const Header = ({
    isScrolling,
    navigation,
    onEntrar,
}: {
    isScrolling: boolean;
    navigation: any;
    onEntrar?: () => void;
}) => {
    const useLoginHook = useLogin({ navigation });

    const closeForgotPasswordModalHandler = (
        forgotPasswordSuccess: boolean,
    ) => {
        useLoginHook.setShowForgotPasswordModal(false);
        useLoginHook.setShowForgotPasswordSuccessMessage(forgotPasswordSuccess);
    };

    const { width } = useWindowDimensions();

    const [hovered, setHovered] = React.useState(false);

    const classNameActionButton = classNames(
        "flex flex-row text-white items-center py-1 px-10 rounded-full items-end space-x-2 shadow-lg",
        {
            "bg-[#00AF14]/80": !hovered,
            "bg-[#00AF14]": hovered,
            "shadow-none": isScrolling,
        },
    );

    return (
        <Animated.View
            style={{
                backgroundColor: "white",
                position: "absolute",
                top: 0,
                paddingRight: 16,
                transform: "background-color 3s ease",
            }}
            className={classNames("w-full absolute z-10 bg-white top-0", {
                "shadow-md ": isScrolling,
            })}
        >
            <View className="flex flex-row items-center w-full max-w-7xl mx-auto justify-between py-3 px-4">
                <Image
                    className="flex w-44 h-8 md:h-8"
                    source={require("../../../assets/images/checkei/logo-horizontal2.png")}
                    resizeMode="contain"
                />

                <View className="items-start space-y-1">
                    <View className="flex flex-row space-x-5 items-end">
                        {width > 850 && (
                            <View className="flex flex-row space-x-3">
                                <View className="w-60">
                                    <TextInput
                                        mode="outlined"
                                        label={"E-mail"}
                                        value={useLoginHook.email}
                                        onChangeText={(text) =>
                                            useLoginHook.setEmail(text)
                                        }
                                        dense={true}
                                        className={classNames("shadow-lg", {
                                            "shadow-none": isScrolling,
                                        })}
                                        left={() => (
                                            <Ionicons
                                                name="mail-outline"
                                                size={20}
                                                color="black"
                                            />
                                        )}
                                    />
                                </View>
                                <View className="w-60">
                                    <SenhaInput
                                        value={useLoginHook.password}
                                        onChangeText={(text) =>
                                            useLoginHook.setPassword(text)
                                        }
                                        inputMode="numeric"
                                        className={classNames("shadow-lg", {
                                            "shadow-none": isScrolling,
                                        })}
                                        left={() => (
                                            <Ionicons
                                                name="lock-closed-outline"
                                                size={20}
                                                color="black"
                                            />
                                        )}
                                        dense={true}
                                    />
                                </View>
                            </View>
                        )}
                        <Pressable
                            className={classNameActionButton}
                            onHoverIn={() => {
                                setHovered(true);
                            }}
                            onHoverOut={() => {
                                setHovered(false);
                            }}
                            onPress={() => {
                                Keyboard.dismiss();

                                if (width < 850) {
                                    onEntrar && onEntrar();
                                } else {
                                    useLoginHook.signIn();
                                }
                            }}
                        >
                            <Text className="text-white font-semibold text-lg mb-1">
                                Entrar
                            </Text>
                        </Pressable>
                    </View>
                    {width > 850 && (
                        <View className="flex flex-row justify-start">
                            <Pressable
                                className="text-sm text-[#00AF14]"
                                onPress={() => {
                                    Keyboard.dismiss();
                                    useLoginHook.setShowForgotPasswordModal(
                                        true,
                                    );
                                }}
                            >
                                Esqueceu sua senha ?
                            </Pressable>
                        </View>
                    )}
                </View>
            </View>

            <ForgotPasswordInterface
                email={useLoginHook.email}
                visible={useLoginHook.showForgotPasswordModal}
                close={closeForgotPasswordModalHandler}
            />
        </Animated.View>
    );
};

export const Sessao1 = () => {
    const { width } = useWindowDimensions();

    return (
        <View className="flex flex-row w-screen justify-center pb-32 max-w-md md:max-w-7xl md:mx-auto md:space-x-32">
            <View className="space-y-6 pt-32 md:pt-72 items-center md:items-start">
                <Text className="font-bold text-zinc-800 text-5xl text-center md:text-start md:text-6xl">
                    {`Tenha a gestão \ndo seu time de \ncampo na palma \nda sua mão`}
                </Text>

                {width < 850 ? (
                    <Text className="max-w-sm text-xl text-zinc-700 text-center">
                        {`Otimize seus processos e aumente a produtividade da sua equipe com o Check-Ei. Acompanhe a execução de tarefas, monitore planos de ação e muito mais.`}
                    </Text>
                ) : (
                    <Text className="text-xl text-zinc-700">
                        {`Otimize seus processos e aumente a produtividade da sua \nequipe com o Check-Ei. Acompanhe a execução de tarefas, \nmonitore planos de ação e muito mais.`}
                    </Text>
                )}

                <CallToAction
                    label="Começar"
                    icone={
                        <AntDesign
                            name="arrowright"
                            size={18}
                            color="white"
                            style={{ marginTop: 3 }}
                        />
                    }
                    onPress={() => {
                        ReactGA.event({
                            category: "checkei_interesse_servico",
                            action: "checkei_checkei_click",
                            label: "botao_2",
                        });
                    }}
                />
            </View>

            {width > 850 && (
                <View className="flex w-[400px] h-[650px] rounded-3xl mt-32">
                    <Image
                        className="flex flex-1 w-full h-full"
                        source={require("../../../assets/images/celular.svg")}
                        resizeMode="cover"
                    />
                </View>
            )}
        </View>
    );
};

export const CallToAction = ({
    label,
    icone,
    cor = "verde",
    onPress,
    ...props
}: {
    label: string;
    icone: React.ReactNode;
    cor?: "verde" | "branco";
    onPress?: () => void;
}) => {
    const [hovered, setHovered] = React.useState(false);

    const classNameActionButton = classNames(
        "flex flex-row h-12 items-center px-6 rounded-full space-x-2 shadow-lg w-36",
        {
            "bg-[#00AF14]/80 ": !hovered && cor === "verde",
            "bg-[#00AF14] text-white": hovered && cor === "verde",
            "bg-white ": !hovered && cor === "branco",
            "bg-zinc-100": hovered && cor === "branco",
        },
    );

    const classNameLabel = classNames("text-lg font-semibold mb-1", {
        "text-white": cor === "verde",
        "text-[#00AF14]": cor === "branco",
    });

    return (
        <Pressable
            className={classNameActionButton}
            onHoverIn={() => {
                setHovered(true);
            }}
            onHoverOut={() => {
                setHovered(false);
            }}
            onPress={() => {
                const telefoneComercial = "5511965884699";
                const mensagemPadrao = `Olá, por favor, me explique como que o Check-Ei pode ajudar na gestão do meu time de campo.`;
                Linking.openURL(
                    `https://wa.me/${telefoneComercial}?text=${encodeURIComponent(mensagemPadrao)}`,
                );
                onPress && onPress();
            }}
            {...props}
        >
            <Text className={classNameLabel}>{label}</Text>
            {icone}
        </Pressable>
    );
};
