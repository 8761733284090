import React from "react";
import { Text, View } from "react-native";
import { twMerge } from "tailwind-merge";
import { TextBold } from "../text/TextBold";

export type IconLabelValueProps = {
    icon?: React.ReactNode;
    label: string;
    styleLabel?: string;
    value?: string | number | React.ReactNode;
    styleValue?: string;
};

export const IconLabelValue = ({
    icon,
    label,
    styleLabel,
    value,
    styleValue,
}: IconLabelValueProps) => {
    return (
        <View className={`flex flex-row space-x-1 px-2 items-center py-1`}>
            <View className="mr-2 w-6">{icon}</View>

            <Text className={twMerge("text-zinc-500", styleLabel)}>
                {label}:
            </Text>

            {typeof value === "number" || typeof value === "string" ? (
                <TextBold label={value.toString()} className={styleValue} />
            ) : (
                value
            )}
        </View>
    );
};
