import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, patch, post } from "@shared/http/Http.service";
import { ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type AdicionarChecklistVisitaExceptions = HttpExceptions | ApiException;
export type AdicionarChecklistVisitaResult = ResultAsync<
    AdicionarChecklistVisitaExceptions,
    void
>;

export async function adicionarChecklistVisita(
    idChecklistConfig: string,
    idVisita: string
): AdicionarChecklistVisitaResult {
    return await post(`${basePath}/${idVisita}/checklist`, {
        body: { idChecklistConfig: idChecklistConfig },
    });
}
