import axios from "axios";

import { HttpAccessDeniedException } from "../exceptions/http/HttpAccessDenied.exception";
import { HttpForbiddenException } from "../exceptions/http/HttpForbidden.exception";
import { HttpServiceException } from "../exceptions/http/HttpService.exception";
import { HttpTimeoutException } from "../exceptions/http/HttpTimeout.exception";
import { R, Result, ResultAsync } from "../utils/Result";
import HTTP_CONFIG from "./Http.config";

export type HttpExceptions =
    | HttpServiceException
    | HttpTimeoutException
    | HttpAccessDeniedException
    | HttpForbiddenException;

export type HttpServiceConfig = { body?: any; headers?: any };

export const axiosInstance = axios.create({
    baseURL: `${HTTP_CONFIG.baseURL}`,
    timeout: HTTP_CONFIG.timeout,
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
});

export async function get<R>(
    url: string,
    params?: any,
    headers?: any,
    readData?: boolean
): ResultAsync<HttpExceptions, R> {
    try {
        const result = await axiosInstance.get(url, {
            params,
            headers,
            responseType: readData ? "arraybuffer" : "json",
        });

        axiosInstance.defaults.headers.common["Authorization"] =
            result.headers["authorization"];

        return R.ok(readData ? result.data : result.data.data);
    } catch (error) {
        return getError(error);
    }
}

export async function post<R = any>(
    url: string,
    config?: HttpServiceConfig
): ResultAsync<HttpExceptions, R> {
    try {
        axios.defaults.withCredentials = true;
        const result = await axiosInstance.post(url, config?.body, {
            headers: config?.headers,
        });

        axiosInstance.defaults.headers.common["Authorization"] =
            result.headers["authorization"];

        return R.ok(result.data.data);
    } catch (error) {
        return getError(error);
    }
}

export async function httpDelete<R = any>(
    url: string,
    config?: HttpServiceConfig
): ResultAsync<HttpExceptions, R> {
    try {
        axios.defaults.withCredentials = true;
        const result = await axiosInstance.delete(url, {
            headers: config?.headers,
        });

        axiosInstance.defaults.headers.common["Authorization"] =
            result.headers["authorization"];

        return R.ok(result.data.data);
    } catch (error) {
        return getError(error);
    }
}

export async function put<R = any>(
    url: string,
    config?: HttpServiceConfig
): ResultAsync<HttpExceptions, R> {
    try {
        axios.defaults.withCredentials = true;
        const result = await axiosInstance.put(url, config?.body, {
            headers: config?.headers,
        });

        axiosInstance.defaults.headers.common["Authorization"] =
            result.headers["authorization"];

        return R.ok(result.data.data);
    } catch (error) {
        return getError(error);
    }
}

export async function patch<R = any>(
    url: string,
    config?: HttpServiceConfig
): ResultAsync<HttpExceptions, R> {
    try {
        axios.defaults.withCredentials = true;
        const result = await axiosInstance.patch(url, config?.body, {
            headers: config?.headers,
        });

        axiosInstance.defaults.headers.common["Authorization"] =
            result.headers["authorization"];

        return R.ok(result.data.data);
    } catch (error) {
        return getError(error);
    }
}

function getError(error: any): Result<HttpExceptions, any> {
    if (error.code === "ECONNABORTED") {
        return R.failure(
            new HttpTimeoutException(
                "O serviço está demorando mais que o normal para responder. \n Tente novamente em instântes.",
                error.code,
                408
            )
        );
    } else if (error.response.status === 403) {
        return R.failure(
            new HttpForbiddenException(error.response.data.message)
        );
    } else if (error.response.status === 401) {
        return R.failure(
            new HttpAccessDeniedException(error.response.data.message)
        );
    } else {
        return R.failure(
            new HttpServiceException(
                error.response.data.message,
                error.response.data.error,
                error.response.data.statusCode
            )
        );
    }
}
