import { ABAS_TELA_VISITA } from "@contexts/Loja";
import { useMapaContext } from "../MapaContext";

export const navegarParaVisita = (
    aba: ABAS_TELA_VISITA = ABAS_TELA_VISITA.CHECKLIST
) => {
    useMapaContext.setState(
        (state) => ({
            ...state,
            drawer: { nome: "visita", aba },
        }),
        false,
        navegarParaVisita.name
    );
};
