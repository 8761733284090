import ContentLoader from "react-content-loader";

export interface LabelLoaderProps {
    style?: React.CSSProperties;
    width?: number;
}

export const LabelLoader = ({ style, width = 448 }: LabelLoaderProps) => {
    return (
        <ContentLoader
            speed={2}
            width={width}
            height={28}
            viewBox={`0 0 ${width} 28`}
            backgroundColor="#f2f2f2"
            foregroundColor="#ffffff"
            style={{ ...style }}
        >
            <rect x="0" y="0" rx="4" ry="4" width={width - 16} height="28" />
        </ContentLoader>
    );
};
