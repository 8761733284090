import { ApiException } from "@shared/exceptions/Api.exception";
import { get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./contants";

export type UsuarioAtualApi = {
    avatar: string;
    menus: string[];
    nome: string;
    tenantId: string;
};

export const getUsuarioAtual = async (): ResultAsync<
    ApiException,
    UsuarioAtualApi
> => {
    try {
        const usuarioAtual = await get<UsuarioAtualApi>(`${basePath}/me`);
        if (usuarioAtual.isFailure()) throw usuarioAtual.error;
        return R.ok({ ...usuarioAtual.value } as UsuarioAtualApi);
    } catch (error) {
        return R.failure(new ApiException(error as any));
    }
};
