import { EmailFastOutlineIcon } from "@shared/components/Icons/EmailFastOutline";
import { Alert } from "@shared/components/alerts/Alert";

export interface PasswordChangedAlertProps {
    visible: boolean;
    onDismiss: () => void;
}

export const PasswordChangedAlert = ({
    visible,
    onDismiss,
}: PasswordChangedAlertProps) => {
    return (
        <Alert
            visible={visible}
            onDismiss={onDismiss}
            onActionClick={onDismiss}
            message="Sua senha foi alterada com sucesso!"
            icon={<EmailFastOutlineIcon color="white" size={20} />}
            actionLabel="Fechar"
        />
    );
};
