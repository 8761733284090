import { View, ViewProps } from "react-native";

export interface BodyProps extends ViewProps {
    children?: React.ReactNode;
}

export const Body = ({ children, ...props }: BodyProps) => {
    return (
        <View className="flex flex-col grow space-y-2" {...props}>
            {children}
        </View>
    );
};
