import { View, ViewProps } from "react-native";

export interface ChecklistButtonContainerProps extends ViewProps {
    children: React.ReactNode;
}

export const ChecklistButtonContainer = ({
    children,
    ...props
}: ChecklistButtonContainerProps) => {
    return (
        <View className="flex flex-1 items-center space-y-1" {...props}>
            {children}
        </View>
    );
};
