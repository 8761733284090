import { BusinessException } from "../shared/exceptions/BusinessException";

export class InvalidEmailOrPaswordException extends BusinessException {
    InvalidEmailOrPaswordException = "key";

    getName(): string {
        return InvalidEmailOrPaswordException.name;
    }

    getInstance(): this {
        return this;
    }
}
