import React from "react";

import { CloudOfflineIcon } from "@shared/components/Icons/CloudOffline";
import { MessageModal } from "../../../../shared/components/modal/MessageModal";

export interface TimeoutModalProps {
    visible: boolean;
    onRequestClose: () => void;
}

export const TimeoutModal = ({
    visible,
    onRequestClose,
}: TimeoutModalProps) => {
    return (
        <MessageModal
            visible={visible}
            icon={<CloudOfflineIcon />}
            title={"Timeout"}
            titleColor={"#646464"}
            primaryButtonText={"Tentar novamente"}
            message={
                "Parece que o serviço está demorando mais tempo que o normal para responder. \n Tente novamente em instantes."
            }
            messageColor={"#646464"}
            onRequestClose={onRequestClose}
            onPrimaryButtonPressed={onRequestClose}
        />
    );
};
