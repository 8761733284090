import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Pressable, Text, TextInput, View, ViewProps } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "../../styles/GlobalStyles";
import { ChecklistButtonContainer } from "@pages/Visita/components/ChecklistButtonContainer";

export interface NumberChecklistButtonProps extends ViewProps {
    title: string;
    answer?: number;
    comment?: string;
    sufixo?: string;
    answerRange: {
        min: number;
        max: number;
    };
    onAnswer: (answer?: number, comment?: string) => void;
    onDetail: () => void;
}

export const NumberChecklistButton = ({
    answer,
    comment,
    sufixo,
    onAnswer,
    onDetail,
    answerRange,
    ...props
}: NumberChecklistButtonProps) => {
    const theme = useTheme<Theme>();
    const [answerState, setAnswerState] = React.useState<string | undefined>(
        answer?.toString(),
    );

    const [stateError, setStateError] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (
            answerState &&
            (Number(answerState) < answerRange.min ||
                Number(answerState) > answerRange.max)
        ) {
            setStateError(true);
        } else {
            setStateError(false);
            //onAnswer(answerState, commentState);
        }
    }, [answerState]);

    const helperText = `A resposta deve ser entre ${answerRange.min} e ${answerRange.max}`;

    return (
        <ChecklistButtonContainer {...props}>
            <View
                className={`flex flex-row items-start rounded-lg border divide-x  ${
                    stateError
                        ? `border-red-500 divide-red-500 `
                        : `border-zinc-300  divide-zinc-300`
                }`}
            >
                <View className="flex flex-col items-center rounded-l-md w-28 h-full">
                    <View className="flex flex-row items-center w-full pt-2">
                        <TextInput
                            inputMode="numeric"
                            underlineColorAndroid="transparent"
                            onChangeText={(value) => {
                                const regex = /^\d+(,\d{0,2})?$/;
                                if (value === "" || regex.test(value))
                                    setAnswerState(value);

                                //if (!isNaN(Number(value)))
                                //setAnswerState(value);
                            }}
                            value={
                                answerState?.toString() === "not_answered"
                                    ? ""
                                    : answerState?.toString().replace(".", ",")
                            }
                            className={`pl-2 text-lg text-zinc-500 w-full h-full focus:outline-none ${
                                stateError && `text-red-500`
                            }`}
                            selectionColor={"gray"}
                            onBlur={() => {
                                let valor = answerState?.toString();
                                if (valor?.slice(-1) === ",") {
                                    valor = valor.slice(0, -1);
                                    setAnswerState(valor);
                                }
                                onAnswer(Number(valor?.replace(",", ".")));
                            }}
                        />

                        <Text className="px-1 text-zinc-500">{sufixo}</Text>
                    </View>
                </View>

                <Pressable
                    onPress={() => {
                        onDetail();
                    }}
                >
                    <View className=" items-center rounded-r-md pl-4 pr-4 pt-2 pb-2">
                        {!comment || comment == "" ? (
                            <Ionicons
                                name="ellipsis-horizontal-circle-outline"
                                size={24}
                                color={
                                    !stateError
                                        ? theme.colors.icon.light
                                        : theme.colors.icon.error
                                }
                            />
                        ) : (
                            <Ionicons
                                name="alert-circle-outline"
                                size={24}
                                color={theme.colors.icon.alert}
                            />
                        )}
                    </View>
                </Pressable>
            </View>
            <View className="flex flex-row w-full space-x-1 h-3">
                {stateError && (
                    <Text className="text-xs text-red-500">{helperText}</Text>
                )}
            </View>
        </ChecklistButtonContainer>
    );
};
