import { createStackNavigator } from "@react-navigation/stack";
import { Text, View } from "react-native";
import { LoginStack } from "./LoginStack";
import { PaginaRespostaPlanoAcaoPublica } from "@pages/PlanoAcao/PaginaRespostaPlanoAcaoPublica";

const StackPublica = createStackNavigator();

export const StackPublicaComponent = () => {
    return (
        <StackPublica.Navigator screenOptions={{ headerShown: false }}>
            <StackPublica.Screen
                name={"resposta-plano-acao"}
                component={PaginaRespostaPlanoAcaoPublica}
            />
        </StackPublica.Navigator>
    );
};
