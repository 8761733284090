export type Colors = "primary" | "secondary" | "white" | "gray" | string;

export interface IconProps {
    size?: "xs" | "sm" | "md" | "lg";
    color?: Colors;
    onPress?: () => void;
    className?: string;
}

export const SizeIcons = {
    xs: 14,
    sm: 20,
    md: 28,
    lg: 36,
};
