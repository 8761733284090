export type Localizacao = {
    latitude: number;
    longitude: number;
};

export function criarLocalizacao(localizacao: Localizacao) {
    return {
        ...localizacao,
    };
}
