import { DrawerContentScrollView } from "@react-navigation/drawer";
import { View } from "react-native-animatable";

export const CustomDrawerContainer = (props) => {
    return (
        <View className="flex flex-1">
            <DrawerContentScrollView
                {...props}
                contentContainerStyle={{ flex: 1, overflow: "hidden" }}
            >
                {props.children}
            </DrawerContentScrollView>
        </View>
    );
};
