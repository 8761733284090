import * as ErroContext from "@contexts/Erro";
import { useLoginStore } from "@contexts/Login";
import * as MapaContext from "@contexts/Mapa";
import { Ionicons } from "@expo/vector-icons";
import * as LoginService from "@services/Autenticacao";
import React from "react";
import { Dimensions, Pressable, Text, View, ViewProps } from "react-native";
import { Divider, Menu as MenuRNP } from "react-native-paper";

interface ItemMenuProps {
    onPress: () => void;
    children: React.ReactNode;
}

const ItemMenu = ({ onPress, children: children }: ItemMenuProps) => {
    return (
        <Pressable
            className="items-center justify-center h-20 space-y-1 hover:bg-zinc-100 hover:bg-opacity-10 rounded p-2"
            onPress={onPress}
        >
            {children}
        </Pressable>
    );
};

export interface MenuProps extends ViewProps {
    tipo: "mobile" | "desktop";
}

export const Menu = ({ tipo, ...props }: MenuProps) => {
    const { width } = Dimensions.get("window");
    const isMobile = width < 768;

    const onSairClick = async () => {
        const logout = await LoginService.logout();
        if (logout?.isFailure()) {
            ErroContext.addErro(logout.error);
            return;
        }
    };

    const navegarParaMapa = MapaContext.useMapaContext(
        (state) => state.navegarParaMapa
    );

    const onMapaClick = async () => {};

    const build = () => {
        if (!isMobile && tipo === "desktop") {
            return (
                <MenuDesktop
                    onSairClick={onSairClick}
                    onMapaClick={onMapaClick}
                    onIndicadoresClick={() => {}}
                />
            );
        } else if (isMobile && tipo === "mobile") {
            return (
                <MenuMobile
                    onSairClick={onSairClick}
                    onMapaClick={onMapaClick}
                    onIndicadoresClick={() => {}}
                />
            );
        }
    };

    return build();
};

interface BuildItemMenu {
    menu: string;
    callbacks: MenusProps;
}

type BuildItemMenuResult = {
    callback: () => void;
    icone: string;
    titulo: string;
    notfound: boolean;
};

const buildItemMenu = ({ menu, callbacks }: BuildItemMenu) => {
    let callback = () => {};
    let icone = "";
    let titulo = "";
    let notfound = false;

    switch (menu) {
        case "mapa":
            callback = callbacks.onMapaClick;
            icone = "map-outline";
            titulo = "Mapa";
            break;
        case "indicadores":
            callback = callbacks.onIndicadoresClick;
            icone = "bar-chart-outline";
            titulo = "Indicadores";
            break;
        default:
            notfound = true;
            break;
    }

    return {
        callback,
        icone,
        titulo,
        notfound,
    };
};

interface MenusProps {
    onSairClick: () => void;
    onMapaClick: () => void;
    onIndicadoresClick: () => void;
}

const MenuMobile = ({ onSairClick, onMapaClick }: MenusProps) => {
    const [visible, setVisible] = React.useState(false);
    const openMenu = () => setVisible(true);
    const closeMenu = () => setVisible(false);

    const onMapaClickHandler = () => {
        setVisible(false);
        onMapaClick();
    };

    return (
        <MenuRNP
            visible={visible}
            onDismiss={closeMenu}
            anchor={
                <Ionicons
                    name="menu"
                    size={24}
                    onPress={openMenu}
                    color={"#00AF14"}
                />
            }
            contentStyle={{
                backgroundColor: "white",
            }}
        >
            <MenuRNP.Item
                onPress={onMapaClickHandler}
                title="Mapa"
                leadingIcon={() => <Ionicons name="map-outline" size={24} />}
            />
            <Divider />
            <MenuRNP.Item
                onPress={onSairClick}
                title="Sair"
                leadingIcon={() => <Ionicons name="exit-outline" size={24} />}
            />
        </MenuRNP>
    );
};

const MenuDesktop = ({
    onSairClick,
    onMapaClick,
    onIndicadoresClick,
}: MenusProps) => {
    const menus = useLoginStore((state) => state.menus);

    return (
        <View className="flex flex-col z-50 bg-[#00AF14] bg-opacity-80 w-24">
            {menus?.map((menu) => {
                const itemMenu = buildItemMenu({
                    menu,
                    callbacks: { onSairClick, onMapaClick, onIndicadoresClick },
                });

                return !itemMenu.notfound ? (
                    <ItemMenu onPress={itemMenu.callback}>
                        <Ionicons
                            name={itemMenu.icone as any}
                            size={22}
                            color={"white"}
                        />
                        <Text style={{ color: "white" }}>
                            {itemMenu.titulo}
                        </Text>
                    </ItemMenu>
                ) : (
                    <></>
                );
            })}

            {/**
            <ItemMenu onPress={onMapa}>
                <Ionicons name="map-outline" size={22} color={"white"} />
                <Text style={{ color: "white" }}>Mapa</Text>
            </ItemMenu>

            <ItemMenu onPress={onMapa}>
                <Ionicons name="bar-chart-outline" size={22} color={"white"} />
                <Text style={{ color: "white" }}>Indicadores</Text>
            </ItemMenu>
             */}

            <View className="grow"></View>

            <Pressable
                className="flex flex-row space-x-2 justify-center items-center hover:bg-zinc-100 hover:bg-opacity-10 py-2"
                onPress={onSairClick}
            >
                <Ionicons name="exit-outline" size={22} color={"white"} />
                <Text style={{ color: "white" }}>Sair</Text>
            </Pressable>
        </View>
    );
};
