import { MessageModal } from "@shared/components/modal/MessageModal";
import { Component } from "react";

export class ErrorBoundary extends Component<
    { children: React.ReactNode },
    { hasError: boolean; error?: Error }
> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // Esse método é chamado quando um erro é lançado em qualquer componente filho
    static getDerivedStateFromError(error) {
        // Atualiza o estado para que o próximo render mostre a UI alternativa
        return { hasError: true, error };
    }

    // Esse método é usado para registrar o erro
    componentDidCatch(error, errorInfo) {
        // Você pode também logar o erro para um serviço de monitoramento de erros
        console.error("Error caught by Error Boundary:", error, errorInfo);
    }

    render() {
        /*
        if (this.state.hasError) {
            // Você pode renderizar qualquer UI alternativa
            return (
                <MessageModal
                    visible={!!this.state.hasError}
                    onRequestClose={() => this.setState({ hasError: false })}
                    message={this.state.error?.message}
                    title="Ops!"
                    primaryButtonText="Ok"
                    onPrimaryButtonPressed={() =>
                        this.setState({ hasError: false })
                    }
                />
            );
        }
        */

        return (
            <>
                <MessageModal
                    visible={!!this.state.hasError}
                    onRequestClose={() => this.setState({ hasError: false })}
                    message={this.state.error?.message}
                    title="Ops!"
                    primaryButtonText="Ok"
                    onPrimaryButtonPressed={() =>
                        this.setState({ hasError: false })
                    }
                />
                {this.props.children}
            </>
        );
    }
}
