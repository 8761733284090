import { BottomPage } from "@shared/components/bottomPage/BottomPage";
import { Botao } from "@shared/components/button/Button";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { useState } from "react";
import { Text, View } from "react-native";
import { TextInput } from "react-native-paper";

export interface CadastroObservacaoProps {
    observacaoAtual?: string;
    onClose: () => void;
    onSave?: (observacao?: string) => void;
    modo?: "edicao" | "visualizacao";
}

export const CadastroObservacao = ({
    observacaoAtual,
    onClose,
    onSave,
    modo = "edicao",
}: CadastroObservacaoProps) => {
    const [observacao, setObservacao] = useState<string | undefined>(
        () => observacaoAtual
    );

    const handleOnSave = () => {
        onSave && onSave(observacao);
    };

    return (
        <BottomPage title={"Cadastro de observação"} onClose={onClose}>
            <Container className="pt-52">
                <Body className="pt-4 overflow-y-auto mb-2">
                    <View className="flex flex-col space-y-1">
                        <Text className="font-bold text-[#270040]">
                            Observação
                        </Text>
                        <TextInput
                            mode="outlined"
                            value={observacao}
                            multiline
                            numberOfLines={10}
                            onChangeText={(nome) => {
                                modo == "edicao" && setObservacao(nome);
                            }}
                        />
                    </View>
                </Body>

                {modo == "edicao" && (
                    <Botao onPress={() => handleOnSave()} className="w-full ">
                        Salvar
                    </Botao>
                )}
            </Container>
        </BottomPage>
    );
};
