import { Checklist, ChecklistConfig } from "@models/Checklist";
import { Loja } from "@models/Loja/Loja";
import * as PlanoAcao from "@models/PlanoAcao";
import * as Visita from "@models/Visita";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { isDevtoolsHabilitado } from "..";

export enum ABAS_TELA_LOJA {
    GERAL = "geral",
    VISITAS = "visitas",
    PLANOS_DE_ACAO = "planosAcao",
}

export enum ABAS_TELA_VISITA {
    CHECKLIST = "checklist",
    PLANO_ACAO = "planoAcao",
}

export type Telas =
    | { nome: "loja"; aba: ABAS_TELA_LOJA }
    | { nome: "visita"; aba: ABAS_TELA_VISITA }
    | { nome: "planoAcao" };

export type LojaStoreState = {
    tela?: Telas;
    lojaSelecionada?: Loja;
    visitas: Visita.Visita[];
    checklistsConfig: ChecklistConfig[];
    visitaSelecionada?: Visita.Visita;
    checklists: Checklist[];
    planosAcao: PlanoAcao.PlanoAcao[];
};

export type LojaStoreActions = {
    reiniciarStore: () => void;
    iniciarVisita: (
        visita: Visita.Visita,
        checklists: Checklist[],
        planosAcao: PlanoAcao.PlanoAcao[],
    ) => void;
    continuarVisita: (
        visita: Visita.Visita,
        checklists: Checklist[],
        planosAcao: PlanoAcao.PlanoAcao[],
    ) => void;
    carregarVisitas: (
        visitas: Visita.Visita[],
        checklistsConfig: ChecklistConfig[],
        navegarPara?: Telas,
    ) => void;
    atualizarVisita: (
        visita: Visita.Visita,
        checklists: Checklist[],
        planosAcao: PlanoAcao.PlanoAcao[],
        navegarPara?: Telas,
    ) => void;
    atualizarChecklist: (checklist: Checklist) => void;
    atualizarPlanoAcao: (planoAcao: PlanoAcao.PlanoAcao) => void;
};

export type LojaStore = LojaStoreState & LojaStoreActions;

export const useLojaStore = create<LojaStore>()(
    devtools(
        (set) => ({
            tela: undefined,
            lojaSelecionada: undefined,
            visitas: [],
            visitaSelecionada: undefined,
            checklists: [],
            planosAcao: [],
            checklistsConfig: [],
            reiniciarStore() {
                set(
                    (state) => ({
                        tela: undefined,
                        lojaSelecionada: undefined,
                        visitas: [],
                        visitaSelecionada: undefined,
                        checklists: [],
                        planosAcao: [],
                        checklistsConfig: [],
                    }),
                    false,
                    "reiniciarStore",
                );
            },
            iniciarVisita(visita, checklists, planosAcao) {
                set(
                    (state) => ({
                        ...state,
                        visitas: [...state.visitas, visita],
                        visitaSelecionada: visita,
                        checklists,
                        planosAcao,
                    }),
                    false,
                    "iniciarVisita",
                );
            },
            continuarVisita(visita, checklists, planosAcao) {
                set(
                    (state) => ({
                        ...state,
                        visitaSelecionada: visita,
                        checklists: checklists,
                        planosAcao: planosAcao,
                    }),
                    false,
                    "continuarVisita",
                );
            },
            atualizarVisita(visita, checklists, planosAcao, navegarPara) {
                const updatePadrao = {
                    visitaSelecionada: visita,
                    planosAcao: planosAcao,
                    checklists: checklists,
                };

                const update = navegarPara
                    ? { ...updatePadrao, tela: navegarPara }
                    : updatePadrao;

                set(
                    (state) => ({
                        ...state,
                        ...update,
                    }),
                    false,
                    "atualizarVisita",
                );
            },
            carregarVisitas(visitas, checklistsConfig, navegarPara) {
                const update = navegarPara
                    ? {
                          visitas: visitas,
                          checklistsConfig,
                          tela: navegarPara,
                      }
                    : {
                          visitas: visitas,
                          checklistsConfig,
                      };

                set(
                    (state) => ({
                        ...state,
                        ...update,
                    }),
                    false,
                    "carregarVisitas",
                );
            },
            atualizarChecklist(checklist) {
                set(
                    (state) => ({
                        ...state,
                        checklists: state.checklists.map((c) =>
                            c.id === checklist.id ? checklist : c,
                        ),
                    }),
                    false,
                    "atualizarChecklist",
                );
            },
            atualizarPlanoAcao(planoAcao) {
                set(
                    (state) => ({
                        ...state,
                        planosAcao: state.planosAcao.map((p) =>
                            p.id === planoAcao.id ? planoAcao : p,
                        ),
                    }),
                    false,
                    "atualizarPlanoAcao",
                );
            },
        }),

        { name: "LojaStore", enabled: isDevtoolsHabilitado() },
    ),
);
