import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";

export interface EmailFastOutlineIconProps {
    size?: number;
    color?: string;
}

export const EmailFastOutlineIcon: React.FC<EmailFastOutlineIconProps> = ({
    size = 120,
    color = "#f87171",
}) => {
    return (
        <MaterialCommunityIcons
            name="email-fast-outline"
            size={size}
            color={color}
        />
    );
};
