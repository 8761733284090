import { ComentarioItemChecklist } from "@components/Checklist/ComentarioItemChecklist";
import { TiposItensChecklist } from "@models/Checklist/ItemChecklist";
import { BottomPage } from "@shared/components/bottomPage/BottomPage";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { useState } from "react";
import { View } from "react-native";
import { Button } from "react-native-paper";

export interface ModalDetalheRespostaItemChecklistProps {
    item: TiposItensChecklist;
    onClose: () => void;
    onSave: (comentario: string) => void;
}

export const ModalDetalheRespostaItemChecklist = ({
    item,
    onClose,
    onSave,
}: ModalDetalheRespostaItemChecklistProps) => {
    const [comentario, setComentario] = useState<string | undefined>(
        item?.answer.comment
    );

    return (
        <BottomPage title={"Detalhes do Checklist"} onClose={onClose}>
            <Container className="pt-2">
                <Body>
                    <ComentarioItemChecklist
                        value={comentario}
                        onChange={setComentario}
                    />
                </Body>

                <View className="flex flex-row justify-around space-x-2">
                    <View className="grow">
                        <Button mode="outlined" onPress={onClose}>
                            Cancelar
                        </Button>
                    </View>
                    <View className="grow">
                        <Button
                            mode="contained"
                            onPress={() => onSave(comentario || "")}
                        >
                            Salvar
                        </Button>
                    </View>
                </View>
            </Container>
        </BottomPage>
    );
};
