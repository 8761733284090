import * as ChecklistApi from "@api/Checklist";
import * as PlanoAcaoApi from "@api/PlanoAcao";
import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import * as Visita from "@models/Visita";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { Telas, useLojaStore } from "../../LojaStore";

export interface AtualizarVisitaProps {
    visita: Visita.Visita;
    actionName: string;
    navegarPara?: Telas;
}

/**
 * Recebe uma visita e atualiza os dados dependentes dela na store
 */
export const atualizarVisita = async (
    props: AtualizarVisitaProps
): ResultAsync<
    | ChecklistApi.GetChecklistExceptions
    | HttpExceptions
    | ApiException
    | AtividadePlanoAcaoInvalida,
    void
> => {
    const checklistPromise = await ChecklistApi.getChecklist(props.visita.id);
    if (checklistPromise.isFailure()) return R.failure(checklistPromise.error);

    const planoAcao = await PlanoAcaoApi.getPlanoAcao(props.visita.id);
    if (planoAcao.isFailure()) return R.failure(planoAcao.error);

    useLojaStore.setState(
        (state) => {
            const updatePadrao = {
                visitaSelecionada: props.visita,
                planoAcao: planoAcao.value,
                checklist: checklistPromise.value[0],
            };

            const update = props.navegarPara
                ? { ...updatePadrao, tela: props.navegarPara }
                : updatePadrao;

            return {
                ...state,
                ...update,
            };
        },

        false,
        `${props.actionName} => atualizarVisita`
    );

    return R.ok();
};
