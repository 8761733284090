export enum StatusAtividadePlanoAcao {
    PENDENTE_CONFIGURACAO = "PENDENTE CONFIGURAÇÃO",
    CONFIGURADA = "CONFIGURADA",
    CONCLUIDA = "CONCLUIDA",
    EM_ANDAMENTO = "EM ANDAMENTO",
}

export const getStatusAtividadePlanoAcaoFromString = (
    status: string
): StatusAtividadePlanoAcao => {
    switch (status) {
        case "PENDENTE CONFIGURAÇÃO":
            return StatusAtividadePlanoAcao.PENDENTE_CONFIGURACAO;
        case "CONFIGURADA":
            return StatusAtividadePlanoAcao.CONFIGURADA;
        case "CONCLUIDA":
            return StatusAtividadePlanoAcao.CONCLUIDA;
        case "EM ANDAMENTO":
            return StatusAtividadePlanoAcao.EM_ANDAMENTO;
        default:
            return StatusAtividadePlanoAcao.PENDENTE_CONFIGURACAO;
    }
};
