import { Tag } from "@shared/components/tag/Tag";
import { StatusPlanoAcao } from "@models/PlanoAcao/StatusPlanoAcao";

export interface StatusPlanoAcaoComponentProps {
    status: StatusPlanoAcao;
}

export const StatusPlanoAcaoComponent = ({
    status,
}: StatusPlanoAcaoComponentProps) => {
    return (
        <>
            {status === "Pendente Configuracao" && <Tag label={`Pendente`} />}
            {status === "Configurado" && (
                <Tag label={`Configurado`} color="green" />
            )}
        </>
    );
};
