import * as Visita from "@models/Visita";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, patch } from "@shared/http/Http.service";
import { ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type ConcluirVisitaProps = {
    visita: Visita.Visita;
};

export type ConcluirVisitaResult = ResultAsync<
    HttpExceptions | ApiException,
    void
>;

export async function concluirVisita({
    visita,
}: ConcluirVisitaProps): ConcluirVisitaResult {
    return await patch(`${basePath}/${visita.id}`, {
        body: visita.localizacao,
    });
}
