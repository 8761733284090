import { useErroStore } from "@shared/components/Error/stores/UseErrorStore";
import { Exception } from "../../../exceptions/Exception";

export interface UseErro {
    erro?: Exception;
    setErro: (error?: Exception) => void;
}

export function useErro(): UseErro {
    const { erro, setErro } = useErroStore();

    return {
        erro,
        setErro,
    };
}
