import { ABAS_TELA_VISITA, useLojaStore } from "@contexts/Loja/LojaStore";

export const alterarTabTelaVisita = async (novaTab: ABAS_TELA_VISITA) => {
    useLojaStore.setState(
        (state) => ({
            ...state,
            tela: { nome: "visita", aba: novaTab },
        }),
        false,
        "alterarTabTelaLoja"
    );
};
