export type ParametroPlanoAcao = {
    id: string;
    descricao: string;
    responsaveis: string[];
    prazos: number[];
    itemChecklistGatilho: string[];
};

export function criarParametroPlanoAcao(
    props: ParametroPlanoAcao
): ParametroPlanoAcao {
    return {
        id: props.id,
        descricao: props.descricao,
        responsaveis: props.responsaveis,
        prazos: props.prazos,
        itemChecklistGatilho: props.itemChecklistGatilho,
    };
}
