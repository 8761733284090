import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Pressable, Text, TextInput, View, ViewProps } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "../../styles/GlobalStyles";
import { OpcaoItemChecklistSelect } from "@models/Checklist";
import { Select } from "@shared/components/Select/Select";
import { ChecklistButtonContainer } from "@pages/Visita/components/ChecklistButtonContainer";

export interface SelectChecklistButtonProps extends ViewProps {
    title: string;
    answer?: string;
    comment?: string;
    onAnswer: (answer?: string | "not_answered", comment?: string) => void;
    onDetail: () => void;
    opcoes: OpcaoItemChecklistSelect[];
}

export const SelectChecklistButton = ({
    title,
    answer,
    comment,
    onAnswer,
    onDetail,
    opcoes,
    ...props
}: SelectChecklistButtonProps) => {
    const theme = useTheme<Theme>();

    return (
        <ChecklistButtonContainer {...props}>
            <View
                className={`flex flex-row items-start rounded-lg border divide-x border-zinc-300 divide-zinc-300`}
            >
                <View className="flex flex-col items-center rounded-l-md w-28 h-full justify-center bg-transparent">
                    <View className="flex flex-row w-full pl-1 bg-transparent">
                        <Select
                            options={opcoes.map((o) => {
                                return { valor: o, rotulo: o };
                            })}
                            onSelect={(valor?: string, index?: number) => {
                                onAnswer(index === -1 ? "not_answered" : valor);
                            }}
                            pickerStyle="border-none outline-transparent py-0 bg-transparent"
                            value={
                                answer == "not_answered" ? undefined : answer
                            }
                        />
                    </View>
                </View>

                <Pressable
                    onPress={() => {
                        onDetail();
                    }}
                >
                    <View className=" items-center rounded-r-md pl-4 pr-4 pt-2 pb-2">
                        {!comment || comment == "" ? (
                            <Ionicons
                                name="ellipsis-horizontal-circle-outline"
                                size={24}
                                color={theme.colors.icon.light}
                            />
                        ) : (
                            <Ionicons
                                name="alert-circle-outline"
                                size={24}
                                color={theme.colors.icon.alert}
                            />
                        )}
                    </View>
                </Pressable>
            </View>
        </ChecklistButtonContainer>
    );
};
