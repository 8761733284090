import { TiposItensChecklist } from "./ItemChecklist";

export type Checklist = {
    id: string;
    itens: TiposItensChecklist[];
    idVisita: string;
    titulo: string;
};

export function criarChecklist(props: Checklist): Checklist {
    props.itens.sort((a, b) => {
        if (!a.ordem || !b.ordem) return 1;

        return a.ordem - b.ordem;
    });

    return {
        id: props.id,
        itens: props.itens,
        idVisita: props.idVisita,
        titulo: props.titulo,
    };
}

export function isItemRespondido(item: TiposItensChecklist) {
    return (
        item.answer !== undefined &&
        item.answer !== null &&
        item.answer.answer !== "not_answered"
    );
}

export function getItensChecklistRespondidos(checklist: Checklist) {
    return checklist.itens.filter((item) => isItemRespondido(item));
}

export const responderItemChecklist = (
    checklist: Checklist,
    itemChecklistId: string,
    resposta: boolean | number | string,
): Checklist => {
    const itens = checklist.itens.map((item) => {
        if (item.id === itemChecklistId) {
            item.answer.answer = resposta;
        }
        return item;
    });

    return {
        ...checklist,
        itens,
    };
};

export const addComentarioItemChecklist = (
    checklist: Checklist,
    itemChecklistId: string,
    comentario: string,
): Checklist => {
    const itens = checklist.itens.map((item) => {
        if (item.id === itemChecklistId) {
            item.answer.comment = comentario;
        }
        return item;
    });

    return {
        ...checklist,
        itens,
    };
};
