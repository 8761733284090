import * as ChecklistConfig from "@models/Checklist/ChecklistConfig";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type GetChecklistConfigExceptions = HttpExceptions | ApiException;
export type GetChecklistConfigResult = ResultAsync<
    GetChecklistConfigExceptions,
    ChecklistConfig.ChecklistConfig[]
>;

export async function getChecklistConfig(): GetChecklistConfigResult {
    const checklistConfigApi = await get<ChecklistConfig.ChecklistConfig[]>(
        `${basePath}/config`
    );
    if (checklistConfigApi.isFailure())
        return R.failure(checklistConfigApi.error);

    const checklistConfig: ChecklistConfig.ChecklistConfig[] =
        checklistConfigApi.value.map((item) => {
            const i = ChecklistConfig.criarChecklistConfig({
                ...item,
            });

            return i;
        });

    return R.ok(checklistConfig);
}
