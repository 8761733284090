import classNames from "classnames";
import React from "react";
import { Text, TextProps } from "react-native";
import { twMerge } from "tailwind-merge";

export type DefaultTextColors = "default" | "primary" | "error";
export interface DefaultTextProps extends TextProps {
    label: string | React.ReactElement;
    bold?: boolean;
    light?: boolean;
    color?: DefaultTextColors;
}

export const DefaultText: React.FC<DefaultTextProps> = (
    props: DefaultTextProps
) => {
    const className = twMerge(
        `text-zinc-500`,
        props.className,
        classNames({
            "font-bold": props.bold,
            "font-light": props.light,
        })
    );

    return (
        <Text className={className} ellipsizeMode="tail" {...props}>
            {props.label}
        </Text>
    );
};
