import { CardLoader } from "@components/Loaders/CardLoader";
import * as ErroContext from "@contexts/Erro";
import * as LojaStore from "@contexts/Loja";
import { ChecklistNaoCarregadoException } from "@exceptions/Checklist/ChecklistNaoCarregado.exception";
import { PlanoAcaoNaoCarregadoException } from "@exceptions/PlanoAcao/PlanoAcaoNaoCarregado.exception";
import { VisitaNaoCarregadaException } from "@exceptions/Visita/VisitaNaoCarregada.exception";
import {
    PROP_NAMES,
    SelecaoResponsavelPlanoAcao,
} from "@features/PlanoAcao/components";
import { CadastroObservacao } from "@features/PlanoAcao/components/CadastroObservacao";
import { ResponsavelPlanoAcao } from "@features/PlanoAcao/models";
import { useVisita } from "@hooks/useVisita";
import { PlanoAcao } from "@models/PlanoAcao";
import { ListaChecklists } from "@pages/Visita/components/ListaChecklists";
import { Botao, BotaoDelineado } from "@shared/components/button/Button";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { Footer } from "@shared/components/page/Footer";
import { useEffect, useMemo, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import {
    AtividadePlanoAcaoType,
    ListaAtividadesPlanoAcao,
} from "./ListaAtividadesPlanoAcao";

export interface PlanoAcaoComponentProps {
    idVisita: string;
}

type ShowSelecaoResponsavel = {
    tipo: "responsavel";
    cargos: string[];
    responsavelAtual?: ResponsavelPlanoAcao;
};

type ShowInclusaoObservacao = {
    tipo: "observacao";
    observacaoAtual?: string;
};

type ShowSelecao = { idAtividade: string; planoAcao: PlanoAcao } & (
    | ShowSelecaoResponsavel
    | ShowInclusaoObservacao
);

export interface ConfiguracaoPlanoAcaoProps {
    onConcluirVisita: () => void;
    loadingBotaoConcluir?: boolean;
}

export const AbaConfiguracaoPlanoAcao = ({
    onConcluirVisita,
    loadingBotaoConcluir,
}) => {
    const [loading, setLoading] = useState<"pagina" | "salvar" | undefined>(
        undefined,
    );
    const planosAcao = LojaStore.useLojaStore((store) => store.planosAcao);
    const checklists = LojaStore.useLojaStore((store) => store.checklists);
    const visita = LojaStore.useLojaStore((store) => store.visitaSelecionada);
    const [showSelecao, setShowSelecao] = useState<ShowSelecao>();

    const {
        salvarChecklistsVisita,
        adicionarPrazoAtividadePlanoAcaoVisita,
        adicionarObservacaoAtividadePlanoAcaoVisita,
        adicionarResponsavelAtividadePlanoAcaoVisita,
        configurarPlanosAcaoVisita,
    } = useVisita();

    useEffect(() => {
        setLoading("pagina");
        carregar().finally(() => setLoading(undefined));
    }, []);

    const carregar = async () => {
        if (!checklists) {
            ErroContext.addErro(new ChecklistNaoCarregadoException());
            return;
        }
        if (!visita) {
            ErroContext.addErro(new VisitaNaoCarregadaException());
            return;
        }

        const salvarChecklsit = await salvarChecklistsVisita(
            visita,
            checklists,
        );
        if (salvarChecklsit.isFailure()) {
            ErroContext.addErro(salvarChecklsit.error);
            return;
        }
    };

    /**
     * Persiste o plano de ação configurado no backend
     */
    const salvarPlanoAcaoHandler = async () => {
        setLoading("salvar");
        const configurarPlanoAcao =
            await configurarPlanosAcaoVisita(planosAcao);
        if (configurarPlanoAcao.isFailure())
            ErroContext.addErro(configurarPlanoAcao.error);
        setLoading(undefined);
    };

    const onClickProps = (
        propName: PROP_NAMES,
        id: string,
        planoAcao: PlanoAcao,
    ) => {
        switch (propName) {
            case PROP_NAMES.RESPONSAVEL:
                setShowSelecao({
                    tipo: "responsavel",
                    idAtividade: id,
                    cargos:
                        planoAcao.parametros.find((p) => p.id === id)
                            ?.responsaveis || [],
                    responsavelAtual: planoAcao.atividades.find(
                        (a) => a.id === id,
                    )?.responsavel,
                    planoAcao,
                });
                break;
            case PROP_NAMES.OBSERVACAO:
                setShowSelecao({
                    tipo: "observacao",
                    idAtividade: id,
                    observacaoAtual: planoAcao.atividades.find(
                        (a) => a.id === id,
                    )?.observacao,
                    planoAcao,
                });
        }
    };

    const adicionarResponsavelAtividadePlanoAcaoHandler = ({
        responsavel,
        idAtividade,
        planoAcao,
    }: {
        responsavel: ResponsavelPlanoAcao;
        idAtividade: string;
        planoAcao: PlanoAcao;
    }) => {
        if (!planosAcao) throw new PlanoAcaoNaoCarregadoException();

        adicionarResponsavelAtividadePlanoAcaoVisita({
            idAtividade,
            responsavel,
            planoAcao,
        });

        setShowSelecao(undefined);
    };

    const adicionarObservacaoAtividadePlanoAcaoHandler = ({
        observacao,
        idAtividade,
        planoAcao,
    }: {
        observacao?: string;
        idAtividade: string;
        planoAcao: PlanoAcao;
    }) => {
        if (!planosAcao) throw new PlanoAcaoNaoCarregadoException();

        adicionarObservacaoAtividadePlanoAcaoVisita({
            idAtividade,
            observacao,
            planoAcao,
        });

        setShowSelecao(undefined);
    };

    const adicionarPrazoAtividadeHandler = ({
        prazo,
        idAtividade,
        planoAcao,
    }: {
        prazo: number;
        idAtividade: string;
        planoAcao: PlanoAcao;
    }) => {
        if (!planosAcao) throw new PlanoAcaoNaoCarregadoException();

        adicionarPrazoAtividadePlanoAcaoVisita({
            idAtividade,
            prazo,
            planoAcao,
        });

        setShowSelecao(undefined);
    };

    const atividades = useMemo(() => {
        const result: AtividadePlanoAcaoType[] = [];
        for (const planoAcao of planosAcao) {
            const atv = planoAcao.atividadesNecessarias.map(
                (parametro): AtividadePlanoAcaoType => {
                    return {
                        id: parametro.id,
                        descricao: parametro.descricao,
                        prazo: planoAcao.atividades.find(
                            (a) => a.id === parametro.id,
                        )?.prazo,
                        status: planoAcao.atividades.find(
                            (a) => a.id === parametro.id,
                        )!.status,
                        responsavel: planoAcao.atividades.find(
                            (a) => a.id === parametro.id,
                        )!.responsavel,
                        opcoesPrazo: parametro.prazos,
                        observacao: planoAcao.atividades.find(
                            (a) => a.id === parametro.id,
                        )?.observacao,
                        onClick: (prop: PROP_NAMES) => {
                            onClickProps(prop, parametro.id, planoAcao);
                        },
                        itemChecklistGatilho: planoAcao.atividades.find(
                            (a) => a.id === parametro.id,
                        )!.itemChecklistGatilho,
                        planoAcao,
                    };
                },
            );

            result.push(...atv);
        }

        return result;
    }, [planosAcao]);

    const sugestaoResponsavelList = useMemo(() => {
        const result: ResponsavelPlanoAcao[] = [];
        for (const planoAcao of planosAcao) {
            planoAcao.atividades.forEach((a) => {
                if (
                    a.responsavel?.email &&
                    result.find((r) => {
                        return (
                            r.email == a.responsavel?.email &&
                            r.cargo == a.responsavel?.cargo
                        );
                    }) === undefined
                ) {
                    result.push(a.responsavel);
                }
            });
        }
        return result;
    }, [planosAcao]);

    const checklistsComPlanoAcao = checklists.filter((c) => {
        return planosAcao.some(
            (p) => p.idChecklist === c.id && p.atividades.length > 0,
        );
    });

    return (
        <Container>
            <ScrollView>
                <Body className="p-2">
                    {loading == "pagina" ? (
                        <View className="flex flex-col p-2 space-y-2">
                            <CardLoader />
                            <CardLoader />
                            <CardLoader />
                        </View>
                    ) : (
                        <View>
                            {checklistsComPlanoAcao.length === 0 && (
                                <View className="flex items-center justify-center p-2 ">
                                    <Text>
                                        Esta visita não necessidade de nenhum
                                        plano de ação!
                                    </Text>
                                </View>
                            )}

                            {checklistsComPlanoAcao.length === 1 && (
                                <ListaAtividadesPlanoAcao
                                    atividades={atividades.filter((a) =>
                                        checklistsComPlanoAcao[0].itens.some(
                                            (c) =>
                                                c.id ===
                                                a.itemChecklistGatilho[0],
                                        ),
                                    )}
                                    modo={"configuracao"}
                                    onChangeValue={(
                                        idAtividade,
                                        propName,
                                        planoAcao,
                                        value,
                                    ) => {
                                        if (
                                            propName === PROP_NAMES.PRAZO &&
                                            planoAcao
                                        ) {
                                            adicionarPrazoAtividadeHandler({
                                                prazo: value as number,
                                                idAtividade,
                                                planoAcao,
                                            });
                                        }
                                    }}
                                    checklist={checklistsComPlanoAcao[0]}
                                />
                            )}

                            {checklistsComPlanoAcao.length > 1 && (
                                <ListaChecklists
                                    checklists={checklistsComPlanoAcao}
                                    mostrarBotaoExcluir={false}
                                    getChildren={(checklist) => {
                                        return (
                                            <ListaAtividadesPlanoAcao
                                                atividades={atividades.filter(
                                                    (a) =>
                                                        checklist.itens.some(
                                                            (c) =>
                                                                c.id ===
                                                                a
                                                                    .itemChecklistGatilho[0],
                                                        ),
                                                )}
                                                modo={"configuracao"}
                                                onChangeValue={(
                                                    idAtividade,
                                                    propName,
                                                    planoAcao,
                                                    value,
                                                ) => {
                                                    if (
                                                        propName ===
                                                            PROP_NAMES.PRAZO &&
                                                        planoAcao
                                                    ) {
                                                        adicionarPrazoAtividadeHandler(
                                                            {
                                                                prazo: value as number,
                                                                idAtividade,
                                                                planoAcao,
                                                            },
                                                        );
                                                    }
                                                }}
                                                checklist={checklist}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </View>
                    )}
                </Body>
            </ScrollView>

            {showSelecao?.tipo === "responsavel" && (
                <SelecaoResponsavelPlanoAcao
                    cargos={showSelecao.cargos}
                    onClose={() => {
                        setShowSelecao(undefined);
                    }}
                    sugestaoResponsavelList={sugestaoResponsavelList}
                    onResponsavelSelecionado={(responsavel) => {
                        adicionarResponsavelAtividadePlanoAcaoHandler({
                            responsavel,
                            idAtividade: showSelecao.idAtividade,
                            planoAcao: showSelecao.planoAcao,
                        });
                    }}
                    responsavelAtual={showSelecao.responsavelAtual}
                />
            )}

            {showSelecao?.tipo === "observacao" && (
                <CadastroObservacao
                    observacaoAtual={showSelecao.observacaoAtual}
                    onClose={() => {
                        setShowSelecao(undefined);
                    }}
                    onSave={(observacao) => {
                        adicionarObservacaoAtividadePlanoAcaoHandler({
                            observacao: observacao,
                            idAtividade: showSelecao.idAtividade,
                            planoAcao: showSelecao.planoAcao,
                        });
                    }}
                />
            )}

            <Footer>
                <View className="flex flex-row justify-around space-x-2">
                    <View className="grow">
                        <BotaoDelineado
                            onPress={salvarPlanoAcaoHandler}
                            disabled={!!loading || loadingBotaoConcluir}
                            loading={loading == "salvar"}
                        >
                            Salvar
                        </BotaoDelineado>
                    </View>
                    <View className="grow">
                        <Botao
                            onPress={onConcluirVisita}
                            disabled={!!loading || loadingBotaoConcluir}
                            loading={loadingBotaoConcluir}
                        >
                            Concluir
                        </Botao>
                    </View>
                </View>
            </Footer>
        </Container>
    );
};
