import * as LojaStore from "@contexts/Loja";
import * as MapaContext from "@contexts/Mapa";
import { CHAVES_GLOSSARIO } from "@hooks/useGlossario";
import { BackIcon } from "@shared/components/Icons/BackIcon";
import {
    HeaderBar,
    PageHeader,
    TituloComGlossario,
} from "@shared/components/page";
import { Container } from "@shared/components/page/Container";
import { Visita } from "./components/Visita";

export const PaginaVisita = () => {
    const navegarParaLoja = MapaContext.navegarParaLoja;

    const voltarHandler = () => {
        navegarParaLoja(LojaStore.ABAS_TELA_LOJA.VISITAS);
    };

    return (
        <Container>
            <PageHeader>
                <HeaderBar>
                    <TituloComGlossario
                        chaveGlossario={CHAVES_GLOSSARIO.TITULO_PAGINA_VISITA}
                        color="#00AF14"
                    />
                    <BackIcon color={"#00AF14"} onPress={voltarHandler} />
                </HeaderBar>
            </PageHeader>
            <Visita />
        </Container>
    );
};
