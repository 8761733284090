import {
    AtividadePlanoAcao,
    ItemAtividadePlanoAcao,
    PROP_NAMES,
} from "@features/PlanoAcao/components";
import { CardAtividadePlanoAcao } from "@features/PlanoAcao/components/CardAtividadePlanoAcao";
import { ConcluirAtividadePlanoAcao } from "@features/PlanoAcao/components/ConcluirAtividadePlanoAcao";
import { Checklist } from "@models/Checklist";
import * as PlanoAcaoModel from "@models/PlanoAcao";
import { FlatList } from "react-native";

export type AtividadePlanoAcaoType = ItemAtividadePlanoAcao & {
    onClick?: (prop: PROP_NAMES) => void;
};

export interface ListaAtividadesPlanoAcaoProps {
    atividades: AtividadePlanoAcaoType[];
    modo: "execucao" | "configuracao";
    onConcluirAtividadeClick?: (atividade: AtividadePlanoAcaoType) => void;
    onChangeValue?: (
        idAtividade: string,
        propName: PROP_NAMES,
        planoAcao?: PlanoAcaoModel.PlanoAcao,
        value?: string | number,
    ) => void;
    checklist: Checklist;
}

export const ListaAtividadesPlanoAcao = ({
    atividades,
    modo,
    onConcluirAtividadeClick,
    onChangeValue,
    checklist,
}: ListaAtividadesPlanoAcaoProps) => {
    const gatilhos = {};

    checklist.itens.sort((a, b) => {
        if (!a.ordem || !b.ordem) return 1;

        return a.ordem - b.ordem;
    });

    atividades.sort((a, b) => {
        const itemA = checklist.itens.find((i) =>
            a.itemChecklistGatilho.find((id) => id == i.id),
        );
        const itemB = checklist.itens.find((i) =>
            b.itemChecklistGatilho.find((id) => id == i.id),
        );

        if (!itemA || !itemB) return 1;

        if (!itemA.ordem || !itemB.ordem) {
            if (itemA.id < itemB.id) return -1;
            if (itemB.id > itemA.id) return 1;
            return 0;
        }

        return itemA.ordem - itemB.ordem;
    });

    atividades.forEach((atividade) => {
        if (!gatilhos[atividade.itemChecklistGatilho[0]]) {
            gatilhos[atividade.itemChecklistGatilho[0]] = {
                titulo: checklist.itens.find(
                    (i) => i.id == atividade.itemChecklistGatilho[0],
                )?.title,
                idAtividade: atividade.id,
            };
        }
    });

    return (
        <FlatList
            data={atividades}
            keyExtractor={(atividade) => atividade.id}
            renderItem={({ item: atividade }) => {
                const titulo =
                    gatilhos[atividade.itemChecklistGatilho[0]]?.idAtividade ==
                    atividade.id
                        ? gatilhos[atividade.itemChecklistGatilho[0]]?.titulo
                        : undefined;

                switch (modo) {
                    case "configuracao":
                        return (
                            <CardAtividadePlanoAcao>
                                {buildAtividadePlanoAcao(
                                    atividade,
                                    titulo,
                                    onChangeValue,
                                )}
                            </CardAtividadePlanoAcao>
                        );

                    case "execucao":
                        return (
                            <ConcluirAtividadePlanoAcao
                                onClick={() =>
                                    onConcluirAtividadeClick &&
                                    onConcluirAtividadeClick(atividade)
                                }
                                showBotaoConcluir={
                                    !PlanoAcaoModel.isAtividadeConcluida(
                                        atividade.status,
                                    )
                                }
                            >
                                {buildAtividadePlanoAcao(
                                    atividade,
                                    titulo,
                                    onChangeValue,
                                )}
                            </ConcluirAtividadePlanoAcao>
                        );
                }
            }}
        />
    );
};

const buildAtividadePlanoAcao = (
    atividade: AtividadePlanoAcaoType,
    titulo?: string,
    onChangeValue?: (
        idAtividade: string,
        propName: PROP_NAMES,
        planoAcao?: PlanoAcaoModel.PlanoAcao,
        value?: string | number,
    ) => void,
) => {
    return (
        <AtividadePlanoAcao
            key={atividade.id}
            item={{ ...atividade }}
            titulo={titulo}
            onClickProps={
                atividade.onClick
                    ? (propName) => {
                          const onClick = atividade.onClick;
                          onClick && onClick(propName);
                      }
                    : undefined
            }
            onChangeValue={
                onChangeValue
                    ? (propName, value) => {
                          onChangeValue(
                              atividade.id,
                              propName,
                              atividade.planoAcao,
                              value,
                          );
                      }
                    : undefined
            }
        />
    );
};
