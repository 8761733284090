import { get } from "@shared/http/Http.service";
import { basePath } from "./contants";

export type ParametroOrganizacao = {
    nome: string;
    valor: string;
    descricao: string;
};

export const consultarParametroOrganizacao = async (nome: string) => {
    const parametro = await get<ParametroOrganizacao>(
        `${basePath}/parametros?nome=${nome}`
    );
    return parametro;
};
