import * as UsuarioApi from "@api/Usuario";
import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import { VisitaNaoEncontradaException } from "@exceptions/Visita/VisitaNaoEncontrada.exception";
import * as Visita from "@models/Visita";
import { Localizacao, StatusVisita } from "@models/Visita";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type VisitaApi = {
    id: string;
    numero: number;
    startDate: Date;
    promoter: string;
    status: StatusVisita;
    checklist: string;
    planoAcao: string;
    localizacao?: Localizacao;
};

export type GetVisitaExceptions =
    | HttpExceptions
    | ApiException
    | AtividadePlanoAcaoInvalida
    | VisitaNaoEncontradaException;

export type GetVisitaResult = ResultAsync<GetVisitaExceptions, Visita.Visita[]>;

export interface GetVisitaProps {
    idLoja: string;
    ultimaVisita?: boolean;
}

export async function getVisita({
    idLoja,
    ultimaVisita = false,
}: GetVisitaProps): GetVisitaResult {
    const visitaApi = await get<VisitaApi[]>(
        `${basePath}?storeId=${idLoja}&ultimaVisita=${ultimaVisita}`
    );
    if (visitaApi.isFailure()) return R.failure(visitaApi.error);

    if (!visitaApi.value) return R.failure(new VisitaNaoEncontradaException());

    const promotores: { id: string; nome: string }[] = [];
    const visitas: Visita.Visita[] = [];
    for (const visita of visitaApi.value) {
        if (!promotores.some((p) => p.id === visita.promoter)) {
            const nomePromotor = await UsuarioApi.getNomeUsuario(
                visita.promoter
            );
            if (nomePromotor.isFailure()) return R.failure(nomePromotor.error);
            promotores.push({
                id: visita.promoter,
                nome: nomePromotor.value.name,
            });
        }

        const promotor = promotores.find((p) => p.id === visita.promoter)!;

        visitas.push(
            Visita.criarVisita({
                ...visita,
                promotor: Visita.criarPromotorVisita({
                    id: promotor.id,
                    nome: promotor.nome,
                }),
            })
        );
    }

    return R.ok(visitas);
}
