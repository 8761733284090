import { Props } from "@shared/domain/Props";
import React from "react";
import { View } from "react-native";

export interface BarraProgressoProps {
    progresso: number;
    range?: number[];
    cores?: string[];
}

export const BarraProgresso = ({
    range,
    cores,
    progresso,
}: BarraProgressoProps) => {
    const corPadrao = "green";

    let cor = corPadrao;
    if (range && cores) {
        const index = range?.findIndex((item) => progresso <= item);
        if (index === -1 && cores.length === range.length + 1) {
            cor = cores[range.length];
        } else {
            cor = cores[index];
        }
    }

    return (
        <View className="h-2 grow bg-zinc-200 rounded-lg">
            <View
                style={{
                    height: "100%",
                    width: `${progresso > 1 ? 100 : progresso * 100}%`,
                    backgroundColor: `${cor}`,
                    borderRadius: 5,
                }}
            />
        </View>
    );
};
