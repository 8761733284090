import React from "react";
import { View } from "react-native";

export interface PageSessionProps {
    children: React.ReactNode;
}

export const PageSession = ({ children }: PageSessionProps) => {
    return <View className="flex flex-col p-2">{children}</View>;
};
