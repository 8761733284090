import { CHAVES_GLOSSARIO, useGlossario } from "@hooks/useGlossario";
import { useEffect } from "react";
import { Text, View } from "react-native";

export interface DescricaoPlanoAcaoLojaProps {
    numeroVisita: number;
}

export const DescricaoPlanoAcaoLoja = ({
    numeroVisita,
}: DescricaoPlanoAcaoLojaProps) => {
    const { carregarTexto, componenteLoader, texto } = useGlossario();

    useEffect(() => {
        carregarTexto(
            CHAVES_GLOSSARIO.DESCRICAO_PLANO_ACAO_LOJA_CARD_RESUMO_PLANO_ACAO
        );
    }, []);

    return (
        <View className="flex flex-row items-center">
            {texto ? <Text>{texto}</Text> : componenteLoader}
            <Text className="font-bold">{` #${numeroVisita}`}</Text>
        </View>
    );
};
