import * as GlossarioApi from "@api/Glossario";
import { Loader } from "@shared/components/Loading/components/Loader";
import { useState } from "react";
import { useTheme } from "react-native-paper";
import { Theme } from "src/styles/GlobalStyles";

export enum CHAVES_GLOSSARIO {
    TITULO_ABA_VISITAS_PAGINA_LOJA,
    TITULO_ULTIMA_VISITA_PAGINA_LOJA,
    TITULO_BOTAO_NOVA_VISITA_PAGINA_LOJA,
    TITULO_BOTAO_CONTINUAR_VISITA_PAGINA_LOJA,
    DESCRICAO_PLANO_ACAO_LOJA_CARD_RESUMO_PLANO_ACAO,
    DESCRICAO_PONTO_VENDA_SEM_VISITA_PAGINA_LOJA,
    TITULO_PAGINA_VISITA,
}

export const useGlossario = () => {
    const theme = useTheme<Theme>();

    const componenteLoader = (
        <Loader color={theme.colors.primary} size={"xs"} />
    );

    const [texto, setTexto] = useState<string | undefined>(undefined);

    const carregarTexto = (chaveGlossario: CHAVES_GLOSSARIO): void => {
        GlossarioApi.getTexto(chaveGlossario).then((texto) => {
            setTexto(texto);
        });
    };

    return {
        carregarTexto,
        componenteLoader,
        texto,
    };
};
