import { get } from "@shared/http/Http.service";
import { basePath } from "./contants";

export type Organizacao = {
    nome: string;
    descricao: string;
    localizacaoObrigatoria: boolean;
    localizacaoPadrao: {
        latitude: number;
        longitude: number;
    };
};

export const getOrganizacao = async () => {
    return await get<Organizacao>(`${basePath}/me`);
};
