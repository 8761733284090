import { Fontisto } from "@expo/vector-icons";

import { IconProps, SizeIcons } from ".";
import { View } from "react-native";

export const DateIcon = ({
    size = "md",
    onPress,
    color = "#00AF14",
    className,
}: IconProps) => {
    return (
        <View>
            <Fontisto
                name="date"
                size={SizeIcons[size]}
                color={color}
                onPress={onPress}
                className={className}
            />
        </View>
    );
};
