import { LoginInterface } from "@/pages/login/Login.interface";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Screens } from "../Screens";

const LoginStackNavigator = createNativeStackNavigator();

export function LoginStack() {
    return (
        <LoginStackNavigator.Navigator>
            <LoginStackNavigator.Screen
                name={Screens.SIGN_IN}
                component={LoginInterface}
                options={{
                    animation: "fade",
                    animationDuration: 500,
                    headerShown: false,
                }}
            />
        </LoginStackNavigator.Navigator>
    );
}
