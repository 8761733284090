import { BusinessException } from "../BusinessException";

export class HttpAccessDeniedException extends BusinessException {
    HttpAccessDeniedException = "key";

    constructor(creationProps: Error | string = "Acesso negado") {
        super(creationProps);
    }

    getName(): string {
        return HttpAccessDeniedException.name;
    }

    getInstance(): this {
        return this;
    }
}
