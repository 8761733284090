import { Ionicons } from "@expo/vector-icons";
import { IconProps, SizeIcons } from ".";
import { Pressable, View } from "react-native";

export const CloseIcon = ({
    size = "md",
    onPress,
    color = "#270040",
    className,
}: IconProps) => {
    return (
        <Ionicons
            name="close"
            size={SizeIcons[size]}
            color={color}
            onPress={onPress}
            className={`${className}`}
        />
    );
};
