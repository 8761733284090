import { TextProps } from "react-native";
import { DefaultText } from "./DefaultText";

export interface TextBoldProps extends TextProps {
    label: string | React.ReactElement;
}

export const TextBold = ({ label, ...props }: TextBoldProps) => {
    return <DefaultText label={label} bold {...props} />;
};
