import React from "react";

import { FingerPrintIcon } from "@shared/components/Icons/FingerPrint";
import { MessageModal } from "../../../shared/components/modal/MessageModal";

export interface LoginNotAuthorizedModalProps {
    visible: boolean;
    onRequestClose: () => void;
}

export const LoginNotAuthorizedModal = ({
    visible,
    onRequestClose,
}: LoginNotAuthorizedModalProps) => {
    return (
        <MessageModal
            visible={visible}
            renderIcon={() => <FingerPrintIcon size={120} color="#FFA000" />}
            title={"Acesso negado"}
            titleColor={"#646464"}
            primaryButtonText={"Tentar novamente"}
            primaryButtonColor="#FFA000"
            message={"Seu e-mail ou senha estão incorretos."}
            messageColor={"#646464"}
            onRequestClose={onRequestClose}
            onPrimaryButtonPressed={onRequestClose}
        />
    );
};
