import { ButtonLoader } from "@components/Loaders/ButtonLoader";
import { CardLoader } from "@components/Loaders/CardLoader";
import * as ErroContext from "@contexts/Erro";
import * as LojaStore from "@contexts/Loja";
import * as GlobalStore from "@contexts/Mapa";
import { LojaNaoCarregadaException } from "@exceptions/Loja/LojaNaoCarregada.exception";
import { useAsyncErrorHandling } from "@hooks/useAsyncErrorHandling";
import { CHAVES_GLOSSARIO } from "@hooks/useGlossario";
import { useVisita } from "@hooks/useVisita";
import { StatusVisita } from "@pages/Loja/components/ResumoVisita/components/StatusVisita";
import { DateIcon } from "@shared/components/Icons/DateIcon";
import { InformationIcon } from "@shared/components/Icons/InformationIcon";
import { PersonIcon } from "@shared/components/Icons/PersonIcon";
import { BotaoComGlossario } from "@shared/components/button/Button";
import {
    IconLabelValueList,
    IconLabelValueListData,
} from "@shared/components/itemList/IconLabelValueList";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { LabelComGlossario } from "@shared/components/text/Label";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { View } from "react-native";

export default function AbaResumoVisita() {
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const {
        continuarVisita: continuarVisitaHook,
        iniciarVisita,
        carregarVisitas,
    } = useVisita();

    const visitas = LojaStore.useLojaStore((state) => state.visitas);
    const checklistConfig = LojaStore.useLojaStore(
        (state) => state.checklistsConfig,
    );

    const loja = GlobalStore.useMapaContext((state) => state.lojaSelecionada);

    useEffect(() => {
        if (loja) {
            setLoading(true);
            carregarVisitas({
                idLoja: loja.id,
                actionName: "carregarVisita",
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [loja]);

    const continuarVisita = GlobalStore.useMapaContext(
        (state) => state.continuarVisita,
    );

    const iniciarNovaVisitaHandler = useAsyncErrorHandling(
        async (idChecklistConfig) => {
            setLoadingButton(true);
            if (!loja) throw new LojaNaoCarregadaException();

            iniciarVisita(loja, idChecklistConfig)
                .then((result) => {
                    if (result.isFailure()) ErroContext.addErro(result.error);
                })
                .finally(() => {
                    setLoadingButton(false);
                });
        },
    );

    const continuarVisitaHandler = async () => {
        if (visitas && visitas.length > 0) {
            setLoadingButton(true);

            continuarVisitaHook(visitas[0])
                .then((result) => {
                    if (result.isFailure()) ErroContext.addErro(result.error);
                })
                .finally(() => {
                    setLoadingButton(false);
                });
        }

        continuarVisita();
    };

    const details: IconLabelValueListData[] = [];

    if (visitas && visitas?.length > 0) {
        details.push({
            id: "1",
            label: "Data",
            value: visitas[0]?.startDate
                ? format(new Date(visitas[0].startDate), "dd/MM/yyyy")
                : "",
            icon: <DateIcon size="sm" />,
        });

        details.push({
            id: "2",
            label: "Promotor",
            value: visitas[0]?.promotor?.nome || "",
            icon: <PersonIcon size="sm" />,
        });

        details.push({
            id: "3",
            label: "Status",
            value: <StatusVisita status={visitas[0]?.status} />,
            icon: <InformationIcon size="sm" />,
        });
    }

    return (
        <Container>
            <Body>
                {loading ? (
                    <View className="flex flex-col p-2 space-y-2">
                        <CardLoader />
                        <CardLoader />
                        <CardLoader />
                    </View>
                ) : visitas && visitas.length > 0 ? (
                    <Fragment>
                        <View className="flex flex-row px-2 pt-4">
                            <LabelComGlossario
                                chaveGlossario={
                                    CHAVES_GLOSSARIO.TITULO_ULTIMA_VISITA_PAGINA_LOJA
                                }
                            />
                        </View>
                        <IconLabelValueList data={details} />
                    </Fragment>
                ) : (
                    <View className="flex items-center justify-center p-2 ">
                        <LabelComGlossario
                            chaveGlossario={
                                CHAVES_GLOSSARIO.DESCRICAO_PONTO_VENDA_SEM_VISITA_PAGINA_LOJA
                            }
                        />
                    </View>
                )}
            </Body>

            <View className="flex flex-row w-full p-2">
                {loading && <ButtonLoader style={{ marginLeft: 8 }} />}

                {!loading && visitas && visitas[0]?.status === "inProgress" && (
                    <View className="w-full">
                        <BotaoComGlossario
                            chaveGlossario={
                                CHAVES_GLOSSARIO.TITULO_BOTAO_CONTINUAR_VISITA_PAGINA_LOJA
                            }
                            onPress={continuarVisitaHandler}
                            className="w-full"
                            loading={loadingButton}
                            disabled={loadingButton}
                        />
                    </View>
                )}

                {!loading &&
                    visitas &&
                    (visitas[0]?.status === "completed" ||
                        visitas.length == 0) && (
                        <View className="w-full">
                            <BotaoComGlossario
                                opcoes={
                                    checklistConfig.length > 1
                                        ? checklistConfig.map((c) => c.nome)
                                        : []
                                }
                                chaveGlossario={
                                    CHAVES_GLOSSARIO.TITULO_BOTAO_NOVA_VISITA_PAGINA_LOJA
                                }
                                onPressOption={(opcao) => {
                                    const idChecklistConfig =
                                        checklistConfig.find(
                                            (c) => c.nome === opcao,
                                        )?.id;
                                    if (idChecklistConfig) {
                                        iniciarNovaVisitaHandler(
                                            idChecklistConfig,
                                        );
                                    }
                                }}
                                onPress={() =>
                                    iniciarNovaVisitaHandler(
                                        checklistConfig[0]?.id,
                                    )
                                }
                                className="w-full"
                                loading={loadingButton}
                                disabled={loadingButton}
                            />
                        </View>
                    )}
            </View>
        </Container>
    );
}
