import { StatusVisita as StatusVisitaModel } from "@models/Visita/StatusVisita";
import { Tag } from "@shared/components/tag/Tag";

export interface StatusVisitaProps {
    status?: StatusVisitaModel;
}

export const StatusVisita = ({ status }: StatusVisitaProps) => {
    switch (status) {
        case "inProgress":
            return <Tag label={"Em andamento"} />;
        case "completed":
            return <Tag label={"Concluída"} />;
    }
};
