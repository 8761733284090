import * as ErroContext from "@contexts/Erro";
import * as LojaStore from "@contexts/Loja";
import * as MapaContext from "@contexts/Mapa";
import { ChecklistNaoCarregadoException } from "@exceptions/Checklist/ChecklistNaoCarregado.exception";
import { PlanoAcaoNaoCarregadoException } from "@exceptions/PlanoAcao/PlanoAcaoNaoCarregado.exception";
import { VisitaNaoCarregadaException } from "@exceptions/Visita/VisitaNaoCarregada.exception";
import { useVisita } from "@hooks/useVisita";
import { Localizacao } from "@models/Visita";
import { useLocalizacao } from "@services/UseLocalizacao/useLocalizacao";
import { Tab, TabView } from "@shared/components/tabView/TabView";
import { useState } from "react";
import { AbaChecklist } from "./AbaChacklist";
import { AbaConfiguracaoPlanoAcao } from "./AbaConfiguracaoPlanoAcao";
import { R } from "@shared/utils/Result";

export const Visita = () => {
    const [loading, setLoading] = useState<"concluir" | undefined>(undefined);

    const { getLocalizacao } = useLocalizacao();

    const drawer = MapaContext.useMapaContext((store) => store.drawer);
    const alterarTabDrawerVisita = MapaContext.useMapaContext(
        (store) => store.alterarTabDrawerVisita,
    );

    const { concluirVisita, configurarPlanosAcaoVisita } = useVisita();

    const localizacaoObrigatoria = MapaContext.useMapaContext(
        (store) => store.localizacaoObrigatoria,
    );

    const visita = LojaStore.useLojaStore(
        (store: LojaStore.LojaStore) => store.visitaSelecionada,
    );
    const checklists = LojaStore.useLojaStore(
        (store: LojaStore.LojaStore) => store.checklists,
    );
    const planosAcao = LojaStore.useLojaStore(
        (store: LojaStore.LojaStore) => store.planosAcao,
    );
    const checklistConfig = LojaStore.useLojaStore(
        (store: LojaStore.LojaStore) => store.checklistsConfig,
    );

    /**
     * Conclui a visita no backend
     */
    const concluirVisitaHandler = async () => {
        setLoading("concluir");

        if (!visita) throw new VisitaNaoCarregadaException();
        if (!checklists) throw new ChecklistNaoCarregadoException();
        if (!planosAcao) throw new PlanoAcaoNaoCarregadoException();

        const getLocalizacaoResult = await getLocalizacao();

        let localizacao: Localizacao | undefined = undefined;
        if (getLocalizacaoResult.isOk())
            localizacao = getLocalizacaoResult.value;
        else if (getLocalizacaoResult.isFailure() && localizacaoObrigatoria) {
            ErroContext.addErro(getLocalizacaoResult.error);
            setLoading(undefined);
            return;
        }

        const pdfVisita = await concluirVisita({
            visita,
            checklists,
            planoAcao: planosAcao, //TODO: ajustar
            localizacao,
        });

        if (pdfVisita.isFailure()) {
            ErroContext.addErro(pdfVisita.error);
            setLoading(undefined);
            return;
        }

        const url = URL.createObjectURL(
            new Blob([pdfVisita.value], { type: "application/pdf" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `visita_${visita?.numero}_${new Date()
                .toLocaleDateString()
                .replaceAll("/", "")}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setLoading(undefined);
    };

    const salvarPlanoAcaoHandler = async () => {
        if (!planosAcao) return R.failure(new PlanoAcaoNaoCarregadoException());
        const configurarPlanoAcao =
            await configurarPlanosAcaoVisita(planosAcao);
        configurarPlanoAcao.isFailure() &&
            ErroContext.addErro(configurarPlanoAcao.error);
    };

    const tabs: Tab[] = [
        {
            id: LojaStore.ABAS_TELA_VISITA.CHECKLIST,
            name: "Checklist",
            component: (
                <AbaChecklist
                    checklists={checklists}
                    onConcluirVisita={concluirVisitaHandler}
                    loadingBotaoConcluir={loading === "concluir"}
                    checklistsConfig={checklistConfig}
                />
            ),
        },
        {
            id: LojaStore.ABAS_TELA_VISITA.PLANO_ACAO,
            name: "Plano de ação",
            component: (
                <AbaConfiguracaoPlanoAcao
                    onConcluirVisita={concluirVisitaHandler}
                    loadingBotaoConcluir={loading === "concluir"}
                />
            ),
        },
    ];

    const tabChangeHandler = async (newTab: Tab, previousTab: Tab) => {
        alterarTabDrawerVisita(newTab.id as LojaStore.ABAS_TELA_VISITA);

        switch (previousTab.id) {
            case LojaStore.ABAS_TELA_VISITA.PLANO_ACAO:
                await salvarPlanoAcaoHandler();
                break;
        }
    };

    return (
        <TabView
            tabs={tabs}
            index={tabs.findIndex(
                (tab) => drawer?.nome === "visita" && tab.id === drawer.aba,
            )}
            onTabChange={tabChangeHandler}
            reloadOnTabChange={true}
        />
    );
};
