import { useLoading } from "@shared/components/Loading/hooks/useLoading";
import { loadingQueue } from "@shared/components/Loading/models/LoadingQueue";
import { useErro } from "@shared/components/Error/hooks/useErro";
import React from "react";
import { BackHandler, Keyboard, Platform } from "react-native";
import * as Animatable from "react-native-animatable";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import { ModalLoading } from "../Loading/components/ModalLoading";
import { Error } from "../Error/models/Error";

export interface PageContainerProps {
    animationIn?: "fadeInLeftBig";
    children: React.ReactNode;
    className?: string;
    loadingQueue?: loadingQueue;
}

export const PageContainer = ({
    children,
    className,
    animationIn,
}: PageContainerProps) => {
    const { erro, setErro } = useErro();
    const { loadingQueue } = useLoading();

    if (Platform.OS === "android")
        BackHandler.addEventListener("hardwareBackPress", () => {
            Keyboard.dismiss();
            return true;
        });

    return (
        <Animatable.View
            animation={animationIn}
            duration={200}
            style={{ flex: 1 }}
            className="bg-white"
        >
            <SafeAreaView
                className={`flex flex-col flex-1 gap-y-4 ${className}`}
            >
                <GestureHandlerRootView className="flex flex-col flex-1">
                    {children}

                    <ModalLoading visible={loadingQueue.length > 0} />
                    <Error
                        error={erro}
                        onDismiss={() => setErro && setErro(undefined)}
                    />
                </GestureHandlerRootView>
            </SafeAreaView>
        </Animatable.View>
    );
};
