import { ResumoPlanoAcao } from "@components/ResumoPlanoAcao/ResumoPlanoAcao";
import * as ErroContext from "@contexts/Erro";
import * as ErrorContext from "@contexts/Erro";
import { PlanoAcaoNaoCarregadoException } from "@exceptions/PlanoAcao/PlanoAcaoNaoCarregado.exception";
import { CadastroObservacao } from "@features/PlanoAcao/components/CadastroObservacao";
import { useResponderPlanoAcao } from "@hooks/useResponderPlanoAcao";
import { PROP_NAMES } from "@pages/Visita/components/AtividadePlanoAcao";
import {
    AtividadePlanoAcaoType,
    ListaAtividadesPlanoAcao,
} from "@pages/Visita/components/ListaAtividadesPlanoAcao";
import { HeaderBar, PageHeader, Titulo } from "@shared/components/page";
import { Body } from "@shared/components/page/Body";
import { Container } from "@shared/components/page/Container";
import { Image } from "expo-image";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

type ShowInclusaoObservacao = {
    idAtividade: string;
    tipo: "observacao";
    observacaoAtual?: string;
};

export const PaginaRespostaPlanoAcaoPublica = ({ route }) => {
    const idVisita = route.params.idVisita;
    const idPlanoAcao = route.params.idPlanoAcao;
    const token = route.params.token;

    const { carregarStore, planoAcao, concluirAtividadePlanoAcao } =
        useResponderPlanoAcao();

    const [showSelecao, setShowSelecao] = useState<ShowInclusaoObservacao>();

    useEffect(() => {
        !planoAcao && carregarPlanoAcao();
    }, []);

    const carregarPlanoAcao = async () => {
        const carregarStoreResult = await carregarStore(
            idVisita,
            token,
            idPlanoAcao,
        );
        if (carregarStoreResult.isFailure()) {
            ErrorContext.addErro(carregarStoreResult.error);
            return;
        }
    };

    const concluirAtividadePlanoAcaoHandler = async (
        atividade: AtividadePlanoAcaoType,
    ) => {
        if (!planoAcao?.resumo) {
            ErroContext.addErro(new PlanoAcaoNaoCarregadoException());
            return;
        }

        const concluirAtividadePlanoAcaoResult =
            await concluirAtividadePlanoAcao({
                idVisita: planoAcao.resumo.visita.id,
                idAtividade: atividade.id,
                token: token,
                idPlanoAcao,
            });

        if (concluirAtividadePlanoAcaoResult.isFailure()) {
            ErroContext.addErro(concluirAtividadePlanoAcaoResult.error);
            return;
        }
    };

    const onClickProps = (propName: PROP_NAMES, id: string) => {
        switch (propName) {
            case PROP_NAMES.OBSERVACAO:
                setShowSelecao({
                    tipo: "observacao",
                    idAtividade: id,
                    observacaoAtual: planoAcao?.atividades.find(
                        (a) => a.id === id,
                    )?.observacao,
                });
        }
    };

    return (
        <View className="flex flex-row flex-1">
            <View className="flex flex-col w-[448px] h-screen">
                <Container>
                    <ScrollView>
                        <PageHeader>
                            <HeaderBar>
                                <Titulo
                                    label={"Plano de Ação"}
                                    color="#00AF14"
                                />
                            </HeaderBar>
                        </PageHeader>

                        {planoAcao && (
                            <Body>
                                <ResumoPlanoAcao
                                    data={{
                                        nomeLoja: "Loja de teste",
                                        nomePromotor:
                                            planoAcao?.resumo?.planoAcao
                                                .promotor,
                                        numeroVisita:
                                            planoAcao?.resumo?.visita.numero,
                                        dataInicio:
                                            planoAcao?.resumo?.visita
                                                .dataCriacao,
                                        dataFim:
                                            planoAcao?.resumo?.planoAcao
                                                .dataLimite,
                                        status: planoAcao?.resumo?.planoAcao
                                            .status,
                                        percentualProgresso:
                                            planoAcao?.resumo?.planoAcao
                                                .percentualPrazo,
                                    }}
                                />

                                <ListaAtividadesPlanoAcao
                                    atividades={
                                        planoAcao?.atividades.map(
                                            (atividade) => ({
                                                id: atividade.id,
                                                descricao: atividade.descricao,
                                                status: atividade.status,
                                                prazo: atividade.prazo,
                                                responsavel:
                                                    atividade.responsavel,
                                                opcoesPrazo: atividade.prazo
                                                    ? [atividade.prazo]
                                                    : [],
                                                dataInicio:
                                                    planoAcao?.resumo?.visita
                                                        .dataCriacao,
                                                observacao:
                                                    atividade.observacao,
                                                onClick(prop) {
                                                    onClickProps(
                                                        prop,
                                                        atividade.id,
                                                    );
                                                },
                                                itemChecklistGatilho:
                                                    atividade.itemChecklistGatilho,
                                            }),
                                        ) || []
                                    }
                                    modo={"execucao"}
                                    onConcluirAtividadeClick={
                                        concluirAtividadePlanoAcaoHandler
                                    }
                                    checklist={planoAcao.checklist}
                                />
                            </Body>
                        )}
                    </ScrollView>
                </Container>
            </View>
            <View className="flex flex-col flex-1 h-full bg-[#270040] items-center">
                <Image
                    className="flex flex-1 w-[500px]"
                    source={require("../../../assets/images/checkei/logo-horizontal.png")}
                    contentFit="scale-down"
                />
            </View>

            {showSelecao?.tipo === "observacao" && (
                <CadastroObservacao
                    observacaoAtual={showSelecao.observacaoAtual}
                    onClose={() => {
                        setShowSelecao(undefined);
                    }}
                    onSave={(observacao) => {}}
                    modo="visualizacao"
                />
            )}
        </View>
    );
};
