import React from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { DefaultText, DefaultTextProps } from "./DefaultText";
import { Theme } from "../../../styles/GlobalStyles";

export interface SubtitleProps extends DefaultTextProps {
    label: string;
}

export const Subtitle: React.FC<SubtitleProps> = ({
    label,
    ...props
}: SubtitleProps) => {
    const theme = useTheme<Theme>();

    return (
        <DefaultText
            className=" text-lg font-semibold text-[#270040]"
            label={label}
            {...props}
        />
    );
};
