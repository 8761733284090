import { Picker } from "@react-native-picker/picker";
import { somarDias } from "@shared/utils/SomarDias";
import { useCallback } from "react";

export interface PrazoPlanoAcaoSelectProps {
    options: number[];
    value?: number;
    dataInicio?: Date;
    onSelect: (prazo: number | undefined) => void;
    enabled?: boolean;
}

export const PrazoPlanoAcaoSelect = ({
    options = [],
    value,
    dataInicio,
    onSelect,
    enabled = true,
}: PrazoPlanoAcaoSelectProps) => {
    const buildLabel = useCallback(
        (prazo: number) => {
            return `${prazo} dias | ${somarDias(
                dataInicio || new Date(),
                prazo
            ).toLocaleDateString()}`;
        },
        [options]
    );

    return (
        <Picker
            selectedValue={value}
            onValueChange={(value, index) => {
                onSelect(index === 0 ? undefined : options[index - 1]);
            }}
            className="bg-transparent outline-none text-zinc-500 font-bold text-sm"
            enabled={enabled}
        >
            <Picker.Item label="Selecione" value={undefined} />
            {options.map((prazo) => (
                <Picker.Item
                    label={buildLabel(prazo)}
                    value={prazo}
                    key={prazo}
                />
            ))}
        </Picker>
    );
};
