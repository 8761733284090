import { CardAtividadePlanoAcao } from "@features/PlanoAcao/components/CardAtividadePlanoAcao";

import { View } from "react-native";
import { Button } from "react-native-paper";

export interface ConcluirAtividadePlanoAcaoProps {
    onClick?: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    showBotaoConcluir?: boolean;
}

export const ConcluirAtividadePlanoAcao = ({
    children,
    onClick,
    disabled,
    showBotaoConcluir,
}: ConcluirAtividadePlanoAcaoProps) => {
    const onClickHandler = () => {
        onClick && onClick();
    };

    return (
        <CardAtividadePlanoAcao>
            {children}

            <View className="flex flex-row-reverse px-2 bg-zinc-50 rounded py-2">
                {showBotaoConcluir && (
                    <Button
                        mode="text"
                        className="cursor-pointer"
                        onPress={onClickHandler}
                        disabled={disabled}
                    >
                        Concluir Atividade
                    </Button>
                )}
            </View>
        </CardAtividadePlanoAcao>
    );
};
