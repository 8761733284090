import { CHAVES_GLOSSARIO } from "@hooks/useGlossario";
import { Label, LabelComGlossario } from "@shared/components/text/Label";
import React, { ReactNode } from "react";
import { Pressable, ScrollView, View } from "react-native";

export interface Tab {
    id: string;
    name: string | ReactNode;
    component: ReactNode;
}

export interface TabViewProps {
    tabs: Tab[];
    index?: number;
    onTabChange?: (newTab: Tab, previousTab: Tab, newIndex: number) => void;
    reloadOnTabChange?: boolean;
}

export const TabView = ({
    tabs,
    index = 0,
    onTabChange,
    reloadOnTabChange = false,
}: TabViewProps) => {
    const handleOnChange = (newIndex: number) => {
        onTabChange && onTabChange(tabs[newIndex], tabs[index], newIndex);
    };

    return (
        <View className="flex flex-1 flex-col w-full grow ">
            <View className="flex flex-col w-full">
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    className="flex flex-1 flex-row border-b border-b-zinc-200"
                >
                    {tabs.map((option, idx) => (
                        <Pressable
                            key={idx}
                            onPress={() => handleOnChange(idx)}
                        >
                            <View
                                className={`flex flex-row p-3  ${
                                    idx == index &&
                                    `border-b-2 border-b-[#00AF14]`
                                }`}
                            >
                                {typeof option.name === "string" ? (
                                    <Titulo label={option.name} />
                                ) : (
                                    option.name
                                )}
                            </View>
                        </Pressable>
                    ))}
                </ScrollView>
            </View>

            {tabs.map((tab, idx) =>
                reloadOnTabChange ? (
                    idx === index && (
                        <View
                            className={`${
                                idx === index ? "flex flex-1" : "hidden"
                            }`}
                            key={idx}
                        >
                            {tab.component}
                        </View>
                    )
                ) : (
                    <View
                        className={`${
                            idx === index ? "flex flex-1" : "hidden"
                        }`}
                        key={idx}
                    >
                        {tab.component}
                    </View>
                )
            )}
        </View>
    );
};

export interface TituloProps {
    label: string;
}

export const Titulo = ({ label }: TituloProps) => {
    return <Label className="text-[#00AF14] font-bold" label={label} />;
};

export interface TituloComGlossarioProps {
    chaveGlossario: CHAVES_GLOSSARIO;
}

export const TituloComGlossario = ({
    chaveGlossario,
}: TituloComGlossarioProps) => {
    return (
        <LabelComGlossario
            chaveGlossario={chaveGlossario}
            className="text-[#00AF14] font-bold"
        />
    );
};
