import { Exception } from "@shared/exceptions/Exception";

export class LocalizacaoException extends Exception {
    LocalizacaoException = "key";

    constructor(string: Error | string | Exception) {
        super(string);
    }

    getName(): string {
        return LocalizacaoException.name;
    }

    getInstance(): this {
        return this;
    }
}
