import * as LojaApi from "@api/Loja";
import { Loja } from "@models/Loja/Loja";
import { R } from "@shared/utils/Result";
import { useLojaStore } from "../LojaStore";

export const carregarDetalhesLoja = async (loja: Loja) => {
    const detalhesLoja = await LojaApi.listarDetalhesLoja(loja.id);

    if (detalhesLoja.isFailure()) return R.failure(detalhesLoja.error);

    useLojaStore.setState(
        (state) => ({
            ...state,
            detalhesLoja: detalhesLoja.value,
        }),
        false,
        carregarDetalhesLoja.name
    );

    return R.ok();
};
