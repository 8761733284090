import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import { AtividadePlanoAcao } from "@infra/types/AtividadePlanoAcao";
import { ResumoPlanoAcaoApi } from "@infra/types/ResumoPlanoAcao";
import * as PlanoAcaoModel from "@models/PlanoAcao";
import { StatusAtividadePlanoAcao } from "@models/PlanoAcao/StatusAtividadePlanoAcao";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import * as Checklist from "@models/Checklist";

export type GetPlanoAcaoPorTokenExceptions =
    | HttpExceptions
    | ApiException
    | AtividadePlanoAcaoInvalida;
export type GetPlanoAcaoPorTokenResult = ResultAsync<
    GetPlanoAcaoPorTokenExceptions,
    {
        resumoPlanoAcao: PlanoAcaoModel.ResumoPlanoAcao;
        atividades: PlanoAcaoModel.AtividadePlanoAcao[];
        checklist: Checklist.Checklist;
    }
>;

type GetPlanoAcaoTokenResponse = {
    resumo: ResumoPlanoAcaoApi;
    atividades: AtividadePlanoAcao[];
};

export const getPlanosAcaoPorToken = async (
    idVisita: string,
    token: string,
    idPlanoAcao: string,
): GetPlanoAcaoPorTokenResult => {
    const response = await get<GetPlanoAcaoTokenResponse>(
        `core/bff/get-plano-acao-token?token=${token}&id-visita=${idVisita}&id-plano-acao=${idPlanoAcao}`,
    );
    if (response.isFailure()) return R.failure(response.error);

    const resumoPlanoAcao = PlanoAcaoModel.criarResumoPlanoAcao({
        planoAcao: {
            id: response.value.resumo.id,
            dataLimite: response.value.resumo.dataLimite,
            percentualPrazo: response.value.resumo.progresso,
            promotor: response.value.resumo.promotor.nome,
            quantidadeAtividadesConcluidas:
                response.value.resumo.quantidadeAtividadesConcluidas,
            quantidadeParticipantes:
                response.value.resumo.quantidadeParticipantes,
            quantidadeTotalAtividades:
                response.value.resumo.quantidadeAtividades,
            status: response.value.resumo.status,
            loja: { ...response.value.resumo.loja },
        },
        visita: {
            id: response.value.resumo.visita.id,
            dataCriacao: response.value.resumo.visita.dataCriacao,
            numero: response.value.resumo.visita.numero,
        },
    });

    const checklist = Checklist.criarChecklist({
        id: response.value.resumo.checklist.id,
        titulo: response.value.resumo.checklist.nome,
        idVisita: idVisita,
        itens: response.value.resumo.checklist.itens.map((item) => {
            return Checklist.criarItemChecklist({
                ...item,
                id: item.id,
                ordem: item.ordem,
                title: item.titulo,
                type: item.type,
            });
        }),
    });

    const atividades: PlanoAcaoModel.AtividadePlanoAcao[] = [];
    for (const atividade of response.value.atividades) {
        const atv = PlanoAcaoModel.criarAtividadePlanoAcao({
            id: atividade.id,
            descricao: atividade.descricao,
            status: atividade.status as StatusAtividadePlanoAcao,
            prazo: atividade.prazo,
            responsavel: atividade.responsavel,
            observacao: atividade.informacaoAdicional,
            itemChecklistGatilho: atividade.itemChecklistGatilho,
        });
        if (atv.isFailure()) return R.failure(atv.error);

        atividades.push(atv.value);
    }

    return R.ok({
        resumoPlanoAcao,
        atividades,
        checklist,
    });
};
