import { StoreIcon } from "@shared/components/Icons/StoreIcon";
import { Text, View } from "react-native";

export interface EnderecoLojaProps {
    endereco?: string;
}

export const EnderecoLoja = ({ endereco }: EnderecoLojaProps) => {
    return (
        <View className="flex flex-row space-x-4 py-4 px-4 items-center">
            <StoreIcon size="sm" />
            <Text className="text-md">{endereco}</Text>
        </View>
    );
};
