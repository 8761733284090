import { ListaPlanosAcao } from "@components/ListaPlanosAcaoLoja/ListaPlanosAcao";
import { EnderecoLoja } from "@components/Loja/EnderecoLoja";
import * as LojaStore from "@contexts/Loja";
import { ABAS_TELA_LOJA } from "@contexts/Loja";
import * as GlobalStore from "@contexts/Mapa";
import { CHAVES_GLOSSARIO } from "@hooks/useGlossario";
import AbaDetalhesLoja from "@pages/Loja/components/AbaDetalhesLoja";
import AbaResumoVisita from "@pages/Loja/components/AbaResumoVisita";

import {
    Tab,
    TabView,
    TituloComGlossario,
} from "@shared/components/tabView/TabView";

import React from "react";
import { View } from "react-native";

export enum TABS {
    GENERAL = "Geral",
    VISITS = "Visitas",
    ACTION_PLAN = "Planos de Ação",
    INDICATORS = "Indicadores",
}

export const Loja = () => {
    const loja = GlobalStore.useMapaContext((state) => state.lojaSelecionada);
    const tela = LojaStore.useLojaStore((state) => state.tela);

    const tabs: Tab[] = [
        {
            id: ABAS_TELA_LOJA.GERAL,
            name: "Geral",
            component: <AbaDetalhesLoja />,
        },
        {
            id: ABAS_TELA_LOJA.VISITAS,
            name: (
                <TituloComGlossario
                    chaveGlossario={
                        CHAVES_GLOSSARIO.TITULO_ABA_VISITAS_PAGINA_LOJA
                    }
                />
            ),
            component: <AbaResumoVisita />,
        },
        {
            id: ABAS_TELA_LOJA.PLANOS_DE_ACAO,
            name: "Planos de Ação",
            component: <ListaPlanosAcao />,
        },
        /*
        {
            id: "indicadores",
            name: TABS.INDICATORS,
            component: <></>,
        },
        */
    ];

    const index = Math.max(
        tabs.findIndex((t) => tela?.nome === "loja" && t.id === tela?.aba),
        0
    );

    return (
        <View className={`flex-1 pt-2 `}>
            <EnderecoLoja endereco={loja?.address} />
            <TabView
                tabs={tabs}
                index={index}
                onTabChange={(newTab, previousTab, newIndex) => {
                    LojaStore.alterarTabTelaLoja(newTab.id as ABAS_TELA_LOJA);
                }}
            />
        </View>
    );
};
