import * as ChecklistApi from "@api/Checklist";
import * as LojaApi from "@api/Loja";
import * as PlanoAcaoApi from "@api/PlanoAcao";
import { useMapaContext } from "@contexts/Mapa";
import { usePlanoAcaoStore } from "@contexts/PlanoAcao";
import { ChecklistNaoEncontradoException } from "@exceptions/Checklist/ChecklistNaoEncontrado.exception";
import { ResumoPlanoAcao } from "@models/PlanoAcao";
import { R } from "@shared/utils/Result";

export interface ConcluirAtividadePlanoAcaoComoPromotorProps {
    idVisita: string;
    idAtividade: string;
    observacao: string;
    idLoja: string;
    idChecklist: string;
    idPlanoAcao: string;
}

export const usePlanoAcao = () => {
    const { carregar: carregarPlanoAcaoStore } = usePlanoAcaoStore();

    const listarResumosPlanoAcao = async (idLoja: string) => {
        return await LojaApi.listarResumosPlanoAcao(idLoja);
    };

    const navegarParaPlanoAcao = async (resumoPlanoAcao: ResumoPlanoAcao) => {
        const planoAcao = await PlanoAcaoApi.getPlanoAcao(
            resumoPlanoAcao.visita.id,
            resumoPlanoAcao.checklist.id,
        );
        if (planoAcao.isFailure()) return R.failure(planoAcao.error);

        const checklists = await ChecklistApi.getChecklist(
            planoAcao.value.idVisita,
        );
        if (checklists.isFailure()) return R.failure(checklists.error);

        const checklist = checklists.value.find(
            (c) => c.id === planoAcao.value.idChecklist,
        );
        if (!checklist) return R.failure(new ChecklistNaoEncontradoException());

        carregarPlanoAcaoStore(resumoPlanoAcao, planoAcao.value, checklist);

        useMapaContext.setState(
            (state) => ({
                ...state,
                drawer: { nome: "planoAcao" },
            }),
            false,
            "navegarParaPlanoAcao",
        );

        return R.ok();
    };

    const concluirAtividadePlanoAcaoComoPromotor = async ({
        idVisita,
        idAtividade,
        observacao,
        idLoja,
        idChecklist,
        idPlanoAcao,
    }: ConcluirAtividadePlanoAcaoComoPromotorProps) => {
        const concluirPlanoAcaoApi =
            await PlanoAcaoApi.concluirAtividadePlanoAcao({
                idVisita,
                idAtividade,
                observacao,
                idPlanoAcao,
            });

        if (concluirPlanoAcaoApi.isFailure()) return concluirPlanoAcaoApi;

        const planoAcaoAtualizadoPromise = PlanoAcaoApi.getPlanoAcao(
            idVisita,
            idChecklist,
        );
        const resumosPlanoAcaoPromise = listarResumosPlanoAcao(idLoja);

        const [planoAcaoAtualizado, resumosPlanoAcao] = await Promise.all([
            planoAcaoAtualizadoPromise,
            resumosPlanoAcaoPromise,
        ]);

        if (planoAcaoAtualizado.isFailure()) return planoAcaoAtualizado;
        if (resumosPlanoAcao.isFailure()) return resumosPlanoAcao;

        usePlanoAcaoStore.setState(
            (state) => ({
                ...state,
                planoAcao: planoAcaoAtualizado.value,
                resumoPlanoAcao: resumosPlanoAcao.value.find(
                    (r) => r.visita.id === idVisita,
                ),
            }),
            false,
            "concluirAtividadePlanoAcao",
        );

        return R.ok();
    };

    const getPlanoAcaoPorToken = async (idVisita: string, token: string) => {
        return await PlanoAcaoApi.getPlanosAcaoPorToken(idVisita, token);
    };

    return {
        listarResumosPlanoAcao,
        navegarParaPlanoAcao,
        concluirAtividadePlanoAcaoComoPromotor,
        getPlanoAcaoPorToken,
    };
};
