import { Ionicons } from "@expo/vector-icons";
import { useState } from "react";
import { TextInput, TextInputProps } from "./TextInput";

export interface SenhaInputProps
    extends Omit<TextInputProps, "secureTextEntry" | "right"> {}

export const SenhaInput: React.FC<SenhaInputProps> = ({ ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextInput
            label={"Senha"}
            autoComplete="password"
            textContentType="password"
            secureTextEntry={!showPassword}
            right={() => (
                <Ionicons
                    name={showPassword ? "eye" : "eye-off"}
                    size={20}
                    color={"rgb(82 82 91)"}
                    onPress={() => setShowPassword(showPassword ? false : true)}
                />
            )}
            {...props}
        />
    );
};
