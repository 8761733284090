import { Picker } from "@react-native-picker/picker";
import classNames from "classnames";
import { Text, View } from "react-native";
import { twMerge } from "tailwind-merge";

export type SelectOption = { valor: string; rotulo: string };

export interface SelectProps {
    label?: string;
    options: SelectOption[];
    value?: string;
    onSelect: (itemValue?: string, itemIndex?: number) => void;
    labelStyle?: string;
    pickerStyle?: string;
    size?: "sm" | "md";
    enabled?: boolean;
}

export const Select = ({
    label,
    options,
    value,
    onSelect,
    pickerStyle,
    size = "md",
    enabled = true,
}: SelectProps) => {
    const pickerClass = twMerge(
        classNames({
            "py-4": size === "md",
            "py-2": size === "sm",
            "border-[#00AF14] bg-white text-[#270040] cursor-pointer": enabled,
            "border-zinc-300 bg-zinc-100 text-zinc-800 cursor-default":
                !enabled,
            "outline-[#00AF14] border rounded pl-2": true,
        }),
        pickerStyle,
    );

    return (
        <View className="flex flex-col justify-center w-full">
            {label && <Text className="font-bold text-[#270040]">{label}</Text>}
            <Picker
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => {
                    if (itemValue === "Selecione") onSelect(undefined, -1);
                    else onSelect(itemValue, itemIndex);
                }}
                className={pickerClass}
                enabled={enabled}
            >
                <Picker.Item label="Selecione" value={undefined} />
                {options.map((option) => (
                    <Picker.Item
                        label={option.rotulo}
                        value={option.valor}
                        key={option.valor}
                    />
                ))}
            </Picker>
        </View>
    );
};
