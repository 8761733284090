import { Checklist } from "@models/Checklist";
import { PlanoAcao, ResumoPlanoAcao } from "@models/PlanoAcao";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { isDevtoolsHabilitado } from "..";

export type PlanoAcaoState = {
    resumoPlanoAcao?: ResumoPlanoAcao;
    planoAcao?: PlanoAcao;
    checklist?: Checklist;
};

export type PlanoAcaoActions = {
    carregar: (
        resumoPlanoAcao: ResumoPlanoAcao,
        planoAcao: PlanoAcao,
        checklist: Checklist
    ) => void;
};

export type PlanoAcaoStore = PlanoAcaoState & PlanoAcaoActions;

export const usePlanoAcaoStore = create<PlanoAcaoStore>()(
    devtools(
        (set, get) => ({
            resumoPlanoAcao: undefined,
            planoAcao: undefined,
            checklist: undefined,
            carregar(resumoPlanoAcao, planoAcao, checklist) {
                set(
                    {
                        resumoPlanoAcao,
                        planoAcao,
                        checklist,
                    },
                    false,
                    "carregarPlanoAcaoStore"
                );
            },
        }),
        {
            name: "PlanoAcaoStore",
            enabled: isDevtoolsHabilitado(),
        }
    )
);
