import * as ChecklistApi from "@api/Checklist";
import * as PlanoAcaoApi from "@api/PlanoAcao";

import { ChecklistNaoEncontradoException } from "@exceptions/Checklist/ChecklistNaoEncontrado.exception";
import * as Checklist from "@models/Checklist";
import { R } from "@shared/utils/Result";
import { useState } from "react";
import * as PlanoAcao from "../models/PlanoAcao";

export interface ConcluirAtividadePlanoAcaoProps {
    idVisita: string;
    idAtividade: string;
    observacao?: string;
    token: string;
    idPlanoAcao: string;
}

export const useResponderPlanoAcao = () => {
    const [planoAcao, setPlanoAcao] = useState<{
        resumo: PlanoAcao.ResumoPlanoAcao;
        atividades: PlanoAcao.AtividadePlanoAcao[];
        checklist: Checklist.Checklist;
    }>();

    const carregarStore = async (
        idVisita: string,
        token: string,
        idPlanoAcao: string,
    ) => {
        const getPlanoAcaoPorTokenPromise = PlanoAcaoApi.getPlanosAcaoPorToken(
            idVisita,
            token,
            idPlanoAcao,
        );

        const [getPlanoAcaoPorToken] = await Promise.all([
            getPlanoAcaoPorTokenPromise,
        ]);

        if (getPlanoAcaoPorToken.isFailure())
            return R.failure(getPlanoAcaoPorToken.error);

        setPlanoAcao({
            resumo: getPlanoAcaoPorToken.value.resumoPlanoAcao,
            atividades: getPlanoAcaoPorToken.value.atividades,
            checklist: getPlanoAcaoPorToken.value.checklist,
        });

        return R.ok();
    };

    const concluirAtividadePlanoAcao = async ({
        idVisita,
        idAtividade,
        token,
        idPlanoAcao,
    }: ConcluirAtividadePlanoAcaoProps) => {
        const concluirPlanoAcaoApi =
            await PlanoAcaoApi.concluirAtividadePlanoAcao({
                idVisita,
                idAtividade,
                token,
                idPlanoAcao,
            });

        if (concluirPlanoAcaoApi.isFailure()) return concluirPlanoAcaoApi;

        carregarStore(idVisita, token, idPlanoAcao);

        return R.ok();
    };

    return {
        planoAcao,
        carregarStore,
        concluirAtividadePlanoAcao,
    };
};
