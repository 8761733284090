import * as Checklist from "@models/Checklist/Checklist";
import * as ItemChecklist from "@models/Checklist/ItemChecklist";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type GetChecklistExceptions = HttpExceptions | ApiException;
export type GetChecklistResult = ResultAsync<
    GetChecklistExceptions,
    Checklist.Checklist[]
>;

export async function getChecklist(idVisita: string): GetChecklistResult {
    const checklistApi = await get(`${basePath}/${idVisita}/checklist`);
    if (checklistApi.isFailure()) return R.failure(checklistApi.error);

    const result: Checklist.Checklist[] = [];

    let count = 0;

    for (const c of Array.isArray(checklistApi.value)
        ? checklistApi.value
        : [checklistApi.value]) {
        count++;
        const checklist = Checklist.criarChecklist({
            id: c.id,
            itens: c.itens.map((item) => {
                const i = ItemChecklist.criarItemChecklist({
                    ...item,
                });

                return i;
            }),
            idVisita: idVisita,
            titulo: c.nome,
        });
        result.push(checklist);
    }

    return R.ok(result);
}
