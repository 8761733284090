import * as ErroContext from "@contexts/Erro";
import * as LoginContext from "@contexts/Login";
import * as LoginStore from "@contexts/Login";
import { createStackNavigator } from "@react-navigation/stack";
import { LoginStack } from "@routes/public/LoginStack";
import * as AutenticacaoService from "@services/Autenticacao";
import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { MenuCustomizadoMapaStack } from "./MenusCustomizadosMapaStack";
import { Screens } from "@routes/Screens";
import { ChangePasswordInterface } from "@pages/AlteracaoSenha/ChangePassword.interface";
import * as UsuarioApi from "@api/Usuario";

const StackPrivada = createStackNavigator();

const erroPermmissaoNegada = "HttpAccessDeniedException";

export const StackPrivadaComponent = () => {
    const logado = LoginContext.useLoginStore((state) => state.logado);
    const [loading, setLoading] = useState(true);
    const erro = ErroContext.useErroContext((state) => state.erro);

    useEffect(() => {
        if (!logado) {
            validarLogin();
        } else {
            setLoading(false);
        }
    }, [logado]);

    useEffect(() => {
        if (erro && erro.name === erroPermmissaoNegada) {
            LoginStore.logOut();
        }
    }, [erro]);

    const validarLogin = async () => {
        const validarLogin = await AutenticacaoService.validarLogin();
        if (validarLogin.isOk()) {
            const getUsuarioAtual = await UsuarioApi.getUsuarioAtual();

            if (getUsuarioAtual.isFailure()) {
                setLoading(false);
                return;
            }

            LoginStore.logIn(getUsuarioAtual.value.menus);
        }

        setLoading(false);
    };

    const buildPage = () => {
        if (loading)
            return (
                <StackPrivada.Screen
                    name={"loadingStack"}
                    component={LoadingStack}
                />
            );

        return logado ? (
            <StackPrivada.Screen
                name={"mapaStack"}
                component={MenuCustomizadoMapaStack}
            />
        ) : (
            <>
                <StackPrivada.Screen
                    name={"loginStack"}
                    component={LoginStack}
                />
                <StackPrivada.Screen
                    name={Screens.CHANGE_PASSWORD}
                    component={ChangePasswordInterface}
                />
            </>
        );
    };

    return (
        <StackPrivada.Navigator screenOptions={{ headerShown: false }}>
            {buildPage()}
        </StackPrivada.Navigator>
    );
};

export const LoadingStack = () => {
    return (
        <View>
            <Text>Carregando...</Text>
        </View>
    );
};
