import { TechnicalException } from "@shared/exceptions/TechnicalException";

export class AtividadePlanoAcaoInvalida extends TechnicalException {
    AtividadePlanoAcaoInvalida = "key";

    getInstance(): this {
        return this;
    }

    getName(): string {
        return AtividadePlanoAcaoInvalida.name;
    }
}
