import React from "react";
import { ActivityIndicator, Modal, StyleSheet, View } from "react-native";
import { useTheme } from "react-native-paper";
import { Theme } from "../../../../styles/GlobalStyles";
import { Loader } from "./Loader";

export interface ModalLoadingProps {
    visible: boolean;
}

export const ModalLoading = ({ visible }: ModalLoadingProps) => {
    const theme = useTheme<Theme>();

    return (
        <Modal
            transparent={true}
            animationType="fade"
            visible={visible}
            onRequestClose={() => {}}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Loader size={"lg"} color={theme.colors.primary} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "rgba(0, 0, 0, 0.5)", // fundo fosco
    },
    modalContent: {
        backgroundColor: "transparent",
        padding: 20,
        borderRadius: 10,
        alignItems: "center",
    },
});
