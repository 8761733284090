import { Ionicons } from "@expo/vector-icons";
import { IconProps, SizeIcons } from ".";
import { View } from "react-native";

export const PersonIcon = ({
    size = "md",
    onPress,
    color = "#00AF14",
    className,
}: IconProps) => {
    return (
        <View>
            <Ionicons
                name="person-outline"
                size={SizeIcons[size]}
                color={color}
                onPress={onPress}
                className={className}
            />
        </View>
    );
};
