import {
    IconLabelValue,
    IconLabelValueProps,
} from "@shared/components/itemList/IconLabelValue";
import { FlatList, Pressable, View } from "react-native";

export type IconLabelValueListData = IconLabelValueProps & {
    id: string;
    formatValue?: (value: string | number) => string;
    quantidadeLinhas?: number;
};
export interface IconLabelValueListProps {
    data: IconLabelValueListData[];
    onClick?: (id: string) => void;
    separator?: boolean;
}

export const IconLabelValueList = ({
    data,
    onClick,
    separator = true,
    ...props
}: IconLabelValueListProps) => {
    return (
        <View className="flex flex-col" {...props}>
            <FlatList
                data={data}
                keyExtractor={(item) => item.label}
                renderItem={({ item, index }) => (
                    <Pressable
                        onPress={() => {
                            onClick && onClick(item.id);
                        }}
                        className={`${
                            !!onClick ? "cursor-pointer" : "cursor-default"
                        }`}
                    >
                        <IconLabelValue
                            icon={item.icon}
                            label={item.label}
                            value={
                                item.formatValue &&
                                item.value &&
                                (typeof item.value === "string" ||
                                    typeof item.value === "number")
                                    ? item.formatValue(item.value)
                                    : item.value
                            }
                            styleLabel={item.styleLabel}
                            styleValue={item.styleValue}
                        />

                        {index < data.length - 1 && separator && (
                            <View className="w-full border-t h-1 border-zinc-200 mt-1" />
                        )}
                    </Pressable>
                )}
            />
        </View>
    );
};
