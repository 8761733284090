import { Exception } from "@shared/exceptions/Exception";
import { ResultAsync } from "@shared/utils/Result";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { isDevtoolsHabilitado } from "..";

export type ErroState = {
    erro?: Error;
};

export const useErroContext = create<ErroState>()(
    devtools(
        (set, get) => ({
            erro: undefined,
        }),
        { name: "ErroContext", enabled: isDevtoolsHabilitado() }
    )
);

export const runAssync = async (fn: () => ResultAsync<Exception, any>) => {
    try {
        const result = await fn();
        if (result.isFailure()) addErro(result.error as Error, "runAssync");
        return result;
    } catch (error) {
        addErro(error as Error, "runAssync");
    }
};

export const addErro = (erro: Error, id?: string) =>
    useErroContext.setState(
        { erro },
        false,
        id ? `${id} => addErro` : "addErro"
    );

export const removerErro = () =>
    useErroContext.setState(
        (state) => ({
            ...state,
            erro: undefined,
        }),
        false,
        "removerErro"
    );
