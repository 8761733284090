import { BarraProgresso } from "@shared/components/BarraProgresso/BarraProgresso";

export const BarraPogressoPlanoAcao = ({ progresso }) => {
    return (
        <BarraProgresso
            progresso={progresso}
            range={[0.5, 0.7, 0.9]}
            cores={["green", "rgb(234 179 8)", "orange", "rgb(220 38 38)"]}
        />
    );
};
