export type PromotorVisita = {
    id: string;
    nome: string;
};

export const criarPromotorVisita = (props: PromotorVisita): PromotorVisita => {
    return {
        ...props,
    };
};
