import { TechnicalException } from "@shared/exceptions/TechnicalException";

export class ChecklistNaoEncontradoException extends TechnicalException {
    ChecklistNaoEncontradoException = "key";

    constructor() {
        super("Checklist não encontrado");
    }

    getInstance(): this {
        return this;
    }

    getName(): string {
        return ChecklistNaoEncontradoException.name;
    }
}
