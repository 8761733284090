import * as LojaApi from "@api/Loja";
import { ListarLojasUsuarioLogadoExceptions } from "@api/Loja/ListarMinhasLojas";
import * as OrganizacaoApi from "@api/Organizacao";
import { Menu } from "@components/Menu/Menu";
import * as ErroContext from "@contexts/Erro";
import * as LojaStore from "@contexts/Loja";
import { useMapaContext } from "@contexts/Mapa";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { Loja as LojaModel } from "@models/Loja/Loja";
import { Localizacao } from "@models/Visita";
import { useLocalizacao } from "@services/UseLocalizacao/useLocalizacao";
import { R, ResultAsync } from "@shared/utils/Result";
import React, { useEffect, useState } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import MapView, { Marker } from "react-native-maps";
import BarraPesquisa from "../../../../components/BarraPesquisa/BarraPesquisa";
import { PageContainer } from "../../../../shared/components/page/PageContainer";

export default function MapInterface() {
    const { reiniciarStore: reiniciarLojaStore } = LojaStore.useLojaStore();

    const selecionarLoja = useMapaContext((state) => state.selectionarLoja);

    const [lojas, setLojas] = useState<LojaModel[]>([]);
    const [organizacao, setOrganizacao] = useState<
        OrganizacaoApi.Organizacao | undefined
    >();

    const lojaSelecionada = useMapaContext((store) => store.lojaSelecionada);
    const [localizacaoInicial, setLocalizacaoInicial] = useState<Localizacao>();

    const { getLocalizacao } = useLocalizacao();

    const mapRef = React.useRef<MapView>(null);

    useEffect(() => {
        !lojaSelecionada && carregarMapa();
    }, [lojaSelecionada]);

    const carregarMapa = async () => {
        reiniciarLojaStore();

        const carregarLojasPromise = carregarLojas();
        const organizacaoPromise = OrganizacaoApi.getOrganizacao();

        const getLocalizacaoResult = await getLocalizacao();

        setLocalizacaoInicial(
            getLocalizacaoResult.isOk()
                ? getLocalizacaoResult.value
                : {
                      latitude: -29.94920068953503,
                      longitude: -51.09538600123748,
                  }
        );

        const [carregarLojasResult, organizacaoResult] = await Promise.all([
            carregarLojasPromise,
            organizacaoPromise,
        ]);

        if (carregarLojasResult.isFailure()) {
            ErroContext.addErro(carregarLojasResult.error);
            return;
        }

        if (organizacaoResult.isFailure()) {
            ErroContext.addErro(organizacaoResult.error);
            return;
        }

        setOrganizacao(organizacaoResult.value);
    };

    const carregarLojas = async (): ResultAsync<
        ListarLojasUsuarioLogadoExceptions,
        void
    > => {
        const lojas = await LojaApi.listarLojasUsuarioLogado();
        if (lojas.isFailure()) return R.failure(lojas.error);
        setLojas(lojas.value || []);
        return R.ok();
    };

    const lojaSelecionadaHandler = useErrorHandling((loja: LojaModel) => {
        mapRef.current?.animateCamera({
            center: loja.coordinate,
            heading: 0,
            pitch: 0,
            zoom: 15,
        });

        selecionarLoja(loja);
    });

    return (
        <PageContainer>
            <View className="flex flex-row h-full">
                <Menu tipo="desktop" />
                <MapView
                    ref={mapRef}
                    googleMapsApiKey={"AIzaSyAAvGFWL19FoshL_eozn6IgTcklXc4487E"}
                    options={{
                        disableDefaultUI: true,
                    }}
                    style={styles.map}
                    provider={"google"}
                    showsUserLocation={true}
                    initialCamera={{
                        center: {
                            latitude:
                                localizacaoInicial?.latitude ||
                                organizacao?.localizacaoPadrao.latitude ||
                                -29.94920068953503,
                            longitude:
                                localizacaoInicial?.longitude ||
                                organizacao?.localizacaoPadrao.longitude ||
                                -51.09538600123748,
                        },
                        heading: 0,
                        pitch: 0,
                        zoom: 15,
                    }}
                    onPress={() => {
                        mapRef.current && Keyboard.dismiss();
                    }}
                    toolbarEnabled={false}
                    showsMyLocationButton={false}
                >
                    {lojas.map((loja) => (
                        <Marker
                            key={loja.id}
                            coordinate={loja.coordinate}
                            onPress={() => {
                                lojaSelecionadaHandler(loja);
                            }}
                            anchor={{ x: 0.5, y: 1 }}
                            pinColor="green"
                        ></Marker>
                    ))}
                </MapView>

                <BarraPesquisa
                    lojas={lojas}
                    onLojaSelecionada={lojaSelecionadaHandler}
                />
            </View>
        </PageContainer>
    );
}

const styles = StyleSheet.create({
    map: {
        ...StyleSheet.absoluteFillObject,
    },
});
