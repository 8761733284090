export type ChecklistConfig = {
    id: string;
    nome: string;
};

export function criarChecklistConfig(props: ChecklistConfig): ChecklistConfig {
    return {
        id: props.id,
        nome: props.nome,
    };
}