import ContentLoader from "react-content-loader";

export interface ButtonLoaderProps {
    style?: React.CSSProperties;
}

export const ButtonLoader = ({ style }: ButtonLoaderProps) => {
    return (
        <ContentLoader
            speed={2}
            width={448}
            height={44}
            viewBox="0 0 448 44"
            backgroundColor="#f2f2f2"
            foregroundColor="#ffffff"
            style={{ ...style }}
        >
            <rect x="0" y="0" rx="22" ry="22" width="432" height="44" />
        </ContentLoader>
    );
};
