import { DefaultTheme as RNTheme } from "react-native-paper";
import { DefaultTheme } from "@react-navigation/native";

import { Theme, colors } from "../GlobalStyles";
import { shade } from "polished";

const primaryColor = "#00AF14";
const secoundColor = "#270040";

const theme = {
    ...DefaultTheme,
    title: "defaultTheme",
    colors: {
        ...RNTheme.colors,
        primary: primaryColor,
        outline: primaryColor,
        secundary: secoundColor,
        success: colors.green.light,
        error: colors.red.dark,
        info: colors.gray.medium,
        warning: colors.yellow.dark,
        border: {
            default: "#EEEEEE",
        },
        textInput: {
            border: {
                default: primaryColor,
                disabled: "#CCCCCC",
            },
        },
        button: {
            border: {
                default: primaryColor,
                inverted: primaryColor,
                disabled: "#CCCCCC",
            },
            background: {
                default: {
                    notPressed: primaryColor,
                    pressed: shade(0.2, primaryColor),
                },
                inverted: {
                    notPressed: colors.white.light,
                    pressed: primaryColor,
                },
                disabled: "#F0F0F0",
            },
            text: {
                default: {
                    notPressed: colors.white.light,
                    pressed: colors.white.light,
                },
                inverted: {
                    notPressed: secoundColor,
                    pressed: colors.white.light,
                },
                disabled: "#A3A3A3",
            },
        },
        text: {
            dark: colors.black.light,
            light: colors.gray.medium,
            disable: colors.gray.light,
            primary: primaryColor,
            white: colors.white.light,
        },
        icon: {
            alert: "#F57C00",
            dark: colors.black.light,
            light: colors.gray.medium,
            disable: "#D0D0D0",
            primary: primaryColor,
            ok: colors.green.dark,
            white: colors.white.light,
            error: colors.red.medium,
        },
        line: {
            default: colors.gray.medium,
        },
    },
};

export default theme;
