import * as DetalheLoja from "@models/Loja/DetalheLoja";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type ListarDetalhesLojaExceptions = HttpExceptions | ApiException;
export type ListarDetalhesLojaResult = ResultAsync<
    ListarDetalhesLojaExceptions,
    DetalheLoja.DetalheLoja[]
>;

export const listarDetalhesLoja = async (
    idLoja: string
): ListarDetalhesLojaResult => {
    const detalhesApi = await get<DetalheLoja.DetalheLoja[]>(
        `${basePath}/${idLoja}/details`
    );
    if (detalhesApi.isFailure()) return R.failure(detalhesApi.error);
    const detalhes: DetalheLoja.DetalheLoja[] = [];
    for (const detalheApi of detalhesApi.value) {
        const detalhe = DetalheLoja.criarDetalheLoja({ ...detalheApi });
        detalhes.push(detalhe);
    }

    return R.ok(detalhes);
};
