import { Localizacao } from "./Localizacao";
import { PromotorVisita } from "./PromotorVisita";
import { StatusVisita } from "./StatusVisita";

export type Visita = {
    id: string;
    numero: number;
    startDate: Date;
    promotor: PromotorVisita;
    status: StatusVisita;
    checklist: string;
    planoAcao: string;
    localizacao?: Localizacao;
};

export function criarVisita(props: Visita): Visita {
    return {
        id: props.id,
        numero: props.numero,
        startDate: props.startDate,
        promotor: props.promotor,
        status: props.status,
        checklist: props.checklist,
        planoAcao: props.planoAcao,
    };
}

export function concluirVisita(
    visita: Visita,
    localizacao?: Localizacao
): Visita {
    return {
        ...visita,
        status: "completed",
        localizacao,
    };
}
