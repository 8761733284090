import React, { useEffect } from "react";
import { View } from "react-native";

import { InvalidEmailOrPaswordException } from "../../../../exceptions/InvalidEmailOrPasword.exception";
import { Exception } from "../../../exceptions/Exception";
import { HttpTimeoutException } from "../../../exceptions/http/HttpTimeout.exception";
import { LoginNotAuthorizedModal } from "@pages/login/components/LoginNotAuthorizedModal";
import { MessageModal } from "../../modal/MessageModal";
import { TimeoutModal } from "@pages/Mapa/components/searchBar/TimeoutModal";

export type ErrorProps = {
    error?: Exception;
    onDismiss: () => void;
};

export const Error = ({ error, onDismiss }: ErrorProps) => {
    const handleClose = () => {
        onDismiss();
    };

    const buildErrorModal = () => {
        if (error instanceof HttpTimeoutException) {
            return (
                <TimeoutModal visible={!!error} onRequestClose={handleClose} />
            );
        }

        if (error instanceof InvalidEmailOrPaswordException) {
            return (
                <LoginNotAuthorizedModal
                    visible={!!error}
                    onRequestClose={handleClose}
                />
            );
        }

        if (error)
            return (
                <MessageModal
                    visible={!!error}
                    onRequestClose={handleClose}
                    message={error.message}
                    title="Ops!"
                    primaryButtonText="Ok"
                    onPrimaryButtonPressed={handleClose}
                />
            );
    };

    return <View>{buildErrorModal()}</View>;
};
