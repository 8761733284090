import ContentLoader from "react-content-loader";

export interface TagLoaderProps {
    style?: React.CSSProperties;
}

export const TagLoader = ({ style }: TagLoaderProps) => {
    return (
        <ContentLoader
            speed={2}
            width={112}
            height={28}
            viewBox="0 0 112 28"
            backgroundColor="#f2f2f2"
            foregroundColor="#ffffff"
            style={{ ...style }}
        >
            <rect x="0" y="0" rx="16" ry="16" width="112" height="28" />
        </ContentLoader>
    );
};
