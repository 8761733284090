import { ApiException } from "@shared/exceptions/Api.exception";
import { R, ResultAsync } from "@shared/utils/Result";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { basePath } from "./constants";

export type Parametro = {
    id: string;
    description: string;
    sponsors: string[];
    deadlines: number[];
    itemChecklistGatilho: string[];
};

export type GetParametrosPlanosAcaoResult = ResultAsync<
    HttpExceptions | ApiException,
    Parametro[]
>;

export async function getParametrosPlanosAcao(
    idVisita: string,
    idChecklist: string
): GetParametrosPlanosAcaoResult {
    const result = await get<Parametro[]>(
        `${basePath}/${idVisita}/checklist/${idChecklist}/action-plan?filter=necessary`
    );

    if (result.isOk()) return R.ok(result.value || []);

    return result;
}
