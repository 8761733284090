import ContentLoader from "react-content-loader";

export interface CardLoaderProps {
    style?: React.CSSProperties;
    height?: number;
}

export const CardLoader = ({ style, height = 180 }: CardLoaderProps) => {
    return (
        <ContentLoader
            speed={2}
            width={448}
            height={height}
            viewBox={`0 0 448 ${height}`}
            backgroundColor="#f1f1f1"
            foregroundColor="#ffffff"
            style={{ ...style }}
        >
            <rect x="0" y="0" rx="8" ry="8" width="432" height={height} />
        </ContentLoader>
    );
};
