import { encryptSHA256 } from "@services/Encryption.service";
import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get, post, put } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";

const basePath = `/auth/authentication`;

export type SignInExceptions = ApiException | HttpExceptions;
export type SignInResponse = ResultAsync<SignInExceptions, void>;

export const signIn = async (
    email: string,
    password: string
): SignInResponse => {
    try {
        const encryptedPassword = await encryptSHA256(password);
        if (encryptedPassword.isFailure())
            return R.failure(new ApiException(encryptedPassword.error));
        const result = await post(`${basePath}/signin`, {
            body: { email, password: encryptedPassword.value },
        });
        return result;
    } catch (error) {
        return R.failure(new ApiException(error as Error));
    }
};

export type ForgotPasswordExceptions = ApiException | HttpExceptions;
export type ForgotPasswordResponse = ResultAsync<
    ForgotPasswordExceptions,
    void
>;

export const forgotPassword = async (email: string): ForgotPasswordResponse => {
    try {
        const result = await post(`${basePath}/forgot`, { body: { email } });
        return result;
    } catch (error) {
        return R.failure(new ApiException(error as Error));
    }
};

export type ChangePasswordProps = {
    email: string;
    currentPassword: string;
    newPassword: string;
};

export type ChangePasswordResponse = ResultAsync<
    HttpExceptions | ApiException,
    void
>;

export const changePassword = async ({
    email,
    currentPassword,
    newPassword,
}: ChangePasswordProps): ChangePasswordResponse => {
    try {
        const encryptedCurrentPasswordPromise = await encryptSHA256(
            currentPassword
        );

        const encryptedNewPasswordPromise = await encryptSHA256(newPassword);

        const [encryptedCurrentPassword, encryptedNewPassword] =
            await Promise.all([
                encryptedCurrentPasswordPromise,
                encryptedNewPasswordPromise,
            ]);

        if (encryptedNewPassword.isFailure())
            return R.failure(new ApiException(encryptedNewPassword.error));
        if (encryptedCurrentPassword.isFailure())
            return R.failure(new ApiException(encryptedCurrentPassword.error));

        const result = await put(`${basePath}/password`, {
            body: {
                email,
                currentPassword: encryptedCurrentPassword.value,
                newPassword: encryptedNewPassword.value,
            },
        });
        if (result.isFailure()) return result;

        return R.ok();
    } catch (error) {
        return R.failure(new ApiException(error as Error));
    }
};

export const validarLogin = async () => {
    try {
        const result = await get<void>(`${basePath}/validar`);
        return result;
    } catch (error) {
        return R.failure(new ApiException(error as Error));
    }
};

export type SignoutExceptions = ApiException | HttpExceptions;
export type SignoutResponse = ResultAsync<SignoutExceptions, void>;

export const signout = async (): SignoutResponse => {
    try {
        const result = await post(`${basePath}/signout`);
        return result;
    } catch (error) {
        return R.failure(new ApiException(error as Error));
    }
};
