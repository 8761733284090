import { useState } from "react";

export const useErrorHandling = (callback) => {
    const [state, setState] = useState();

    return (...args) => {
        try {
            return callback(...args);
        } catch (e) {
            setState(() => {
                throw e;
            });
        }
    };
};
