export type ResumoPlanoAcao = {
    visita: {
        id: string;
        numero: number;
        dataCriacao: Date;
    };
    planoAcao: {
        id: string;
        status: string;
        percentualPrazo: number;
        promotor: string;
        dataLimite: Date;
        quantidadeParticipantes: number;
        quantidadeTotalAtividades: number;
        quantidadeAtividadesConcluidas: number;
        loja: {
            id: string;
            nme: string;
        };
    };
};

export const criarResumoPlanoAcao = (planoAcaoLoja: ResumoPlanoAcao) => {
    return {
        ...planoAcaoLoja,
        planoAcao: {
            ...planoAcaoLoja.planoAcao,
            dataLimite: new Date(planoAcaoLoja.planoAcao.dataLimite),
        },
        visita: {
            ...planoAcaoLoja.visita,
            dataCriacao: new Date(planoAcaoLoja.visita.dataCriacao),
        },
    };
};
