import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import { R, Result } from "@shared/utils/Result";
import * as AtividadePlanoAcao from "./AtividadePlanoAcao";
import { ParametroPlanoAcao } from "./ParametroPlanoAcao";
import { ResponsavelPlanoAcao } from "./ResponsavelPlanoAcao";
import { StatusAtividadePlanoAcao } from "./StatusAtividadePlanoAcao";

export interface PlanoAcao {
    id: string;
    status: string;
    atividades: AtividadePlanoAcao.AtividadePlanoAcao[];
    parametros: ParametroPlanoAcao[];
    atividadesNecessarias: ParametroPlanoAcao[];
    idVisita: string;
    idChecklist: string;
}

export function criarPlanoAcao(
    props: PlanoAcao
): Result<AtividadePlanoAcaoInvalida, PlanoAcao> {
    const planoAcao = { ...props };

    for (const atividadeNecessaria of planoAcao.atividadesNecessarias) {
        if (props.atividades.find((a) => a.id === atividadeNecessaria.id))
            continue;
        const atividade = AtividadePlanoAcao.criarAtividadePlanoAcao({
            id: atividadeNecessaria.id,
            descricao: atividadeNecessaria.descricao,
            status: StatusAtividadePlanoAcao.PENDENTE_CONFIGURACAO,
            itemChecklistGatilho: atividadeNecessaria.itemChecklistGatilho,
        });

        if (atividade.isFailure()) return R.failure(atividade.error);

        planoAcao.atividades.push(atividade.value);
    }

    return R.ok(planoAcao);
}

export const listarAtividadesConfiguradas = (
    planoAcao: PlanoAcao
): AtividadePlanoAcao.AtividadePlanoAcao[] => {
    return planoAcao.atividades.filter(
        (a) => a.status === StatusAtividadePlanoAcao.CONFIGURADA
    );
};

export const adicionarPrazoAtividade = (
    planoAcao: PlanoAcao,
    idAtividade: string,
    prazo: number
): PlanoAcao => {
    const _planoAcao = { ...planoAcao };
    let atividade = _planoAcao.atividades.find((a) => a.id === idAtividade);
    if (!atividade) return _planoAcao;
    atividade.prazo = prazo;
    atividade.status = AtividadePlanoAcao.definirStatus(atividade);
    return _planoAcao;
};

export const adicionarResponsavelAtividade = (
    planoAcao: PlanoAcao,
    idAtividade: string,
    responsavel: ResponsavelPlanoAcao
): PlanoAcao => {
    const _planoAcao = { ...planoAcao };
    const atividade = _planoAcao.atividades.find((a) => a.id === idAtividade);
    if (!atividade) return _planoAcao;
    atividade.responsavel = responsavel;
    atividade.status = AtividadePlanoAcao.definirStatus(atividade);
    return _planoAcao;
};

export const adicionarObservacaoAtividade = (
    planoAcao: PlanoAcao,
    idAtividade: string,
    observacao?: string
): PlanoAcao => {
    const _planoAcao = { ...planoAcao };
    const atividade = _planoAcao.atividades.find((a) => a.id === idAtividade);
    if (!atividade) return _planoAcao;
    atividade.observacao = observacao;
    atividade.status = AtividadePlanoAcao.definirStatus(atividade);
    return _planoAcao;
};
