import { loadingQueue } from "@shared/components/Loading/models/LoadingQueue";
import { useLoadingStore } from "@shared/components/Loading/stores/UseLoadingStore";
import { Exception } from "@shared/exceptions/Exception";
import { Result, ResultAsync } from "@shared/utils/Result";
import { useCallback } from "react";

export interface RunConfig {
    id?: string;
    onSuccess?: (result?: any) => void;
    onError?: (e: Exception) => void;
}
export interface UseLoading {
    addLoading: (id: string) => void;
    removeLoading: (id: string) => void;
    loadingQueue: loadingQueue;
    run: (
        action: () => ResultAsync<Exception, any> | Promise<void>,
        config?: RunConfig
    ) => void;
}

export function useLoading(): UseLoading {
    const {
        loadingQueue,
        addLoading: addLoadingStore,
        removeLoading: removeLoadingStore,
    } = useLoadingStore();
    //const { setErro } = useErro();

    const addLoading = useCallback((id: string) => {
        addLoadingStore(id);
    }, []);

    const removeLoading = useCallback((id: string) => {
        removeLoadingStore(id);
    }, []);

    const run = useCallback(
        (
            action: () => ResultAsync<Exception, any> | Promise<void>,
            config?: RunConfig
        ) => {
            const id = config?.id || action.name;

            addLoading(id);

            action()
                .then((result: Result<Exception, any> | void) => {
                    if (result && result.isFailure()) {
                        //setErro(result.error);
                        config?.onError && config.onError(result.error);
                    } else if (result && result.isOk() && config?.onSuccess) {
                        config.onSuccess(result.value);
                    }
                })
                .catch((e) => {
                    //setErro(e);
                    config?.onError && config.onError(e);
                })
                .finally(() => {
                    removeLoading(id);
                });
        },
        []
    );

    return {
        loadingQueue,
        addLoading,
        removeLoading,
        run,
    };
}
