import { ApiException } from "@shared/exceptions/Api.exception";
import { httpDelete, HttpExceptions } from "@shared/http/Http.service";
import { ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type RemoverCheklistVisitaExceptions = HttpExceptions | ApiException;
export type RemoverCheklistVisitaResult = ResultAsync<
    RemoverCheklistVisitaExceptions,
    void
>;

export async function removerCheklistVisita(
    idChecklist: string,
    idVisita: string
): RemoverCheklistVisitaResult {
    return await httpDelete(`${basePath}/${idVisita}/checklist/${idChecklist}`);
}
