import { patch } from "@shared/http/Http.service";
import { basePath } from "./constants";

export interface ConcluirAtividadePlanoAcaoProps {
    idVisita: string;
    idAtividade: string;
    idPlanoAcao: string;
    observacao?: string;
    token?: string;
}

export const concluirAtividadePlanoAcao = async ({
    idVisita,
    idAtividade,
    idPlanoAcao,
    observacao,
    token,
}: ConcluirAtividadePlanoAcaoProps) => {
    if (!token) {
        return await patch(
            `${basePath}/${idVisita}/plano-acao/${idPlanoAcao}/atividade/${idAtividade}`,
            {
                body: {
                    observacao,
                },
            }
        );
    } else {
        return await patch(
            `${basePath}/${idVisita}/plano-acao/${idPlanoAcao}/atividade`,
            {
                body: {
                    token,
                    observacao,
                    atividadeId: idAtividade,
                },
            }
        );
    }
};
