import * as Visita from "@models/Visita";
import { R, ResultAsync } from "@shared/utils/Result";
import * as Location from "expo-location";
import { LocalizacaoException } from "./LocalizacaoException";

export const useLocalizacao = () => {
    const getLocalizacao = async (): ResultAsync<
        LocalizacaoException,
        Visita.Localizacao
    > => {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== "granted") {
            return R.failure(
                new LocalizacaoException(
                    `Localização não permitida pelo usuário`
                )
            );
        }

        try {
            const location = await Location.getCurrentPositionAsync();
            const localizacao = Visita.criarLocalizacao({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
            });
            return R.ok(localizacao);
        } catch (error) {
            return R.failure(new LocalizacaoException(error as Error));
        }
    };

    return { getLocalizacao };
};
