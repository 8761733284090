import { TechnicalException } from "./TechnicalException";

export class ServiceException extends TechnicalException {
    ServiceException = "key";

    getName(): string {
        return ServiceException.name;
    }

    getInstance(): this {
        return this;
    }
}
