import { InformationIcon } from "@shared/components/Icons/InformationIcon";
import { IconLabelValue } from "@shared/components/itemList/IconLabelValue";

export interface DescricaoPlanoAcaoComponentProps {
    descricao: string;
}

export const DescricaoPlanoAcaoComponent = ({
    descricao,
}: DescricaoPlanoAcaoComponentProps) => {
    return (
        <IconLabelValue
            icon={<InformationIcon size="sm" />}
            label={`Descricao`}
            value={descricao}
        />
    );
};
