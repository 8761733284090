import { Exception } from "@shared/exceptions/Exception";

export class VisitaNaoEncontradaException extends Exception {
    VisitaNaoEncontradaException = "key";

    constructor() {
        super("Visita não encontrada");
    }

    getInstance(): this {
        return this;
    }

    getName(): string {
        return VisitaNaoEncontradaException.name;
    }
}
