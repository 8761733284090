export type Loja = {
    id: string;
    tenantId: string;
    coordinate: {
        latitude: number;
        longitude: number;
    };
    title: string;
    address: string;
};

export const criarLoja = (props: Loja): Loja => {
    return {
        id: props.id,
        tenantId: props.tenantId,
        coordinate: props.coordinate,
        title: props.title,
        address: props.address,
    };
};
