import { AtividadePlanoAcaoInvalida } from "@exceptions/PlanoAcao/AtividadePlanoAcaoInvalida.exception";
import { ResponsavelPlanoAcao } from "@models/PlanoAcao/ResponsavelPlanoAcao";
import { StatusAtividadePlanoAcao } from "@models/PlanoAcao/StatusAtividadePlanoAcao";
import { R, Result } from "@shared/utils/Result";

export interface AtividadePlanoAcao {
    id: string;
    descricao: string;
    status: StatusAtividadePlanoAcao;
    prazo?: number;
    responsavel?: ResponsavelPlanoAcao;
    observacao?: string;
    itemChecklistGatilho: string[];
}

export function criarAtividadePlanoAcao(
    props: AtividadePlanoAcao,
): Result<AtividadePlanoAcaoInvalida, AtividadePlanoAcao> {
    if (
        props.status === StatusAtividadePlanoAcao.CONFIGURADA &&
        (props.prazo === undefined || props.responsavel === undefined)
    ) {
        return R.failure(
            new AtividadePlanoAcaoInvalida(
                "Atividade de plano de ação configurada sem prazo ou responsável",
            ),
        );
    }

    return R.ok({
        ...props,
    });
}

export const definirStatus = (
    atividade: AtividadePlanoAcao,
): StatusAtividadePlanoAcao => {
    if (atividade.prazo && atividade.responsavel)
        return StatusAtividadePlanoAcao.CONFIGURADA;
    else return StatusAtividadePlanoAcao.PENDENTE_CONFIGURACAO;
};

export const isAtividadeConcluida = (status: StatusAtividadePlanoAcao) => {
    return status === StatusAtividadePlanoAcao.CONCLUIDA;
};
