import { BarraPogressoPlanoAcao } from "@components/PlanoAcao/BarraProgressoPlanoAcao";
import { ResumoPlanoAcao } from "@models/PlanoAcao";
import { DateIcon } from "@shared/components/Icons/DateIcon";
import { PeopleIcon } from "@shared/components/Icons/PeopleIcon";
import { PersonIcon } from "@shared/components/Icons/PersonIcon";
import { CheckIcon } from "@shared/components/Icons/check/CheckIcon";
import { Tag } from "@shared/components/tag/Tag";
import { Pressable, Text, View } from "react-native";
import { DescricaoPlanoAcaoLoja } from "../ListaPlanosAcaoLoja/DescricaoPlanoAcaoLoja";

export interface CardPlanoAcaoProps {
    resumoPlanoAcao: ResumoPlanoAcao;
    onClick?: () => void;
}

export const CardResumoPlanoAcao = ({
    resumoPlanoAcao: planoAcaoLoja,
    onClick,
}: CardPlanoAcaoProps) => {
    const planoAcaoClicadoHandler = async () => {
        onClick && onClick();
    };

    return (
        <Pressable onPress={planoAcaoClicadoHandler}>
            <View className="p-2 pb-4 space-y-3" key={planoAcaoLoja.visita.id}>
                <View className="flex flex-row items-center justify-between">
                    <DescricaoPlanoAcaoLoja
                        numeroVisita={planoAcaoLoja.visita.numero}
                    />

                    <Tag
                        label={planoAcaoLoja.planoAcao.status}
                        tooltipMessage={"Status do plano de ação"}
                        color="transparent"
                    />
                </View>

                <View className="flex flex-row items-center space-x-2 ">
                    <Text>Prazo</Text>
                    <BarraPogressoPlanoAcao
                        progresso={planoAcaoLoja.planoAcao.percentualPrazo}
                    />
                </View>

                <View className="flex flex-row items-center space-x-2">
                    <Tag
                        icon={<PersonIcon size="xs" color="gray" />}
                        label={planoAcaoLoja.planoAcao.promotor}
                        tooltipMessage="Promotor do plano de ação"
                    />

                    <Tag
                        icon={<DateIcon size="xs" color="gray" />}
                        label={planoAcaoLoja.planoAcao.dataLimite.toLocaleDateString()}
                        tooltipMessage="Data limite para conclusão do plano de ação"
                    />

                    <Tag
                        icon={<PeopleIcon size="xs" color="gray" />}
                        label={planoAcaoLoja.planoAcao.quantidadeParticipantes}
                        tooltipMessage="Quantidade de participantes do plano de ação"
                    />
                    <Tag
                        icon={<CheckIcon size="xs" color="gray" />}
                        label={`${planoAcaoLoja.planoAcao.quantidadeAtividadesConcluidas}/${planoAcaoLoja.planoAcao.quantidadeTotalAtividades}`}
                        tooltipMessage="Quantidade de atividades concluídas"
                    />
                </View>
            </View>
        </Pressable>
    );
};
