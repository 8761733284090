import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, patch } from "@shared/http/Http.service";
import { R, ResultAsync } from "@shared/utils/Result";
import { basePath } from "./constants";

export type Resposta = {
    idItemChecklist: string;
    resposta: string;
    comentario?: string;
};

export type ResponderChecklistProps = {
    idVisita: string;
    respostas: Resposta[];
};

export type ResponderChecklistExceptions = HttpExceptions | ApiException;
export type ResponderChecklistResult = ResultAsync<
    ResponderChecklistExceptions,
    void
>;

export async function responderChecklist({
    idVisita,
    respostas,
}: ResponderChecklistProps): ResponderChecklistResult {
    return await patch(`${basePath}/${idVisita}/checklist`, {
        body: respostas.map((item) => ({
            checklistItemId: item.idItemChecklist,
            answer: item.resposta,
            comment: item.comentario,
        })),
    });
}
