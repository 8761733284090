import { Exception } from "@shared/exceptions/Exception";
import { create } from "zustand";

export type UseErroStore = {
    erro?: Exception;
    setErro: (erro?: Exception) => void;
};

export const useErroStore = create<UseErroStore>()((set, get) => ({
    erro: undefined,
    setErro(erro) {
        set({ erro });
    },
}));
