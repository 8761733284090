import { TechnicalException } from "@shared/exceptions/TechnicalException";

export class VisitaNaoCarregadaException extends TechnicalException {
    VisitaNaoCarregadaException = "key";

    constructor() {
        super("Visita não carregada");
    }

    getInstance(): this {
        return this;
    }

    getName(): string {
        return VisitaNaoCarregadaException.name;
    }
}
