export enum Screens {
    SPLASH = "splash",
    SIGN_IN = "signin",
    CHANGE_PASSWORD = "changePassword",
    MENU = "menu",
    MAPA = "mapa",
    STORE = "store",
    VISIT = "visit",
    CAMERA = "camera",
    ACTION_PLAN = "actionPlan",
}
