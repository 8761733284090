import MapInterface from "@features/Mapa/components/Mapa/Mapa";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Screens } from "../Screens";

const MapaStackNavigator = createNativeStackNavigator();
export function MapaStack() {
    return (
        <MapaStackNavigator.Navigator>
            <MapaStackNavigator.Screen
                name={Screens.MAPA}
                component={MapInterface}
                options={{
                    animation: "fade",
                    animationDuration: 500,
                    headerShown: false,
                }}
            />
        </MapaStackNavigator.Navigator>
    );
}
