import { TechnicalException } from "@shared/exceptions/TechnicalException";

export class ChecklistNaoCarregadoException extends TechnicalException {
    ChecklistNaoCarregadoException = "key";

    constructor() {
        super("Checklist não carregado");
    }

    getInstance(): this {
        return this;
    }

    getName(): string {
        return ChecklistNaoCarregadoException.name;
    }
}
