import { TechnicalException } from "../TechnicalException";

export class HttpTimeoutException extends TechnicalException {
    HttpTimeoutException = "key";

    constructor(
        creationProps: Error | string,
        private readonly error: string,
        private readonly statusCode: number
    ) {
        super(creationProps);
    }

    getName(): string {
        return HttpTimeoutException.name;
    }

    getInstance(): this {
        return this;
    }

    getError(): string {
        return this.error;
    }

    getStatusCode(): number {
        return this.statusCode;
    }
}
