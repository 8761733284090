export type ExceptionTypes = "BusinessException" | "TechnicalException";

export abstract class Exception extends Error {
    private type: ExceptionTypes;

    constructor(
        creationProps?: Error | string | Exception,
        type: ExceptionTypes = "TechnicalException"
    ) {
        let message = "";
        let stack = "";
        if (creationProps instanceof Exception) {
            message = creationProps.message;
        } else if (creationProps instanceof Error) {
            message = creationProps.message;
        } else if (typeof creationProps === "string") {
            message = creationProps;
        }

        super(message);
        this.stack = stack;
        this.name = this.getName();
        this.type = type;
    }

    abstract getName(): string;

    abstract getInstance(): this;

    setMessage(message: string): this {
        this.message = message;
        return this.getInstance();
    }

    setStack(stack: string): this {
        this.stack = stack;
        return this.getInstance();
    }

    toString(): string {
        return `${this.name}: ${this.message} - ${this.stack}`;
    }

    getType(): ExceptionTypes {
        return this.type;
    }

    isTechnicalException(): boolean {
        return this.type === "TechnicalException";
    }

    isBusinessException(): boolean {
        return this.type === "BusinessException";
    }
}
