import { TiposItensChecklist } from "@models/Checklist/ItemChecklist";
import { BottomPage } from "@shared/components/bottomPage/BottomPage";
import { Text } from "react-native";

export interface ModalAjudaItemChecklistProps {
    item: TiposItensChecklist;
    onClose: () => void;
}

export const ModalAjudaItemChecklist = ({
    item,
    onClose,
}: ModalAjudaItemChecklistProps) => {
    return (
        <BottomPage title={"Ajuda sobre o checklist"} onClose={onClose}>
            <Text>{item.description}</Text>
        </BottomPage>
    );
};
