import { ABAS_TELA_LOJA, useLojaStore } from "../LojaStore";

export const alterarTabTelaLoja = (aba: ABAS_TELA_LOJA) =>
    useLojaStore.setState(
        (state) => ({
            ...state,
            tela: { nome: "loja", aba },
        }),
        false,
        "alterarTabTelaLoja"
    );
