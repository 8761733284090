import { ApiException } from "@shared/exceptions/Api.exception";
import { HttpExceptions, get } from "@shared/http/Http.service";
import { ResultAsync } from "@shared/utils/Result";
import { basePath } from "./contants";

export type NomeUsuario = {
    name: string;
};

export type GetNomeUsuarioExceptions = ApiException | HttpExceptions;
export type GetNomeUsuarioResult = ResultAsync<
    GetNomeUsuarioExceptions,
    NomeUsuario
>;

export const getNomeUsuario = async (
    usuarioId: string
): GetNomeUsuarioResult => {
    const nomeUsuario = await get<NomeUsuario>(`${basePath}/${usuarioId}/name`);
    return nomeUsuario;
};
