import { LoginTemplate } from "@shared/components/Templates/login/Login.template";
import { PasswordChangedAlert } from "@components/Alertas/PasswordChangedAlert";
import { useChangePassword } from "@pages/AlteracaoSenha/useChangePassword";
import React from "react";
import { Keyboard, View } from "react-native";
import { Button, TextInput } from "react-native-paper";

export interface ChangePasswordProps {
    route: any;
}

export const ChangePasswordInterface = ({ route }: ChangePasswordProps) => {
    const useChangePasswordHook = useChangePassword({
        currentPassword: route.params.password,
        email: route.params.email,
    });

    return (
        <LoginTemplate>
            <View className="flex justify-center items-center space-y-2">
                <TextInput
                    mode="outlined"
                    label="E-mail"
                    inputMode="numeric"
                    value={useChangePasswordHook.email}
                    onChange={(text) =>
                        useChangePasswordHook.setEmail(text.nativeEvent.text)
                    }
                    className="w-full"
                />

                <TextInput
                    mode="outlined"
                    secureTextEntry
                    label="Senha atual"
                    inputMode="numeric"
                    value={useChangePasswordHook.currentPassword}
                    onChange={(text) =>
                        useChangePasswordHook.setCurrentPassword(
                            text.nativeEvent.text
                        )
                    }
                    className="w-full"
                />

                <TextInput
                    mode="outlined"
                    secureTextEntry
                    label="Nova Senha"
                    inputMode="numeric"
                    value={useChangePasswordHook.newPassword}
                    onChange={(text) =>
                        useChangePasswordHook.setNewPassword(
                            text.nativeEvent.text
                        )
                    }
                    className="w-full"
                />

                <TextInput
                    mode="outlined"
                    secureTextEntry
                    label="Confirmação nova senha"
                    inputMode="numeric"
                    value={useChangePasswordHook.confirmPassword}
                    onChange={(text) =>
                        useChangePasswordHook.setConfirmPassword(
                            text.nativeEvent.text
                        )
                    }
                    className="w-full"
                />

                <Button
                    mode="contained"
                    onPress={() => {
                        Keyboard.dismiss();
                        useChangePasswordHook.changePassword();
                    }}
                    className="w-full"
                >
                    Cadastrar Nova Senha
                </Button>

                <Button
                    mode="text"
                    onPress={() => {
                        Keyboard.dismiss();
                        useChangePasswordHook.goBack();
                    }}
                    className="w-full"
                >
                    Voltar
                </Button>
            </View>

            <PasswordChangedAlert
                onDismiss={() =>
                    useChangePasswordHook.closePasswordChangedMessage()
                }
                visible={useChangePasswordHook.showPasswordChangedMessage}
            />
        </LoginTemplate>
    );
};
